import { useContext } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { AppContext } from "./Context";
import store from "../../app/store";
import { postUser } from "../../features/error/slices";
import Autocomplete from "@mui/material/Autocomplete";
import countries from "./countryList";
import argentineProvinces from "./provinceList";
import './FirstStep.css'

export default function FirstStep(props: any) {
  const { formValues, handleChange, handleNext, variant, margin } =
    useContext(AppContext);
  const { firstName, lastName, email, apodo, pais, provincia, localidad } =
    formValues;

    function isError(country: "Argentina" | string): boolean {
      const fieldsToCheck =
        country === "Argentina"
          ? ["firstName", "lastName", "email", "apodo", "pais", "provincia", "localidad"]
          : ["firstName", "lastName", "email", "apodo", "pais"];
    
      return fieldsToCheck.some(
        (name) =>
          formValues[name] && // add null/undefined check
          formValues[name].required && // add null/undefined check
          (!formValues[name].value || formValues[name].error)
      );
    }


  function handleChangeAuto(event: any, values: any) {
    if (values) {
      event.target = {
        type: "text",
        name: "pais",
        value: values.label,
      };
    } else {
      event.target = {
        type: "text",
        name: "pais",
        value: "",
      };
    }
    handleChange(event);
  }

  function handleChangeAutoProv(event: any, values: any) {
    if (values) {
      event.target = {
        type: "text",
        name: "provincia",
        value: values.properties.nombre,
      };
    } else {
      event.target = {
        type: "text",
        name: "provincia",
        value: "",
      };
    }
    handleChange(event);
  }

  async function inscription() {
    let dataUser = {
      name: firstName.value,
      last_name: lastName.value,
      email: email.value,
      nick_name: apodo.value,
      country: pais.value,
      provincia: provincia.value,
      localidad: localidad.value,
    };
    let dataString = JSON.stringify(dataUser);

    localStorage.setItem("user", dataString);
    props.handleInscription();
  }
  function storeUserData() {
    let dataUser = {
      name: firstName.value,
      last_name: lastName.value,
      email: email.value,
      nick_name: apodo.value,
      country: pais.value,
      state: provincia.value,
      city: localidad.value,
    };
    store.dispatch(postUser(dataUser));
    let dataString = JSON.stringify(dataUser);
    localStorage.setItem("user", dataString);
  }
  return (
    <div
      className="form-container"
    >
      <div className="form-title-container">
        <h5 className="text-dark without-margin">Informacion Personal</h5>
      </div>
      <div className="form-fields-container">
        <Grid container spacing={6} style={{ marginTop: 0 }}>
          <Grid item xs={12} sm={3}>
            <TextField
              variant={variant}
              margin={margin}
              fullWidth
              type="text"
              label="Nombre"
              name="firstName"
              placeholder="Nombre"
              value={firstName.value}
              disabled={firstName.disabled}
              onChange={handleChange}
              error={!!firstName.error}
              helperText={firstName.error}
              required={firstName.required}
              InputProps={{
                classes: {
                  input: "text-dark",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              variant={variant}
              margin={margin}
              fullWidth
              type="text"
              label="Apellido"
              name="lastName"
              placeholder="Apellido"
              value={lastName.value}
              disabled={lastName.disabled}
              onChange={handleChange}
              error={!!lastName.error}
              helperText={lastName.error}
              required={lastName.required}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant={variant}
              margin={margin}
              fullWidth
              label="Correo Electronico"
              name="email"
              placeholder="Correo Electronico"
              type="email"
              value={email.value}
              disabled={email.disabled}
              onChange={handleChange}
              error={!!email.error}
              helperText={email.error}
              required={email.required}
            />
          </Grid>

          <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
            <TextField
              variant={variant}
              margin={margin}
              fullWidth
              label="Apodo"
              name="apodo"
              type="text"
              placeholder="Apodo"
              value={apodo.value}
              disabled={apodo.disabled}
              onChange={handleChange}
              error={!!apodo.error}
              helperText={apodo.error}
              required={apodo.required}
            />
            <p className="small">
              Tu nombre favorito o tu apodo, ¡lo que prefieras!
            </p>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
              <Autocomplete
                id="country-select-demo"
                className="country-list"
                options={countries}
                autoHighlight
                onChange={handleChangeAuto}
                disabled={pais.disabled}
                value={pais.value}
                renderOption={(props, option) => (
                  <div className="dropdown-list-item">
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                    key={option.code}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code})
                  </Box>
                  </div>
                )}
                renderInput={(params) => (
                  <TextField
                    margin={margin}
                    variant={variant}
                    label="País"
                    name="Pais"
                    type="text"
                    placeholder="País"
                    value={pais.value}
                    onChange={handleChange}
                    error={!!pais.error}
                    helperText={pais.error}
                    required={pais.required}
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                {pais.value === "Argentina" ? (
                  <Autocomplete
                    id="provincias-select"
                    options={argentineProvinces}
                    disabled={provincia.disabled}
                    value={provincia.value}
                    onChange={handleChangeAutoProv}
                    getOptionLabel={(option) => {
                      if (option?.properties?.nombre) {
                        return option?.properties?.nombre;
                      } else {
                        return provincia.value;
                      }
                    }}
                    renderOption={(props, option) => (
                      <div className="dropdown-list-item">
                      <Box
                      className="dropdown-list"
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                        key={option.properties.id}
                      >
                        {option.properties.nombre}
                      </Box>
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        variant={variant}
                        margin={margin}
                        type="text"
                        label="Provincia"
                        name="provincia"
                        placeholder="Provincia"
                        value={provincia.value}
                        onChange={handleChange}
                        error={!!provincia.error}
                        helperText={provincia.error}
                        required={provincia.required}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password2", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                ) : null}
              </Grid>
              <Grid item xs={6} sm={6} style={{ paddingTop: 0 }}>
                {pais.value === "Argentina" ? (
                  <TextField
                  variant={variant}
                  margin={margin}
                  type="text"
                  label="Ciudad"
                  name="localidad"
                  placeholder=""
                  value={localidad.value}
                  onChange={handleChange}
                  required={localidad.required}
                  error={!!localidad.error}
                  helperText={localidad.error}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid item xs={12}>
        {
          <div className="button-and-error-container">
          <button
            className={isError(pais.value) ? "button-inscripcion button-inscripcion-disabled" : "button-inscripcion"}
            disabled={isError(pais.value)}
            color="primary"
            onClick={!isError(pais.value) ? props.isFree? () => inscription() : ()=>{storeUserData(); handleNext();} : () => null}
          >
            {props.isFree ? "Inscribirme" : "Continuar a pagar"}
          </button>
              <p
              className="error"
              style={{ display: isError(pais.value) ? "block" : "none" }}
            >
            No completaste todos los campos, o alguno tiene un dato inválido.
            </p>
            </div>
          }
      </Grid>
    </div>
  );
}