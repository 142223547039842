import './myCourse.css'

interface Session {
  class_number: string;
  nota: string;
  url_to_replay_webinar_course: string;
  url_to_live_webinar_course: string;
}

interface SessionLinksProps {
  sessions: Session[];
  past_course: boolean;
}

const SessionLinks = ({ sessions, past_course }: SessionLinksProps) => {
    return(
      <div className="card course-data-card" id="course-sessions">
        <div className="course-data-container">
          <div className="course-data-title-icon-container">
            <svg
              width="22"
              height="12"
              viewBox="0 0 22 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 4C4.89543 4 4 4.89543 4 6C4 7.10457 4.89543 8 6 8C7.10457 8 8 7.10457 8 6C8 4.89543 7.10457 4 6 4Z"
                fill="#F88D00"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 0.25C2.82436 0.25 0.25 2.82436 0.25 6C0.25 9.17564 2.82436 11.75 6 11.75C8.18057 11.75 10.0762 10.5363 11.0508 8.75H14.25V11C14.25 11.4142 14.5858 11.75 15 11.75H18.5C18.9142 11.75 19.25 11.4142 19.25 11V8.75H21C21.4142 8.75 21.75 8.41421 21.75 8V5C21.75 4.0335 20.9665 3.25 20 3.25H11.0508C10.0762 1.46371 8.18057 0.25 6 0.25ZM1.75 6C1.75 3.65279 3.65279 1.75 6 1.75C7.74161 1.75 9.24018 2.79769 9.89669 4.30028C10.016 4.57345 10.2859 4.75 10.584 4.75H20C20.1381 4.75 20.25 4.86193 20.25 5V7.25H18.5C18.0858 7.25 17.75 7.58579 17.75 8V10.25H15.75V8C15.75 7.58579 15.4142 7.25 15 7.25H10.584C10.2859 7.25 10.016 7.42655 9.89669 7.69972C9.24018 9.20231 7.74161 10.25 6 10.25C3.65279 10.25 1.75 8.34721 1.75 6Z"
                fill="#F88D00"
              />
            </svg>
            <p className="course-data-title">
              Acceso al curso
            </p>
          </div>
              {
              sessions?.length === 1 ?
                !sessions[0]?.url_to_replay_webinar_course ?
                  <a href={sessions[0]?.url_to_live_webinar_course ? sessions[0]?.url_to_live_webinar_course : ""} target="_blank" rel="noreferrer" className="button-inscripcion">
                    Ver la clase
                  </a>
                :
                  <a href={sessions[0]?.url_to_replay_webinar_course} target="_blank" rel="noreferrer" className="button-inscripcion">
                    Ver la grabación
                  </a>
              :
              !sessions || sessions.length === 0 ?
                <p style={{textAlign:"center"}}>
                  La grabación no está disponible.
                </p>
                :
                <select onChange={(event) => window.open(event.target.value)} className="session-dropdown">
                  <option value="">Elegí la clase que querés ver:</option>
                  {sessions?.map((session: any) => (
                    <option
                      value={
                        session?.url_to_replay_webinar_course
                          ? session?.url_to_replay_webinar_course
                          : session?.url_to_live_webinar_course
                      }
                    >
                      {session?.class_number}{" "}
                      {session?.date_details ? `(${session?.date_details})` : "(clase grabada)"}
                    </option>
                  ))}
                </select>
              }
        </div>
      </div>
    )
}
export default SessionLinks