import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import React, { useContext, useState, useEffect } from "react";

type Certificate100Props = {
  infoCertificate: certificate
};

type certificate = {
  certificate_id: number;
  date_issued: string;
  recipient_name: string;
  amount_of_hours: string;
  speciality_field: string;
  course_date: string;
};

export default function Certificate100({
  infoCertificate
}: Certificate100Props) {
  return (
    <div className="border-100">
      <div id="certificate-special">
          <Grid
            container
            direction="column"
            justifyContent="center"
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mt={0}
            >
              <img
                src={require("../../static/certificate/IsoLogoSpecial.svg").default}
                alt="Isologo"
              />
              <img
                src={require("../../static/certificate/sello-100.svg").default}
                alt="Sello 100 HORAS"
              />
            </Grid>

            <div style={{ marginBottom: "14px", marginTop: "70px" }}>
              <p className="overline text without-margin uppercase light">
                Trayecto de formación especializada{" "}
              </p>
            </div>
            <h2 className="bold without-margin primary">
              {infoCertificate.speciality_field}
            </h2>
            <p className='text'>
              Se entrega este certificado a 
              <span className="h5 capitalize text"> {infoCertificate.recipient_name} </span><br></br>
              por haber completado {infoCertificate.amount_of_hours} horas reloj de capacitaciones en {infoCertificate.speciality_field}, en encuentros de formación organizados por Juan Macarlupu o el Instituto Superior N.° 9123 San Bartolomé, y por haber cumplido con todos los requisitos de cursado.
            </p>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              mt={12}
            >
              <Grid
                item
                md={4}
                container
                direction="column"
                justifyContent="center"
                style={{top:'10px',position:'relative'}}
              >
                <p className="overline text without-margin uppercase text-center">
                  {infoCertificate.course_date}
                </p>
                <div
                  style={{
                    backgroundColor: "var(--primary)",
                    height: "1px",
                    margin: "14px auto",
                    width: "213px",
                  }}
                ></div>
                <p className="overline text without-margin uppercase text-center">
                  ID: {infoCertificate.certificate_id}
                </p>
              </Grid>
              <Grid
                item
                md={4}
                container
                direction="column"
                justifyContent="center"
                alignContent="center"
                style={{ paddingBottom: "6px" }}
              >
                <img
                  src={
                    require("../../static/certificate/firma-juan.svg").default
                  }
                  alt="Firma Juan"
                />
              </Grid>
              <Grid
                item
                md={4}
                container
                direction="column"
                justifyContent="center"
                style={{ paddingBottom: "24px" }}
              >
                <img
                  src={
                    require("../../static/certificate/firma-veronica.svg").default
                  }
                  alt="Firma Veronica"
                />
              </Grid>
            </Grid>
          </Grid>
      </div>
    </div>
  );
}
