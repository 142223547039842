type SpeakersProps = {
  speakers: [any]
}

export default function SpeakersCard({ speakers }: SpeakersProps) {

  return (
    <div >
      <div className="big-card-content">
        <h5 className='big-card-title'>
            {speakers?.length === 1 ?'Disertante:' :'Disertantes:'}        
        </h5>
        <div>
          {speakers?.map((speaker) => (
            <div style={{ marginTop: '20px' }}>
              <h6 style={{ margin:0}}>
                {speaker.full_name}
              </h6>
              {speaker.background ?
              <p>
                {speaker.background}
              </p> :
                <p>
                  Sin descripción
                </p>
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}