export default function generateSpeakersList(speakers) {
    let speakersList = ""
    for (let i = 0; i < speakers?.length; i++) {
        if (i===0) {
        speakersList = speakers[i].full_name
        }
        else if (i < speakers.length-1) {
        speakersList = speakersList + ", " + speakers[i].full_name
        }
        else {
        speakersList = speakersList + " y " + speakers[i].full_name
        }
        
    }
    return speakersList;
  }