import { createSlice } from '@reduxjs/toolkit'

export const errorSlice = createSlice({
  name: 'error',
  initialState: {
    value: ""
  },
  reducers: {
    postError: (state, action) => {
      if(action.payload?.message){
        state.value = action.payload?.message
      }
      else{
        state.value = action.payload
      }
    },
    clearError: (state) => {
      state.value = ''
    }
  }
})

export const severitySlice = createSlice({
  name: 'severity',
  initialState: {
    value: ""
  },
  reducers: {
    postSeverity: (state, action) => {
      state.value = action.payload;
    },
    clearSeverity: (state) => {
      //default to success so we always have a default value.
      state.value = "success";
    }
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value: {}
  },
  reducers: {
    postUser: (state, action) => {
      state.value = {
        ...state.value,
        ...action.payload
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { postError, clearError } = errorSlice.actions
export const { postSeverity, clearSeverity } = severitySlice.actions
export const { postUser } = userSlice.actions

export const errorReducer =  errorSlice.reducer
export const severityReducer =  severitySlice.reducer
export const userReducer =  userSlice.reducer