import Box from "@mui/material/Box";
import { Grid } from "@mui/material";

const ForumUnavailable = () => {
    return(
        <Grid item xs={8}>
            <Box className="card" style={{ height: "100%", padding: "20px", marginTop: "20px" }}>
                <h3>Estamos trabajando para mejorar esta sección del sitio</h3>
                <p>Vamos a hacer algunos cambios en esta sección del sitio, por lo que necesitamos mantenerla desactivada un tiempo. Una vez que la reactivemos, todo el contenido anterior va a seguir estando acá.</p>
                <p>Estamos haciendo todo lo posible para reactivar pronto este espacio de intercambio entre colegas y te vamos a avisar por correo electrónico cuando este listo.</p>
            </Box>
        </Grid>
    )
}
export default ForumUnavailable;