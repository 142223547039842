
import { useSelector } from 'react-redux';
import { useEffect } from 'react'
import store from "../../app/store";
import { clearError, clearSeverity } from './slices';
import Swal from 'sweetalert2'


const ErrorGlobal = () =>  {
    const error = useSelector(state => state.error.value);
    const severity = useSelector(state => state.severity.value);

    useEffect(() => {
        if (error) {
            if(severity === 'error'){
            Swal.fire({
                title: '¡Ups!',
                text: error,
                icon: 'error',
                confirmButtonText: 'OK',
                didClose: () => {
                    store.dispatch(clearError())
                    store.dispatch(clearSeverity())
                  },
                })
            }
            else{
                Swal.fire({
                    title: '¡Listo!',
                    text: error,
                    icon: 'success',
                    confirmButtonText: 'OK',
                    didClose: () => {
                        store.dispatch(clearError())
                        store.dispatch(clearSeverity())
                      },
                })
            }
        }
    }, [error, severity])

    return null

}

export default ErrorGlobal;