import React, { useContext, useState, useEffect } from "react";
import CertificateRegular from "./certificate_regular";
import Certificate100 from "./certificate_100";
import Certificate200 from "./certificate_200";
import Certificate500 from "./certificate_500";
import { useParams } from "react-router-dom";
import { getCertificate } from "../../services/services";
import BottomBar from "../bottomBarHome";
import TopBar from "../topBarHome";
import Grid from "@mui/material/Grid";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Skeleton from "@mui/material/Skeleton";

const Certificate = () => {
  const [infoCertificate, setInfoCertificate]: any = useState({});
  const [loading, setLoading]: any = useState(true);
  const [, setImage]: any = useState("");
  const [Year, setYear]: any = useState("");
  const [Month, setMonth]: any = useState("");
  let { idCertificate } = useParams();
  let fullUrl = window.location.href;


  const settings = {
    className: "center",
    centerMode: true,
    centerPadding: "60px",
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    initialSlide: 0,
    arrows: false,
  };

  useEffect(() => {
    getInfoCourse(idCertificate);
  }, []);

  async function getInfoCourse(id?: string) {
    const dataForm = {
      certificate: id,
    };
  
    const data: any = await getCertificate(dataForm);
  
    const dataCourses = data.data;
    
    setInfoCertificate(dataCourses.certificate);
    getMonthFull(dataCourses.certificate);
    getYearFull(dataCourses.certificate);
    setLoading(false);

  }

  function downloadCertificate() {

    const input = document.getElementById("certificate_download");
    if (input) {
      html2canvas(input).then((canvas) => {


         const imgData = canvas.toDataURL("image/jpg");
         const pdf =  new jsPDF({
           orientation: 'l', 
           unit: 'pt', 
           format: [canvas.width, canvas.height] 
     });
         //@ts-ignore:next-line
         pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width, canvas.height);
         pdf.save(`certificado-${idCertificate}.pdf`);
      });
    }
  }

  function getMonthFull(certificate: any) {
    const moonLanding = new Date(certificate.date_issued);
    setMonth(moonLanding.getMonth());
  }

  function getYearFull(certificate: any) {
    const moonLanding = new Date(certificate.date_issued);
    setYear(moonLanding.getFullYear());
  }

  return (
    <div id="certificate">
      <TopBar />
      <div style={{ margin: "122px 0px 50px 0px" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={{xs:5,md:12}}
          mb={{xs:6,md:5}}
          sx={{ maxWidth: "1024px", marginInline: "auto",width:"100%" }}
        >
          <Grid
            item
            xs={12}
            md={7}
            container
            direction="column"
            justifyContent="center"
          >
            <h5 className="primary">
              ¡Descargá o compartí este certificado!
            </h5>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <button
              className="button-inscripcion"
              color="primary"
              onClick={() => downloadCertificate()}
            >
              Descargar certificado
            </button>
            <a
              href={`https://www.linkedin.com/in/juanmacarlupu/edit/forms/certification/new/?certId=${infoCertificate.certificate_id}&certUrl=${fullUrl}&expirationMonth=&expirationYear=&isFromA2p=true&issueMonth=${Month}&issueYear=${Year}&name=${infoCertificate.course_name ? infoCertificate.course_name : 'Trayecto de formación especializada en ' + infoCertificate.speciality_field}&organizationName=Juan%20Macarlupu`}
              target="_blank"
            >
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="var(--primary)" />
                <path
                  fill-rule="evenodd"
                  clipRule="evenodd"
                  d="M13 9.25C11.4812 9.25 10.25 10.4812 10.25 12C10.25 13.5188 11.4812 14.75 13 14.75C14.5188 14.75 15.75 13.5188 15.75 12C15.75 10.4812 14.5188 9.25 13 9.25ZM11.75 12C11.75 11.3096 12.3096 10.75 13 10.75C13.6904 10.75 14.25 11.3096 14.25 12C14.25 12.6904 13.6904 13.25 13 13.25C12.3096 13.25 11.75 12.6904 11.75 12Z"
                  fill="#F9F9F9"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.25 16C10.25 15.5858 10.5858 15.25 11 15.25H15C15.4142 15.25 15.75 15.5858 15.75 16V29C15.75 29.4142 15.4142 29.75 15 29.75H11C10.5858 29.75 10.25 29.4142 10.25 29V16ZM11.75 16.75V28.25H14.25V16.75H11.75Z"
                  fill="#F9F9F9"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.25 16C17.25 15.5858 17.5858 15.25 18 15.25H22C22.4142 15.25 22.75 15.5858 22.75 16V16.434L23.1853 16.2475C23.9336 15.9268 24.7339 15.7256 25.5433 15.6521C28.3182 15.3998 30.75 17.5804 30.75 20.3802V29C30.75 29.4142 30.4142 29.75 30 29.75H26C25.5858 29.75 25.25 29.4142 25.25 29V22C25.25 21.6685 25.1183 21.3505 24.8839 21.1161C24.6495 20.8817 24.3315 20.75 24 20.75C23.6685 20.75 23.3505 20.8817 23.1161 21.1161C22.8817 21.3505 22.75 21.6685 22.75 22V29C22.75 29.4142 22.4142 29.75 22 29.75H18C17.5858 29.75 17.25 29.4142 17.25 29V16ZM18.75 16.75V28.25H21.25V22C21.25 21.2707 21.5397 20.5712 22.0555 20.0555C22.5712 19.5397 23.2707 19.25 24 19.25C24.7293 19.25 25.4288 19.5397 25.9445 20.0555C26.4603 20.5712 26.75 21.2707 26.75 22V28.25H29.25V20.3802C29.25 18.4759 27.589 16.9723 25.6791 17.1459C25.025 17.2054 24.3784 17.3681 23.7762 17.6262L22.2954 18.2608C22.0637 18.3601 21.7976 18.3363 21.5871 18.1976C21.3767 18.0588 21.25 17.8235 21.25 17.5714V16.75H18.75Z"
                  fill="#F9F9F9"
                />
              </svg>
            </a>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${fullUrl}&quote=Comparto%20con%20ustedes%20mi%20certificado%20de%20este%20curso.%20%23formaci%C3%B3ncontinua%20%23capacitaci%C3%B3n%20%23translation%20%23cursos%20%23juanmacarlupu`}
              target="_blank"
            >
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="var(--primary)" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.4877 11.7877C19.4723 10.8031 20.8076 10.25 22.2 10.25H24.9C25.3142 10.25 25.65 10.5858 25.65 11V14.6C25.65 15.0142 25.3142 15.35 24.9 15.35H22.2C22.1602 15.35 22.1221 15.3658 22.0939 15.3939C22.0658 15.4221 22.05 15.4602 22.05 15.5V17.45H24.9C25.131 17.45 25.349 17.5564 25.4912 17.7384C25.6333 17.9205 25.6836 18.1578 25.6276 18.3819L24.7276 21.9819C24.6441 22.3158 24.3442 22.55 24 22.55H22.05V29C22.05 29.4142 21.7142 29.75 21.3 29.75H17.7C17.2858 29.75 16.95 29.4142 16.95 29V22.55H15C14.5858 22.55 14.25 22.2142 14.25 21.8V18.2C14.25 17.7858 14.5858 17.45 15 17.45H16.95V15.5C16.95 14.1076 17.5031 12.7723 18.4877 11.7877ZM22.2 11.75C21.2054 11.75 20.2516 12.1451 19.5483 12.8483C18.8451 13.5516 18.45 14.5054 18.45 15.5V18.2C18.45 18.6142 18.1142 18.95 17.7 18.95H15.75V21.05H17.7C18.1142 21.05 18.45 21.3858 18.45 21.8V28.25H20.55V21.8C20.55 21.3858 20.8858 21.05 21.3 21.05H23.4144L23.9394 18.95H21.3C20.8858 18.95 20.55 18.6142 20.55 18.2V15.5C20.55 15.0624 20.7238 14.6427 21.0333 14.3333C21.3427 14.0238 21.7624 13.85 22.2 13.85H24.15V11.75H22.2Z"
                  fill="#F9F9F9"
                />
              </svg>
            </a>
          </Grid>
        </Grid>
        <div id="certificate_download" style={{overflowX:'auto'}}>
          {loading ? (
            <div
              className="border-gradient"
              style={{ background: "rgba(255, 255, 255, 0.13)" }}
            >
              <div id="certificate-regular">
                <div style={{ height: "670px" }}>
                  <Skeleton
                    variant="circular"
                    width={100}
                    height={100}
                    className="marginAuto"
                  />
                  <Skeleton
                    variant="text"
                    width="100%"
                    height={410}
                    sx={{ margin: "40px 0px" }}
                  />
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    className="text-center"
                  >
                    <Skeleton variant="text" width={215} />
                    <Skeleton
                      variant="circular"
                      width={40}
                      height={40}
                      className="marginAuto"
                    />
                    <Skeleton variant="text" width={215} />
                  </Grid>
                </div>
              </div>
            </div>
          ) : infoCertificate?.amount_of_hours ? (
            infoCertificate?.amount_of_hours == "100" ? (
              <Certificate100 infoCertificate={infoCertificate} />
            ) : infoCertificate?.amount_of_hours == "200" ? (
              <Certificate200 infoCertificate={infoCertificate} />
            ) : (
              <Certificate500 infoCertificate={infoCertificate} />
            )
          ) : (
            <CertificateRegular infoCertificate={infoCertificate} />
          )}
        </div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          mt={4}
          sx={{ maxWidth: "1024px", marginInline: "auto",width:"100%" }}
        >
          <p style={{marginRight:'5px'}}>
            Este certificado es válido y fue emitido por Juan Macarlupu el{" "}
            {infoCertificate.course_date}
          </p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5292 2.06777C11.2488 1.05611 12.7512 1.05611 13.4708 2.06777V2.06777C13.9727 2.77334 14.9049 3.02312 15.6923 2.66302V2.66302C16.8214 2.1467 18.1225 2.89792 18.2399 4.13386V4.13386C18.3217 4.99583 19.0042 5.67825 19.8661 5.76011V5.76011C21.1021 5.87748 21.8533 7.17863 21.337 8.30767V8.30767C20.9769 9.09509 21.2267 10.0273 21.9322 10.5292V10.5292C22.9439 11.2488 22.9439 12.7512 21.9322 13.4708V13.4708C21.2267 13.9727 20.9769 14.9049 21.337 15.6923V15.6923C21.8533 16.8214 21.1021 18.1225 19.8661 18.2399V18.2399C19.0042 18.3217 18.3217 19.0042 18.2399 19.8661V19.8661C18.1225 21.1021 16.8214 21.8533 15.6923 21.337V21.337C14.9049 20.9769 13.9727 21.2267 13.4708 21.9322V21.9322C12.7512 22.9439 11.2488 22.9439 10.5292 21.9322V21.9322C10.0273 21.2267 9.09509 20.9769 8.30767 21.337V21.337C7.17863 21.8533 5.87748 21.1021 5.76011 19.8661V19.8661C5.67825 19.0042 4.99583 18.3217 4.13386 18.2399V18.2399C2.89792 18.1225 2.1467 16.8214 2.66302 15.6923V15.6923C3.02312 14.9049 2.77334 13.9727 2.06777 13.4708V13.4708C1.05611 12.7512 1.05611 11.2488 2.06777 10.5292V10.5292C2.77334 10.0273 3.02312 9.09509 2.66302 8.30767V8.30767C2.1467 7.17863 2.89792 5.87748 4.13386 5.76011V5.76011C4.99583 5.67825 5.67825 4.99583 5.76011 4.13386V4.13386C5.87748 2.89792 7.17863 2.1467 8.30767 2.66302V2.66302C9.09509 3.02312 10.0273 2.77334 10.5292 2.06777V2.06777Z"
              fill="#1BCABC"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.5227 8.97725C16.7424 9.19692 16.7424 9.55308 16.5227 9.77275L11.2727 15.0227C11.0531 15.2424 10.6969 15.2424 10.4773 15.0227L7.47725 12.0227C7.25758 11.8031 7.25758 11.4469 7.47725 11.2273C7.69692 11.0076 8.05308 11.0076 8.27275 11.2273L10.875 13.8295L15.7273 8.97725C15.9469 8.75758 16.3031 8.75758 16.5227 8.97725Z"
              fill="#F9F9F9"
            />
          </svg>
        </Grid>
      </div>
      <BottomBar />
    </div>
  );
};

export const style = {
  listCoursesContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  card: {
    margin: "24px",
    marginLeft: "0px",
    borderImage: "linear-gradient(to top, '#00C853', '#B2FF59')",
  },
};

export default Certificate;
