import React, { useState } from "react";
import Stack from '@mui/material/Stack';
import { Link } from "react-router-dom";

export default function BottomBar(props) {
    const [tokenReady] = useState(localStorage.getItem('token'))


    return (<div >
        <div className="bottom-bar padding-inline">
            <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" justifyContent="space-between" spacing={3}>
                <Stack direction='column' sx={{ mt: { xs: 4, md: 0 }, mb: { xs: 3, md: 0 } }}>
                    <img src={require('../static/icon/logo-juan-white.svg').default} alt="logo"/>
                    <Stack direction="row" spacing={2} sx={{ marginTop: '30px', display: { xs: 'none', md: 'block' } }}>
                        <a href="https://www.facebook.com/juan.macarlupu" target="_blank" rel="noopener noreferrer">
                            <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clipRule="evenodd" d="M4.48769 1.78769C5.47225 0.803123 6.80761 0.25 8.2 0.25H10.9C11.3142 0.25 11.65 0.585786 11.65 1V4.6C11.65 5.01421 11.3142 5.35 10.9 5.35H8.2C8.16022 5.35 8.12206 5.3658 8.09393 5.39393C8.0658 5.42206 8.05 5.46022 8.05 5.5V7.45H10.9C11.131 7.45 11.349 7.5564 11.4912 7.73844C11.6333 7.92048 11.6836 8.15785 11.6276 8.3819L10.7276 11.9819C10.6441 12.3158 10.3442 12.55 10 12.55H8.05V19C8.05 19.4142 7.71421 19.75 7.3 19.75H3.7C3.28579 19.75 2.95 19.4142 2.95 19V12.55H1C0.585786 12.55 0.25 12.2142 0.25 11.8V8.2C0.25 7.78579 0.585786 7.45 1 7.45H2.95V5.5C2.95 4.10761 3.50312 2.77226 4.48769 1.78769ZM8.2 1.75C7.20544 1.75 6.25161 2.14509 5.54835 2.84835C4.84509 3.55161 4.45 4.50544 4.45 5.5V8.2C4.45 8.61421 4.11421 8.95 3.7 8.95H1.75V11.05H3.7C4.11421 11.05 4.45 11.3858 4.45 11.8V18.25H6.55V11.8C6.55 11.3858 6.88579 11.05 7.3 11.05H9.41442L9.93942 8.95H7.3C6.88579 8.95 6.55 8.61421 6.55 8.2V5.5C6.55 5.06239 6.72384 4.64271 7.03327 4.33327C7.34271 4.02384 7.76239 3.85 8.2 3.85H10.15V1.75H8.2Z" fill="#F2F2F2" />
                            </svg>
                        </a>
                        <a href="https://www.instagram.com/tradjuanmacarlupu/" target="_blank" rel="noopener noreferrer">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.0002 5.00005C14.0002 4.44776 14.4479 4.00005 15.0002 4.00005C15.5525 4.00005 16.0002 4.44776 16.0002 5.00005C16.0002 5.55233 15.5525 6.00005 15.0002 6.00005C14.4479 6.00005 14.0002 5.55233 14.0002 5.00005Z" fill="#F2F2F2" />
                                <path fill-rule="evenodd" clipRule="evenodd" d="M10.0002 5.25005C7.37683 5.25005 5.25018 7.37669 5.25018 10C5.25018 12.6234 7.37683 14.75 10.0002 14.75C12.6235 14.75 14.7502 12.6234 14.7502 10C14.7502 7.37669 12.6235 5.25005 10.0002 5.25005ZM6.75018 10C6.75018 8.20512 8.20526 6.75005 10.0002 6.75005C11.7951 6.75005 13.2502 8.20512 13.2502 10C13.2502 11.795 11.7951 13.25 10.0002 13.25C8.20526 13.25 6.75018 11.795 6.75018 10Z" fill="#F2F2F2" />
                                <path fill-rule="evenodd" clipRule="evenodd" d="M15.2584 0.833061C11.7919 0.44562 8.20853 0.44562 4.74195 0.833061C2.7299 1.05794 1.10556 2.64295 0.869009 4.66548C0.454471 8.20978 0.454471 11.7903 0.869009 15.3346C1.10556 17.3571 2.7299 18.9422 4.74195 19.167C8.20853 19.5545 11.7919 19.5545 15.2584 19.167C17.2705 18.9422 18.8948 17.3571 19.1314 15.3346C19.5459 11.7903 19.5459 8.20978 19.1314 4.66548C18.8948 2.64295 17.2705 1.05794 15.2584 0.833061ZM4.90856 2.32378C8.26441 1.94871 11.736 1.94871 15.0918 2.32378C16.4219 2.47244 17.4874 3.52205 17.6415 4.83973C18.0425 8.26825 18.0425 11.7318 17.6415 15.1604C17.4874 16.478 16.4219 17.5277 15.0918 17.6763C11.736 18.0514 8.26441 18.0514 4.90856 17.6763C3.57846 17.5277 2.51297 16.478 2.35885 15.1604C1.95786 11.7318 1.95786 8.26825 2.35885 4.83973C2.51297 3.52205 3.57845 2.47244 4.90856 2.32378Z" fill="#F2F2F2" />
                            </svg>
                        </a>
                    </Stack>
                </Stack>

                <Stack sx={{ pb: { xs: 3, md: 0 } }} direction={{ xs: 'column', md: 'row' }}
                    alignItems="center" justifyContent="space-between" spacing={4}>
                <Link to={'/cursos/'} className=" primary">Capacitaciones</Link>
                {/* <Link to={'/honorarios/'} className=" primary">Honorarios</Link>
                <Link to={'/especializaciones/'} className=" primary">Especializaciones</Link> */}
                <Link to={'/comunidad/'} className=" primary">Comunidad</Link>
                <a href="/#contacto" className=" primary">Contacto</a>
                </Stack>

                <Stack direction="row" spacing={1} alignItems="center"  >

                    <div className='btnMobileNext' style={{ width: '168px ', marginLeft: 0, margin: 'auto' }}>
                        <Link  style={{ display: 'flex', justifyContent: 'center', underline: 'none', color: 'white' }} 
                        to={'/cursos/'}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <p  class="subtitle2 button-medium " style={{
                    backgroundColor: "var(--primary)",
                    borderRadius: 70,
                    color: "#F9F9F9",
                    zIndex: 0,
                    marginTop: 0,
                    padding:'11px 20px',
                    height: "40px",
                  }}> Ir a la plataforma</p>
                            </div>
                        </Link>
                    </div>
                </Stack>
            </Stack>
        </div>
    </div>
    )
}


export const style = {
    listCoursesContainer: {
        display: 'grid',
        gridTemplateColumns: '33% 33% 33%',
        justifyContent: 'center'
    },
    card: {
        margin: '20px',
        marginLeft: '0px',
    }
}


