import { useState, useEffect } from "react";
import {
  getAllReviews,
  getRecommendedCourses,
  contact,
} from "../../services/services";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Button, TextField, Paper } from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CourseCard from '../reusable/courseCard/courseCard'
import CardBuyCourseCTA from '../reusable/cardBuyCourseCTA/cardBuyCourseCTA';
import BottomBar from "../bottomBarHome";
import TopBar from "../topBarHome";
import Box from "@mui/material/Box";


export default function Home() {
  const [listRecommendedCourse, setRecommendedCourse] = useState([]);
  const [loading, setloading] = useState(true);
  const [listReviews, setListReviews] = useState([]);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [messageUser, setmessageUser] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error] = useState(false);
  const [registry, setRegistry] = useState(false);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    initialSlide: 0,
    arrows: true,
    nextArrow: <img src={require("../../static/icon/next.svg").default} alt="next"/>,
    prevArrow: <img src={require("../../static/icon/back.png")} alt="prev"/>,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          className: "center",
          centerMode: true,
          centerPadding: "60px",
          initialSlide: 0,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          arrows: false,
          className: "center",
          centerMode: true,
          centerPadding: "60px",
          initialSlide: 0,
          infinite: false,
          speed: 300,
        },
      },
    ],
  };

  useEffect(() => {
    Promise.all([getListRecommendedCourse(), getReviews()]).then(() => {
      setloading(false);
    });
  }, []);

  const isValidFields = (fieldName) => {
    if (registry) {
      if (fieldName === "email") {
        return email
          ? /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email)
            ? error !== "Email not valid"
              ? ""
              : "Email no valido"
            : "Email no valido"
          : "Email requerido";
      } else {
        return fieldName ? "" : `${fieldName} requerido`;
      }
    }
  };

  async function getReviews() {
    setloading(true);
    const data = await getAllReviews();
    const dataReviews = data.data;

    setListReviews(dataReviews.reviews);
  }

  async function getListRecommendedCourse() {
    setloading(true);
    const data = await getRecommendedCourses();
    const dataCourses = data.data;

    setRecommendedCourse(dataCourses.recommended_courses);
  }

  async function sendEmail() {
    setRegistry(true);

    if (
      !!!isValidFields("email") &&
      !!!isValidFields("name") &&
      !!!isValidFields("lastName")
    ) {
      const dataForm = {
        nombre: name,
        apellidos: lastName,
        email: email,
        consulta: messageUser,
      };
      const data = await contact(dataForm);

      setMessage(data.data.message);
    }
  }

  return (
    <div id="home" style={{ marginTop: "0" }}>
      <TopBar></TopBar>
      <div>
        <div id="t" className="image-hero-home">
          <Grid
            container
            direction={{ xs: "column" }}
            justifyContent="center"
            alignItems="center"
            sx={{
              marginBottom: "8px",
              paddingInline: { xs: "30px", sm: "76px" },
            }}
          >
            <Grid item xs={12} md={5} mb={10} className="text-center">
              <h1
                className="text-center"
                style={{
                  margin: 0,
                  marginBottom: "80px",
                  marginRight: "59px",
                  marginTop: "45px",
                }}
              >
                Formación especializada para traductores
              </h1>
              <div
                className="button desktop"
                style={{ width: "30%", margin: "auto" }}
              >
                <Link
                  class="subtitle2"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    underline: "none",
                    color: "white",
                  }}
                  to={"/cursos/"}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p
                      class="button-medium"
                      style={{ color: "var(--text-button)" }}
                    >
                      {" "}
                      Ver todos los cursos
                    </p>
                  </div>
                </Link>
              </div>
              <div
                className="button mobile"
                style={{ marginLeft: 0, marginTop: "70px" }}
              >
                <Link
                  class="subtitle2"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    underline: "none",
                    color: "white",
                  }}
                  to={"/myCatalogue/"}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p
                      to={"/myCatalogue/"}
                      class="button-medium"
                      style={{ color: "var(--text-light)" }}
                    >
                      {" "}
                      Ver todos los cursos
                    </p>
                  </div>
                </Link>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "grid",
                alignContent: "center",
                width: { xs: "100%", md: "60%" },
              }}
              style={{ display: "grid", alignContent: "center" }}
            >
              <picture>
                <source
                  media="(min-width: 1023px)"
                  srcset={require("../../static/images/AnimaciónHero.gif")}
                />
                <source
                  media="(max-width: 1024px)"
                  srcset={require("../../static/images/AnimaciónHerox2.gif")}
                />
                <source
                  media="(max-width: 2000px)"
                  srcset={require("../../static/images/AnimaciónHerox3.gif")}
                />
                <img
                  src={require("../../static/images/AnimaciónHerox3.gif")}
                  alt="home hero"
                  width="100%"
                />
              </picture>
            </Grid>
          </Grid>

          <Grid
            container
            direction={{ xs: "column", md: "row" }}
            justifyContent="center"
            alignItems="center"
            sx={{
              marginBottom: "0px",
              paddingInline: { xs: "30px", sm: "76px" },
              marginTop: "292px",
            }}
          >
            <Grid item xs={12} md={5}>
              <img
                src={require("../../static/images/Feature2_Home.png")}
                alt="home section"
                width="100%"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "grid",
                alignContent: "center",
                paddingRight: { xs: 0, md: 10 },
              }}
            >
              <h2 className="" style={{ margin: 0, color: "var(--primary)" }}>
                ¿Quiénes somos?{" "}
              </h2>
              <p className="base">
                Juan Macarlupu es la institución de formación especializada para
                traductores más grande y mejor valorada por la comunidad.
                Nuestro objetivo es ayudar a nuestros colegas a perfeccionar sus
                habilidades para que puedan alcanzar sus objetivos profesionales
                y personales.
              </p>

              <p className="base">
                {" "}
                Juan, su fundador, es traductor público nacional de Inglés,
                graduado de la Universidad Nacional de Córdoba, y corrector
                internacional de textos en lengua española por la Fundación
                Litterae.
              </p>

              <p className="base">
                Desde 2013, cuando comenzó enseñando herramientas de traducción
                asistida a domicilio, ha sumado propuestas nuevas, como
                capacitaciones en otras áreas, capacitaciones a cargo de colegas
                expertos en otros rubros, capacitación en línea y trayectos de
                formación especializada, entre otras.
              </p>

              <p className="base">
                {" "}
                Trabajamos todos los días para impulsar tu carrera. Trabajamos
                para que trabajes mejor 💪🏽.{" "}
              </p>
            </Grid>
          </Grid>
        </div>

        {/* black section services */}
        <div className="container-cards-services">
          <Grid
            container
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={6} md={3} style={{ height: "100%" }}>
              <img
                src={require("../../static/icon/punto.png")}
                height="56"
                alt="icono elección"
              />

              <p className="medium text-button">
                15 000 colegas ya eligieron desarrollar su carrera con nosotros
                (y la mayoría vuelven para seguir creciendo).
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={3} style={{ height: "100%" }}>
              <img
                src={require("../../static/icon/tilde.png")}
                height="56"
                alt="icono garantia"
              />

              <p className="medium text-button">
                Ofrecemos garantía de satisfacción. Si un curso que tomaste no
                te gustó, te devolvemos el pago. Así de simple.
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={3} style={{ height: "100%" }}>
              <img
                src={require("../../static/icon/coins.png")}
                height="56"
                alt="icono aranceles"
              />

              <p className="medium text-button">
                Nuestros aranceles son asequibles sin sacrificar la calidad
                porque trabajamos con un modelo de negocios diferente.
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={3} style={{ height: "100%" }}>
              <img
                src={require("../../static/icon/5stars.png")}
                height="56"
                alt="icono cinco estrellas"
              />

              <p className="medium text-button">
                Tenemos una calificación perfecta de cinco estrellas en
                Facebook, basada en más de quinientos comentarios.
              </p>
            </Grid>
          </Grid>
        </div>

        <div
          className="padding-inline reviews-image"
          style={{ marginBottom: "100px" }}
        >
          <h3
            style={{
              color: "var(--primary)",
              textAlign: "center",
              paddingBottom: "30px",
            }}
          >
            ¿Qué opinan tus colegas?
          </h3>

          {!loading ? (
            <div>
              <div className="desktop-600">
                <Slider {...settings}>
                  {listReviews.map((reviewResponsive, i) => (
                    <div>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{ height: "100%" }}
                      >
                        <Paper elevation={3} id="card-review">
                          <a
                            href={reviewResponsive.reviewer_profile}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div style={{ textAlign: "center" }}>
                              <svg
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="24" cy="24" r="24" fill="#76729A" />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.9793 17.6248C17.9793 14.2996 20.6749 11.604 24.0002 11.604C27.3254 11.604 30.021 14.2996 30.021 17.6248C30.021 20.9501 27.3254 23.6457 24.0002 23.6457C20.6749 23.6457 17.9793 20.9501 17.9793 17.6248ZM24.0002 13.729C21.8486 13.729 20.1043 15.4732 20.1043 17.6248C20.1043 19.7764 21.8486 21.5207 24.0002 21.5207C26.1518 21.5207 27.896 19.7764 27.896 17.6248C27.896 15.4732 26.1518 13.729 24.0002 13.729Z"
                                  fill="#F9F9F9"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M18.3335 27.8957C16.5731 27.8957 15.146 29.3228 15.146 31.0832V32.7666C15.146 32.7922 15.1646 32.8141 15.1899 32.8182C21.0248 33.7708 26.9756 33.7708 32.8105 32.8182C32.8358 32.8141 32.8543 32.7922 32.8543 32.7666V31.0832C32.8543 29.3228 31.4272 27.8957 29.6668 27.8957H29.1839C29.1466 27.8957 29.1095 27.9016 29.074 27.9132L27.8479 28.3135C25.3477 29.1299 22.6526 29.1299 20.1525 28.3135L18.9263 27.9132C18.8908 27.9016 18.8537 27.8957 18.8164 27.8957H18.3335ZM13.021 31.0832C13.021 28.1492 15.3995 25.7707 18.3335 25.7707H18.8164C19.0778 25.7707 19.3375 25.812 19.5859 25.8931L20.8121 26.2935C22.8836 26.9699 25.1167 26.9699 27.1882 26.2935L28.4144 25.8931C28.6628 25.812 28.9226 25.7707 29.1839 25.7707H29.6668C32.6008 25.7707 34.9793 28.1492 34.9793 31.0832V32.7666C34.9793 33.8337 34.206 34.7435 33.1529 34.9154C27.0912 35.9051 20.9091 35.9051 14.8475 34.9154C13.7943 34.7435 13.021 33.8336 13.021 32.7666V31.0832Z"
                                  fill="#F9F9F9"
                                />
                              </svg>
                            </div>
                            <h6
                              className="text-dark bold noneMargin text-center"
                              style={{ marginTop: "8px" }}
                            >
                              {reviewResponsive.reviewer_name}
                            </h6>
                            <p className="text small noneMargin text-dark">
                              Traductora
                            </p>
                          </a>
                          <p className="text text-dark">{reviewResponsive.review}</p>

                          <Stack
                            sx={{
                              position: "absolute",
                              right: "24px",
                              bottom: "10px",
                            }}
                            direction={{ xs: "row" }}
                            justifyContent="flex-end"
                          >
                            <div>
                              <svg
                                width="97"
                                height="16"
                                viewBox="0 0 97 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.49951 1.9976C9.24259 1.55484 8.60319 1.55483 8.34627 1.9976L6.5369 5.11581C6.40998 5.33453 6.19062 5.48379 5.94058 5.52156L2.5497 6.03371C1.98018 6.11973 1.78041 6.8388 2.22394 7.20627L4.75 9.29908C4.98383 9.49281 5.09378 9.79838 5.03698 10.0967L4.3762 13.5668C4.27174 14.1153 4.8476 14.5415 5.3417 14.2814L8.53464 12.6002C8.77766 12.4722 9.06812 12.4722 9.31114 12.6002L12.5041 14.2814C12.9982 14.5415 13.574 14.1153 13.4696 13.5668L12.8088 10.0967C12.752 9.79838 12.862 9.49281 13.0958 9.29908L15.6218 7.20627C16.0654 6.8388 15.8656 6.11973 15.2961 6.03371L11.9052 5.52156C11.6552 5.48379 11.4358 5.33453 11.3089 5.11581L9.49951 1.9976Z"
                                  fill="#F8BF7F"
                                />
                                <path
                                  d="M29.4995 1.9976C29.2426 1.55484 28.6032 1.55483 28.3463 1.9976L26.5369 5.11581C26.41 5.33453 26.1906 5.48379 25.9406 5.52156L22.5497 6.03371C21.9802 6.11973 21.7804 6.8388 22.2239 7.20627L24.75 9.29908C24.9838 9.49281 25.0938 9.79838 25.037 10.0967L24.3762 13.5668C24.2717 14.1153 24.8476 14.5415 25.3417 14.2814L28.5346 12.6002C28.7777 12.4722 29.0681 12.4722 29.3111 12.6002L32.5041 14.2814C32.9982 14.5415 33.574 14.1153 33.4696 13.5668L32.8088 10.0967C32.752 9.79838 32.862 9.49281 33.0958 9.29908L35.6218 7.20627C36.0654 6.8388 35.8656 6.11973 35.2961 6.03371L31.9052 5.52156C31.6552 5.48379 31.4358 5.33453 31.3089 5.11581L29.4995 1.9976Z"
                                  fill="#F8BF7F"
                                />
                                <path
                                  d="M49.4995 1.9976C49.2426 1.55484 48.6032 1.55483 48.3463 1.9976L46.5369 5.11581C46.41 5.33453 46.1906 5.48379 45.9406 5.52156L42.5497 6.03371C41.9802 6.11973 41.7804 6.8388 42.2239 7.20627L44.75 9.29908C44.9838 9.49281 45.0938 9.79838 45.037 10.0967L44.3762 13.5668C44.2717 14.1153 44.8476 14.5415 45.3417 14.2814L48.5346 12.6002C48.7777 12.4722 49.0681 12.4722 49.3111 12.6002L52.5041 14.2814C52.9982 14.5415 53.574 14.1153 53.4696 13.5668L52.8088 10.0967C52.752 9.79838 52.862 9.49281 53.0958 9.29908L55.6218 7.20627C56.0654 6.8388 55.8656 6.11973 55.2961 6.03371L51.9052 5.52156C51.6552 5.48379 51.4358 5.33453 51.3089 5.11581L49.4995 1.9976Z"
                                  fill="#F8BF7F"
                                />
                                <path
                                  d="M69.4995 1.9976C69.2426 1.55484 68.6032 1.55483 68.3463 1.9976L66.5369 5.11581C66.41 5.33453 66.1906 5.48379 65.9406 5.52156L62.5497 6.03371C61.9802 6.11973 61.7804 6.8388 62.2239 7.20627L64.75 9.29908C64.9838 9.49281 65.0938 9.79838 65.037 10.0967L64.3762 13.5668C64.2717 14.1153 64.8476 14.5415 65.3417 14.2814L68.5346 12.6002C68.7777 12.4722 69.0681 12.4722 69.3111 12.6002L72.5041 14.2814C72.9982 14.5415 73.574 14.1153 73.4696 13.5668L72.8088 10.0967C72.752 9.79838 72.862 9.49281 73.0958 9.29908L75.6218 7.20627C76.0654 6.8388 75.8656 6.11973 75.2961 6.03371L71.9052 5.52156C71.6552 5.48379 71.4358 5.33453 71.3089 5.11581L69.4995 1.9976Z"
                                  fill="#F8BF7F"
                                />
                                <path
                                  d="M89.4995 1.9976C89.2426 1.55484 88.6032 1.55483 88.3463 1.9976L86.5369 5.11581C86.41 5.33453 86.1906 5.48379 85.9406 5.52156L82.5497 6.03371C81.9802 6.11973 81.7804 6.8388 82.2239 7.20627L84.75 9.29908C84.9838 9.49281 85.0938 9.79838 85.037 10.0967L84.3762 13.5668C84.2717 14.1153 84.8476 14.5415 85.3417 14.2814L88.5346 12.6002C88.7777 12.4722 89.0681 12.4722 89.3111 12.6002L92.5041 14.2814C92.9982 14.5415 93.574 14.1153 93.4696 13.5668L92.8088 10.0967C92.752 9.79838 92.862 9.49281 93.0958 9.29908L95.6218 7.20627C96.0654 6.8388 95.8656 6.11973 95.2961 6.03371L91.9052 5.52156C91.6552 5.48379 91.4358 5.33453 91.3089 5.11581L89.4995 1.9976Z"
                                  fill="#F8BF7F"
                                />
                              </svg>
                            </div>
                          </Stack>
                        </Paper>
                      </Grid>
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="mobile-600">
                <div className="AppSlick">
                  <div>
                    <Slider {...settings}>
                      {listReviews.map((reviewResponsive, i) => (
                        <div>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            sx={{ height: "100%" }}
                          >
                            <Paper elevation={3} id="card-review">
                              <a
                                href={reviewResponsive.reviewer_profile}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <div style={{ textAlign: "center" }}>
                                  <svg
                                    width="48"
                                    height="48"
                                    viewBox="0 0 48 48"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="24"
                                      cy="24"
                                      r="24"
                                      fill="#76729A"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M17.9793 17.6248C17.9793 14.2996 20.6749 11.604 24.0002 11.604C27.3254 11.604 30.021 14.2996 30.021 17.6248C30.021 20.9501 27.3254 23.6457 24.0002 23.6457C20.6749 23.6457 17.9793 20.9501 17.9793 17.6248ZM24.0002 13.729C21.8486 13.729 20.1043 15.4732 20.1043 17.6248C20.1043 19.7764 21.8486 21.5207 24.0002 21.5207C26.1518 21.5207 27.896 19.7764 27.896 17.6248C27.896 15.4732 26.1518 13.729 24.0002 13.729Z"
                                      fill="#F9F9F9"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M18.3335 27.8957C16.5731 27.8957 15.146 29.3228 15.146 31.0832V32.7666C15.146 32.7922 15.1646 32.8141 15.1899 32.8182C21.0248 33.7708 26.9756 33.7708 32.8105 32.8182C32.8358 32.8141 32.8543 32.7922 32.8543 32.7666V31.0832C32.8543 29.3228 31.4272 27.8957 29.6668 27.8957H29.1839C29.1466 27.8957 29.1095 27.9016 29.074 27.9132L27.8479 28.3135C25.3477 29.1299 22.6526 29.1299 20.1525 28.3135L18.9263 27.9132C18.8908 27.9016 18.8537 27.8957 18.8164 27.8957H18.3335ZM13.021 31.0832C13.021 28.1492 15.3995 25.7707 18.3335 25.7707H18.8164C19.0778 25.7707 19.3375 25.812 19.5859 25.8931L20.8121 26.2935C22.8836 26.9699 25.1167 26.9699 27.1882 26.2935L28.4144 25.8931C28.6628 25.812 28.9226 25.7707 29.1839 25.7707H29.6668C32.6008 25.7707 34.9793 28.1492 34.9793 31.0832V32.7666C34.9793 33.8337 34.206 34.7435 33.1529 34.9154C27.0912 35.9051 20.9091 35.9051 14.8475 34.9154C13.7943 34.7435 13.021 33.8336 13.021 32.7666V31.0832Z"
                                      fill="#F9F9F9"
                                    />
                                  </svg>
                                </div>
                                <h6
                                  className="text-dark bold noneMargin text-center"
                                  style={{ marginTop: "8px" }}
                                >
                                  {reviewResponsive.reviewer_name}
                                </h6>
                                <p className="text small noneMargin text-center text-dark">
                                  Traductora
                                </p>
                              </a>
                              <p className="text text-dark">{reviewResponsive.review}</p>

                              <Stack
                                sx={{
                                  position: "absolute",
                                  right: "24px",
                                  bottom: "10px",
                                }}
                                direction={{ xs: "row" }}
                                justifyContent="flex-end"
                              >
                                <div>
                                  <svg
                                    width="97"
                                    height="16"
                                    viewBox="0 0 97 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.49951 1.9976C9.24259 1.55484 8.60319 1.55483 8.34627 1.9976L6.5369 5.11581C6.40998 5.33453 6.19062 5.48379 5.94058 5.52156L2.5497 6.03371C1.98018 6.11973 1.78041 6.8388 2.22394 7.20627L4.75 9.29908C4.98383 9.49281 5.09378 9.79838 5.03698 10.0967L4.3762 13.5668C4.27174 14.1153 4.8476 14.5415 5.3417 14.2814L8.53464 12.6002C8.77766 12.4722 9.06812 12.4722 9.31114 12.6002L12.5041 14.2814C12.9982 14.5415 13.574 14.1153 13.4696 13.5668L12.8088 10.0967C12.752 9.79838 12.862 9.49281 13.0958 9.29908L15.6218 7.20627C16.0654 6.8388 15.8656 6.11973 15.2961 6.03371L11.9052 5.52156C11.6552 5.48379 11.4358 5.33453 11.3089 5.11581L9.49951 1.9976Z"
                                      fill="#F8BF7F"
                                    />
                                    <path
                                      d="M29.4995 1.9976C29.2426 1.55484 28.6032 1.55483 28.3463 1.9976L26.5369 5.11581C26.41 5.33453 26.1906 5.48379 25.9406 5.52156L22.5497 6.03371C21.9802 6.11973 21.7804 6.8388 22.2239 7.20627L24.75 9.29908C24.9838 9.49281 25.0938 9.79838 25.037 10.0967L24.3762 13.5668C24.2717 14.1153 24.8476 14.5415 25.3417 14.2814L28.5346 12.6002C28.7777 12.4722 29.0681 12.4722 29.3111 12.6002L32.5041 14.2814C32.9982 14.5415 33.574 14.1153 33.4696 13.5668L32.8088 10.0967C32.752 9.79838 32.862 9.49281 33.0958 9.29908L35.6218 7.20627C36.0654 6.8388 35.8656 6.11973 35.2961 6.03371L31.9052 5.52156C31.6552 5.48379 31.4358 5.33453 31.3089 5.11581L29.4995 1.9976Z"
                                      fill="#F8BF7F"
                                    />
                                    <path
                                      d="M49.4995 1.9976C49.2426 1.55484 48.6032 1.55483 48.3463 1.9976L46.5369 5.11581C46.41 5.33453 46.1906 5.48379 45.9406 5.52156L42.5497 6.03371C41.9802 6.11973 41.7804 6.8388 42.2239 7.20627L44.75 9.29908C44.9838 9.49281 45.0938 9.79838 45.037 10.0967L44.3762 13.5668C44.2717 14.1153 44.8476 14.5415 45.3417 14.2814L48.5346 12.6002C48.7777 12.4722 49.0681 12.4722 49.3111 12.6002L52.5041 14.2814C52.9982 14.5415 53.574 14.1153 53.4696 13.5668L52.8088 10.0967C52.752 9.79838 52.862 9.49281 53.0958 9.29908L55.6218 7.20627C56.0654 6.8388 55.8656 6.11973 55.2961 6.03371L51.9052 5.52156C51.6552 5.48379 51.4358 5.33453 51.3089 5.11581L49.4995 1.9976Z"
                                      fill="#F8BF7F"
                                    />
                                    <path
                                      d="M69.4995 1.9976C69.2426 1.55484 68.6032 1.55483 68.3463 1.9976L66.5369 5.11581C66.41 5.33453 66.1906 5.48379 65.9406 5.52156L62.5497 6.03371C61.9802 6.11973 61.7804 6.8388 62.2239 7.20627L64.75 9.29908C64.9838 9.49281 65.0938 9.79838 65.037 10.0967L64.3762 13.5668C64.2717 14.1153 64.8476 14.5415 65.3417 14.2814L68.5346 12.6002C68.7777 12.4722 69.0681 12.4722 69.3111 12.6002L72.5041 14.2814C72.9982 14.5415 73.574 14.1153 73.4696 13.5668L72.8088 10.0967C72.752 9.79838 72.862 9.49281 73.0958 9.29908L75.6218 7.20627C76.0654 6.8388 75.8656 6.11973 75.2961 6.03371L71.9052 5.52156C71.6552 5.48379 71.4358 5.33453 71.3089 5.11581L69.4995 1.9976Z"
                                      fill="#F8BF7F"
                                    />
                                    <path
                                      d="M89.4995 1.9976C89.2426 1.55484 88.6032 1.55483 88.3463 1.9976L86.5369 5.11581C86.41 5.33453 86.1906 5.48379 85.9406 5.52156L82.5497 6.03371C81.9802 6.11973 81.7804 6.8388 82.2239 7.20627L84.75 9.29908C84.9838 9.49281 85.0938 9.79838 85.037 10.0967L84.3762 13.5668C84.2717 14.1153 84.8476 14.5415 85.3417 14.2814L88.5346 12.6002C88.7777 12.4722 89.0681 12.4722 89.3111 12.6002L92.5041 14.2814C92.9982 14.5415 93.574 14.1153 93.4696 13.5668L92.8088 10.0967C92.752 9.79838 92.862 9.49281 93.0958 9.29908L95.6218 7.20627C96.0654 6.8388 95.8656 6.11973 95.2961 6.03371L91.9052 5.52156C91.6552 5.48379 91.4358 5.33453 91.3089 5.11581L89.4995 1.9976Z"
                                      fill="#F8BF7F"
                                    />
                                  </svg>
                                </div>
                              </Stack>
                            </Paper>
                          </Grid>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "500px",
                width: "90vw",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </div>

        <div className="padding-inline" style={{ marginBottom: "100px" }}>
          <h3
            style={{
              color: "var(--primary)",
              textAlign: "left",
              marginLeft: "40px",
            }}
          >
            Cursos Recomendados
          </h3>
          {!loading ? (
              <div className="">
                <Slider {...settings}>
                  {listRecommendedCourse.map((course) => (
                    <CourseCard course={course} isRecommended={true}>
                      <CardBuyCourseCTA course={course}/>
                    </CourseCard>
                  ))}
                </Slider>
              </div>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "500px",
                width: "90vw",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </div>
        <div className="container-contact">
          <Grid
            className="charlemos"
            id="contacto"
            container
            spacing={6}
            direction={{ xs: "column", md: "row" }}
            justifyContent="center"
            alignItems="stretch"
            sx={{
              marginBottom: "0px",
              paddingInline: { xs: "30px", sm: "76px" },
              paddingTop: "292px",
              paddingBottom: "292px",
            }}
          >
            <Grid item xs={12} md={6} sx={{
               marginBottom: { xs: "30px", md: "0px" }
            }}>
              <h3
                style={{
                  margin: 0,
                  marginBottom: "30px",
                  color: "var(--primary)",
                }}
              >
                ¡Charlemos!
              </h3>

              <p className="base" style={{ marginTop: "10px" }}>
                Con gusto te ayudo. Si tenés una consulta sobre actividades de
                capacitación, sobre alguno de los otros servicios que ofrecemos
                o sobre algo diferente, preguntame que, si sé, con gusto te
                respondo. A veces me puedo demorar un poco, en función de la
                cantidad de correos que tenga, pero siempre contesto. ¡Escribime
                y charlamos!
              </p>
            </Grid>
            {!message ? (
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "grid", alignContent: "center" }}
              >
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Grid xs={6}>
                    <TextField
                      error={!!isValidFields("name")}
                      helperText={isValidFields("name")}
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      style={{ borderRadius: 10 }}
                      label="Nombre"
                      placeholder="Nombre"
                    />
                  </Grid>
                  <Grid
                    xs={6}
                    style={{ display: "flex", justifyContent: "right" }}
                  >
                    <TextField
                      error={!!isValidFields("lastName")}
                      helperText={isValidFields("lastName")}
                      value={lastName}
                      onChange={(event) => setLastName(event.target.value)}
                      style={{ borderRadius: 10 }}
                      label="Apellido"
                      placeholder="Apellido"
                    />
                  </Grid>
                </Grid>
                <TextField
                  error={!!isValidFields("email")}
                  helperText={isValidFields("email")}
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  style={{ borderRadius: 10, marginTop: 26, width: "100%" }}
                  label="Correo electronico"
                  placeholder="Correo electronico"
                  fullWidth
                />
                <TextareaAutosize
                  minRows={5}
                  error={!!isValidFields("messageUser")}
                  helperText={isValidFields("messageUser")}
                  value={messageUser}
                  onChange={(event) => setmessageUser(event.target.value)}
                  className="textarea"
                  style={{
                    borderRadius: 10,
                    marginTop: 26,
                    background: "var(--bg-dark)",
                  }}
                  label="¿Cómo te podemos ayudar?"
                  placeholder="¿Cómo te podemos ayudar?"
                />
                <Button
                  id="btnLogin"
                  disabled={!name || !lastName || !email || !messageUser}
                  onClick={() => sendEmail()}
                  class="subtitle2 btnLogin"
                  style={{
                    backgroundColor: "var(--secondary)",
                    borderRadius: 70,
                    color: "#F9F9F9",
                    zIndex: 0,
                    marginTop: 20,
                    height: "40px",
                  }}
                  type="submit"
                  variant="contained"
                  fullWidth
                >
                  Enviar
                </Button>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                md={6}
                
              >
                <h4
                className="text-center"
                  style={{
                    margin: 0,
                    marginBottom: "30px",
                    color: "var(--primary)",
                  }}
                >
                  {message}
                </h4>
              </Grid>
            )}
          </Grid>
        </div>
        <div className="contactShared">
          <Stack
            className="contactShareSoon"
            direction="row"
            spacing={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <a href="https://www.facebook.com/juan.macarlupu" target="_blank" rel="noreferrer">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F2F2F2" />
                <rect
                  width="24"
                  height="24"
                  transform="translate(8 8)"
                  fill="#F2F2F2"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.4877 11.7877C19.4723 10.8031 20.8076 10.25 22.2 10.25H24.9C25.3142 10.25 25.65 10.5858 25.65 11V14.6C25.65 15.0142 25.3142 15.35 24.9 15.35H22.2C22.1602 15.35 22.1221 15.3658 22.0939 15.3939C22.0658 15.4221 22.05 15.4602 22.05 15.5V17.45H24.9C25.131 17.45 25.349 17.5564 25.4912 17.7384C25.6333 17.9205 25.6836 18.1578 25.6276 18.3819L24.7276 21.9819C24.6441 22.3158 24.3442 22.55 24 22.55H22.05V29C22.05 29.4142 21.7142 29.75 21.3 29.75H17.7C17.2858 29.75 16.95 29.4142 16.95 29V22.55H15C14.5858 22.55 14.25 22.2142 14.25 21.8V18.2C14.25 17.7858 14.5858 17.45 15 17.45H16.95V15.5C16.95 14.1076 17.5031 12.7723 18.4877 11.7877ZM22.2 11.75C21.2054 11.75 20.2516 12.1451 19.5483 12.8483C18.8451 13.5516 18.45 14.5054 18.45 15.5V18.2C18.45 18.6142 18.1142 18.95 17.7 18.95H15.75V21.05H17.7C18.1142 21.05 18.45 21.3858 18.45 21.8V28.25H20.55V21.8C20.55 21.3858 20.8858 21.05 21.3 21.05H23.4144L23.9394 18.95H21.3C20.8858 18.95 20.55 18.6142 20.55 18.2V15.5C20.55 15.0624 20.7238 14.6427 21.0333 14.3333C21.3427 14.0238 21.7624 13.85 22.2 13.85H24.15V11.75H22.2Z"
                  fill="#4400A5"
                />
              </svg>
            </a>

            <a
              href="https://www.instagram.com/tradjuanmacarlupu/"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="#F2F2F2" />
                <rect
                  width="24"
                  height="24"
                  transform="translate(8 8)"
                  fill="#F2F2F2"
                />
                <path
                  d="M24.0002 15C24.0002 14.4478 24.4479 14 25.0002 14C25.5525 14 26.0002 14.4478 26.0002 15C26.0002 15.5523 25.5525 16 25.0002 16C24.4479 16 24.0002 15.5523 24.0002 15Z"
                  fill="#4400A5"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.0002 15.25C17.3768 15.25 15.2502 17.3767 15.2502 20C15.2502 22.6234 17.3768 24.75 20.0002 24.75C22.6235 24.75 24.7502 22.6234 24.7502 20C24.7502 17.3767 22.6235 15.25 20.0002 15.25ZM16.7502 20C16.7502 18.2051 18.2053 16.75 20.0002 16.75C21.7951 16.75 23.2502 18.2051 23.2502 20C23.2502 21.795 21.7951 23.25 20.0002 23.25C18.2053 23.25 16.7502 21.795 16.7502 20Z"
                  fill="#4400A5"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.2584 10.8331C21.7919 10.4456 18.2085 10.4456 14.742 10.8331C12.7299 11.0579 11.1056 12.6429 10.869 14.6655C10.4545 18.2098 10.4545 21.7903 10.869 25.3346C11.1056 27.3571 12.7299 28.9422 14.742 29.167C18.2085 29.5545 21.7919 29.5545 25.2584 29.167C27.2705 28.9422 28.8948 27.3571 29.1314 25.3346C29.5459 21.7903 29.5459 18.2098 29.1314 14.6655C28.8948 12.6429 27.2705 11.0579 25.2584 10.8331ZM14.9086 12.3238C18.2644 11.9487 21.736 11.9487 25.0918 12.3238C26.4219 12.4724 27.4874 13.5221 27.6415 14.8397C28.0425 18.2683 28.0425 21.7318 27.6415 25.1604C27.4874 26.478 26.4219 27.5277 25.0918 27.6763C21.736 28.0514 18.2644 28.0514 14.9086 27.6763C13.5785 27.5277 12.513 26.478 12.3589 25.1604C11.9579 21.7318 11.9579 18.2683 12.3589 14.8397C12.513 13.5221 13.5785 12.4724 14.9086 12.3238Z"
                  fill="#4400A5"
                />
              </svg>
            </a>
          </Stack>
        </div>
      </div>
      <BottomBar />
    </div>
  );
}

export const style = {
  listCoursesContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  card: {
    margin: "24px",
    marginLeft: "0px",
    borderImage: "linear-gradient(to top, '#00C853', '#B2FF59')",
  },
};
