import React, { createContext, useReducer } from 'react'
import { initialValues } from './InitialValues'

const isText = RegExp(/^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ\s]*$/)
const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
const isPhone = RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4,6})$/) // us
const isZip = RegExp(/^[0-9]{5}([- /]?[0-9]{4})?$/) // us
const isNumber = RegExp(/^\d+$/)
const isDni = RegExp(/^\d+$/)

// Applied to all fields
const variant = 'standard'
const margin = 'normal'

export declare type ValidationSchema = Record<
  string,
  {
    value?: any
    error?: string
    required?: boolean
    validate?:
    | 'text'
    | 'number'
    | 'email'
    | 'phone'
    | 'zip'
    | 'checkbox'
    | 'select'
    | 'dni'
    minLength?: number
    maxLength?: number
    helperText?: string
    disabled?:boolean
  }
>

type ContextProps = {
  activeStep: number
  formValues: ValidationSchema
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked?: boolean
  ) => void
  handleNext: () => void
  handleReset: () => void
  handleBack: () => void
  variant: 'outlined' | 'standard' | 'filled'
  margin: 'dense' | 'normal' | 'none'
}

export const AppContext = createContext<ContextProps>({
  activeStep: 0,
  formValues: initialValues,
  handleChange() { },
  handleReset() { },
  handleNext() { },
  handleBack() { },
  variant,
  margin
})

interface ProviderProps {
  children: React.ReactNode
}

type State = {
  activeStep: number
  formValues: ValidationSchema
}

type Action =
  | { type: 'increase' }
  | { type: 'reset' }
  | { type: 'decrease' }
  | { type: 'form-value'; name: string; fieldValue: any }
  | { type: 'form-error'; name: string; error: string }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'increase':
      return {
        ...state,
        activeStep: state.activeStep + 1
      }
    case 'reset':
        return {
          ...state,
          activeStep: 0
        }
    case 'decrease':
      return {
        ...state,
        activeStep: state.activeStep - 1
      }
    case 'form-value':
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.name]: {
            ...state.formValues[action.name],
            value: action.fieldValue
          }
        }
      }
    case 'form-error':
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.name]: {
            ...state.formValues[action.name],
            error: action.error
          }
        }
      }

    default:
      return state
  }
}

export function StepsProvider({ children }: ProviderProps) {
  const [{ activeStep, formValues }, dispatch] = useReducer(reducer, {
    activeStep: 0,
    formValues: initialValues
  })

  // Proceed to next step
  const handleNext = () => dispatch({ type: 'increase' })
  
  const handleReset = () => dispatch({ type: 'reset' })
  // Go back to prev step
  const handleBack = () => dispatch({ type: 'decrease' })

  // Handle form change
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked?: boolean
  ) => {
    const { type, name, value } = event.target
    const fieldValue = type === 'checkbox' ? checked : value

    dispatch({ type: 'form-value', name, fieldValue })

    const fieldName = initialValues[name]
    if (!fieldName) return

    const { required, validate, minLength, maxLength, helperText } = fieldName

    let error = ''

    if (required && !fieldValue) error = 'Campo requerido'
    if (minLength && value && value.length < minLength)
      error = `Este campo requiere al menos ${minLength} caracteres.`
    if (maxLength && value && value.length > maxLength)
      error = 'Excediste el límite de caracteres.'
    if (validate) {
      switch (validate) {
        case 'text':
          if (value && !isText.test(value))
            error = helperText || 'Este campo acepta texto únicamente.'
          break

        case 'number':
          if (value && !isNumber.test(value))
            error = helperText || 'Este campo acepta números únicamente.'
          break

        case 'email':
          if (value && !isEmail.test(value))
            error = helperText || 'Ingresá una dirección de correo electrónico válida.'
          break

        case 'phone':
          if (value && !isPhone.test(value))
            error =
              helperText ||
              'Ingresá un teléfono válido.'
          break

        case 'dni':
          if (value && !isDni.test(value))
            error =
              helperText ||
              'Ingresá un DNI válido de al menos ocho caracteres.'
          break

        case 'zip':
          if (value && !isZip.test(value))
            error = helperText || 'Ingresá un código postal válido.'
          break

        case 'checkbox':
          if (!checked) error = helperText || 'Elegí una opción válida.'
          break

        case 'select':
          if (!value) error = helperText || 'Seleccioná una opción.'
          break

        default:
          break
      }
    }

    dispatch({ type: 'form-error', name, error })
  }

  return (
    <AppContext.Provider
      value={{
        activeStep,
        formValues,
        handleChange,
        handleReset,
        handleNext,
        handleBack,
        variant,
        margin
      }}
    >
      <div className="mui-step-form">{children}</div>
    </AppContext.Provider>
  )
}
