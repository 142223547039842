let pageLocation = '/infoPersonal'
const MyProfileIcon = (props) => (
  pageLocation === props.currentLocation ?
            (<svg
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                strokeLinejoin: "round",
                strokeMiterlimit: 2,
              }}
              width={props.width+2+'px'}
            height={props.height+2+'px'}
              {...props}
            >
              <path
                d="M17.667.405a39.858 39.858 0 0 0-11.335 0 4.067 4.067 0 0 0-3.47 3.656l-.161 1.775a68.049 68.049 0 0 0 0 12.328l.161 1.775a4.067 4.067 0 0 0 3.47 3.656c3.759.54 7.576.54 11.335 0a4.067 4.067 0 0 0 3.471-3.656l.161-1.775a68.049 68.049 0 0 0 0-12.328l-.161-1.775A4.067 4.067 0 0 0 17.667.405Zm-8.211 7.78a2.544 2.544 0 1 1 5.088 0 2.544 2.544 0 0 1-5.088 0Zm-2.543 8.266a3.815 3.815 0 0 1 3.815-3.815h2.544a3.815 3.815 0 0 1 3.815 3.815c0 .703-.569 1.272-1.272 1.272h-7.63a1.271 1.271 0 0 1-1.272-1.272Z"
                style={{
                  fill: props.lightColor,
                }}
              />
              <path
                d="M12 24Zm0-24c1.894 0 3.788.135 5.667.405a4.067 4.067 0 0 1 3.471 3.656l.161 1.775a68.049 68.049 0 0 1 0 12.328l-.161 1.775a4.067 4.067 0 0 1-3.471 3.656c-1.879.27-3.773.405-5.667.405v-6.277h3.815c.703 0 1.272-.569 1.272-1.272a3.815 3.815 0 0 0-3.815-3.815H12v-1.908a2.543 2.543 0 1 0 0-5.087V0Zm0 0Z"
                style={{
                  fill: props.darkColor,
                }}
              />
            </svg>)
            :
            (<svg
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                strokeLinejoin: "round",
                strokeMiterlimit: 2,
              }}
              width={props.width}
              height={props.height}
            >
              <path
                className='icon-svg-path-color'
                d="M9.457 8.185a2.543 2.543 0 1 1 5.086-.002 2.543 2.543 0 0 1-5.086.002Zm1.271 4.451a3.815 3.815 0 0 0-3.815 3.815c0 .702.569 1.272 1.272 1.272h7.63c.703 0 1.272-.57 1.272-1.272a3.815 3.815 0 0 0-3.815-3.815h-2.544Z"
                style={{
                  fillRule: "nonzero",
                }}
              />
              <path
                className='icon-svg-path-color'
                d="M6.333.405c3.759-.54 7.576-.54 11.335 0a4.067 4.067 0 0 1 3.47 3.656l.161 1.775a68.049 68.049 0 0 1 0 12.328l-.161 1.775a4.066 4.066 0 0 1-3.47 3.656c-3.759.54-7.576.54-11.335 0a4.066 4.066 0 0 1-3.471-3.656l-.161-1.775a68.049 68.049 0 0 1 0-12.328l.161-1.775A4.067 4.067 0 0 1 6.333.405Zm11.063 1.888a37.958 37.958 0 0 0-10.792 0 2.158 2.158 0 0 0-1.842 1.94l-.161 1.775a66.076 66.076 0 0 0 0 11.984l.161 1.774a2.16 2.16 0 0 0 1.842 1.941c3.579.514 7.213.514 10.792 0a2.16 2.16 0 0 0 1.842-1.941l.161-1.774a66.076 66.076 0 0 0 0-11.984l-.161-1.775a2.158 2.158 0 0 0-1.842-1.94Z"
              />
            </svg>)
);
export default MyProfileIcon;