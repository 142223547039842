const ArgentineProvinces = [
    {
      properties: {
        nombre_completo: "Provincia de Misiones",
        fuente: "IGN",
        iso_id: "AR-N",
        nombre: "Misiones",
        id: "54",
        categoria: "Provincia",
        iso_nombre: "Misiones",
      },
      type: "Feature",
      geometry: {
        coordinates: [-54.6516966230371, -26.8753965086829],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia de San Luis",
        fuente: "IGN",
        iso_id: "AR-D",
        nombre: "San Luis",
        id: "74",
        categoria: "Provincia",
        iso_nombre: "San Luis",
      },
      type: "Feature",
      geometry: {
        coordinates: [-66.0281298195836, -33.7577257449137],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia de San Juan",
        fuente: "IGN",
        iso_id: "AR-J",
        nombre: "San Juan",
        id: "70",
        categoria: "Provincia",
        iso_nombre: "San Juan",
      },
      type: "Feature",
      geometry: {
        coordinates: [-68.8894908486844, -30.8653679979618],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia de Entre Ríos",
        fuente: "IGN",
        iso_id: "AR-E",
        nombre: "Entre Ríos",
        id: "30",
        categoria: "Provincia",
        iso_nombre: "Entre Ríos",
      },
      type: "Feature",
      geometry: {
        coordinates: [-59.2014475514635, -32.0588735436448],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia de Santa Cruz",
        fuente: "IGN",
        iso_id: "AR-Z",
        nombre: "Santa Cruz",
        id: "78",
        categoria: "Provincia",
        iso_nombre: "Santa Cruz",
      },
      type: "Feature",
      geometry: {
        coordinates: [-69.9557621671973, -48.8154851827063],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia de Río Negro",
        fuente: "IGN",
        iso_id: "AR-R",
        nombre: "Río Negro",
        id: "62",
        categoria: "Provincia",
        iso_nombre: "Río Negro",
      },
      type: "Feature",
      geometry: {
        coordinates: [-67.229329893694, -40.4057957178801],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia del Chubut",
        fuente: "IGN",
        iso_id: "AR-U",
        nombre: "Chubut",
        id: "26",
        categoria: "Provincia",
        iso_nombre: "Chubut",
      },
      type: "Feature",
      geometry: {
        coordinates: [-68.5267593943345, -43.7886233529878],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia de Córdoba",
        fuente: "IGN",
        iso_id: "AR-X",
        nombre: "Córdoba",
        id: "14",
        categoria: "Provincia",
        iso_nombre: "Córdoba",
      },
      type: "Feature",
      geometry: {
        coordinates: [-63.8017532741662, -32.142932663607],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia de Mendoza",
        fuente: "IGN",
        iso_id: "AR-M",
        nombre: "Mendoza",
        id: "50",
        categoria: "Provincia",
        iso_nombre: "Mendoza",
      },
      type: "Feature",
      geometry: {
        coordinates: [-68.5831228183798, -34.6298873058957],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia de La Rioja",
        fuente: "IGN",
        iso_id: "AR-F",
        nombre: "La Rioja",
        id: "46",
        categoria: "Provincia",
        iso_nombre: "La Rioja",
      },
      type: "Feature",
      geometry: {
        coordinates: [-67.1817359694432, -29.685776298315],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia de Catamarca",
        fuente: "IGN",
        iso_id: "AR-K",
        nombre: "Catamarca",
        id: "10",
        categoria: "Provincia",
        iso_nombre: "Catamarca",
      },
      type: "Feature",
      geometry: {
        coordinates: [-66.9476824299928, -27.3358332810217],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia de La Pampa",
        fuente: "IGN",
        iso_id: "AR-L",
        nombre: "La Pampa",
        id: "42",
        categoria: "Provincia",
        iso_nombre: "La Pampa",
      },
      type: "Feature",
      geometry: {
        coordinates: [-65.4466546606951, -37.1315537735949],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia de Santiago del Estero",
        fuente: "IGN",
        iso_id: "AR-G",
        nombre: "Santiago del Estero",
        id: "86",
        categoria: "Provincia",
        iso_nombre: "Santiago del Estero",
      },
      type: "Feature",
      geometry: {
        coordinates: [-63.2523866568588, -27.7824116550944],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia de Corrientes",
        fuente: "IGN",
        iso_id: "AR-W",
        nombre: "Corrientes",
        id: "18",
        categoria: "Provincia",
        iso_nombre: "Corrientes",
      },
      type: "Feature",
      geometry: {
        coordinates: [-57.8012191977913, -28.7743047046407],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia de Santa Fe",
        fuente: "IGN",
        iso_id: "AR-S",
        nombre: "Santa Fe",
        id: "82",
        categoria: "Provincia",
        iso_nombre: "Santa Fe",
      },
      type: "Feature",
      geometry: {
        coordinates: [-60.9498369430241, -30.7069271588117],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia de Tucumán",
        fuente: "IGN",
        iso_id: "AR-T",
        nombre: "Tucumán",
        id: "90",
        categoria: "Provincia",
        iso_nombre: "Tucumán",
      },
      type: "Feature",
      geometry: {
        coordinates: [-65.3647579441481, -26.9478001830786],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia del Neuquén",
        fuente: "IGN",
        iso_id: "AR-Q",
        nombre: "Neuquén",
        id: "58",
        categoria: "Provincia",
        iso_nombre: "Neuquén",
      },
      type: "Feature",
      geometry: {
        coordinates: [-70.1185705180601, -38.6417575824599],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia de Salta",
        fuente: "IGN",
        iso_id: "AR-A",
        nombre: "Salta",
        id: "66",
        categoria: "Provincia",
        iso_nombre: "Salta",
      },
      type: "Feature",
      geometry: {
        coordinates: [-64.8144629600627, -24.2991344492002],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia del Chaco",
        fuente: "IGN",
        iso_id: "AR-H",
        nombre: "Chaco",
        id: "22",
        categoria: "Provincia",
        iso_nombre: "Chaco",
      },
      type: "Feature",
      geometry: {
        coordinates: [-60.7658307438603, -26.3864309061226],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia de Formosa",
        fuente: "IGN",
        iso_id: "AR-P",
        nombre: "Formosa",
        id: "34",
        categoria: "Provincia",
        iso_nombre: "Formosa",
      },
      type: "Feature",
      geometry: {
        coordinates: [-59.9324405800872, -24.894972594871],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia de Jujuy",
        fuente: "IGN",
        iso_id: "AR-Y",
        nombre: "Jujuy",
        id: "38",
        categoria: "Provincia",
        iso_nombre: "Jujuy",
      },
      type: "Feature",
      geometry: {
        coordinates: [-65.7642522180337, -23.3200784211351],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Ciudad Autónoma de Buenos Aires",
        fuente: "IGN",
        iso_id: "AR-C",
        nombre: "Ciudad Autónoma de Buenos Aires",
        id: "02",
        categoria: "Ciudad Autónoma",
        iso_nombre: "Ciudad Autónoma de Buenos Aires",
      },
      type: "Feature",
      geometry: {
        coordinates: [-58.4458563545429, -34.6144934119689],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo: "Provincia de Buenos Aires",
        fuente: "IGN",
        iso_id: "AR-B",
        nombre: "Buenos Aires",
        id: "06",
        categoria: "Provincia",
        iso_nombre: "Buenos Aires",
      },
      type: "Feature",
      geometry: {
        coordinates: [-60.5588319815719, -36.6769415180527],
        type: "Point",
      },
    },
    {
      properties: {
        nombre_completo:
          "Provincia de Tierra del Fuego, Antártida e Islas del Atlántico Sur",
        fuente: "IGN",
        iso_id: "AR-V",
        nombre: "Tierra del Fuego, Antártida e Islas del Atlántico Sur",
        id: "94",
        categoria: "Provincia",
        iso_nombre: "Tierra del Fuego",
      },
      type: "Feature",
      geometry: {
        coordinates: [-50.7427486049785, -82.52151781221],
        type: "Point",
      },
    },
  ];
  export default ArgentineProvinces;