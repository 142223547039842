import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Login from "./login";
import Signup from "./signUp";
import { activate } from "../../services/services";
import url from "../../static/constants";
import TopBar from "../topBarHome";

const SignInOutContainer = () => {
  const [value, setValue] = useState(0);
  const [error, setError] = useState("");
  const handleChange = (event, newValue) => {
    if (event === "error") {
      setError(newValue);
    } else {
      setValue(newValue);
    }
  };

  const urlParams = new URLSearchParams(window.location.search);

  const email = urlParams.get("email");

  const temporaryToken = urlParams.get("temporary_token");
  let location = "/" + window.location.href.split("/")[3];

  useEffect(() => {
    if (temporaryToken) {
      if (location.includes("/activate_account")) {
        activateAccount();
      }
    }
  }, [email, temporaryToken]);

  async function activateAccount(pass) {
    const dataForm = {
      email: email,
      temporaryToken: temporaryToken,
      password: pass,
    };
    try {
      const data = await activate(dataForm);

      if (data.data.message) {
        setError(data.data.message);
      }
      if (data.data.token) {
        localStorage.setItem("token", data.data.token);
        window.location.replace(`${url}cursos`);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <div>
      <TopBar></TopBar>
      <div id="login" className="image-login">
        <div className="title-login-container">
          <h1 className="title-login">
            Formación especializada para traductores
          </h1>
          {/* <p>
            Ingresá a la plataforma para ver los cursos a los que te inscribiste, acceder a los enlaces de las clases grabadas o en vivo, ver, solicitar o descargar tus certificados, ver tu progreso en las diversas especializaciones que estás estudiando, hacer consultas en la comunidad o editar tus datos personales.
          </p> */}
        </div>
        <div className="container-login">
          <Paper elevation={20}>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="var(--text-dark)"
              onChange={handleChange}
              aria-label="disabled tabs example"
            >
              <Tab
                id={value === 0 ? "button-step-active" : "button-step"}
                label="Iniciar Sesión"
              />

              <Tab
                id={value === 1 ? "button-step-active" : "button-step"}
                label="Crear cuenta"
              />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Login handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Signup handleChange={handleChange} />
            </TabPanel>
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default SignInOutContainer;
