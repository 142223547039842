
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import returnSpeakersString from "../../../utils/returnSpeakersString";
import "./courseCard.css";
const cardCourse = ({ children, course, isRecommended }) => {
const specialtyColor = course?.labels[0]?.specialty_color || "ff6d36";

    return (
        <div className= {isRecommended ? "course-card card card-course-recommended" : "course-card card"}>
            <div className="card-header" >
                <div className="card-image-container" style={{ backgroundColor: specialtyColor }}>
                <img
                    src={course?.labels[0]?.picture}
                    alt={course?.labels[0]?.name}
                    className="card-image"
                />
                </div>
                <div className="card-title-container">
                {course?.name.length >= 52 ? (
                    <Tooltip
                    disableFocusListener
                    disableTouchListener
                    title={course?.name}
                    >
                    <h6 className="card-title">
                        {course?.name.substring(0, 62)} ...
                    </h6>
                    </Tooltip>
                ) : (
                    <h6 className="secondary card-title">{course?.name}</h6>
                )}
                </div>
            </div>
            <div className="card-content">
                <div className="card-speaker-wrapper">
                    <p className="small text-dark card-speaker">
                    Dictado por {returnSpeakersString(course?.speakers)}
                    </p>
                </div>
            <div className="card-number-of-sessions">
                    <div>
                    <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.33309 4.33301C9.88537 4.33301 10.3331 4.78072 10.3331 5.33301V7.55651C14.1034 7.22074 17.8961 7.22075 21.6664 7.55652V5.33301C21.6664 4.78072 22.1141 4.33301 22.6664 4.33301C23.2187 4.33301 23.6664 4.78072 23.6664 5.33301V7.75492C25.6571 8.0364 27.2298 9.61097 27.4961 11.6195L27.6115 12.4901C28.0969 16.1518 28.0549 19.8643 27.487 23.5141C27.2057 25.3222 25.7368 26.7109 23.9158 26.8905L22.3251 27.0474C18.1184 27.4622 13.8811 27.4622 9.67432 27.0474L8.08365 26.8905C6.26266 26.7109 4.79378 25.3222 4.51244 23.5141C3.94449 19.8643 3.90257 16.1518 4.38795 12.4901L4.50336 11.6195C4.76959 9.61094 6.34232 8.03636 8.33309 7.75491V5.33301C8.33309 4.78072 8.7808 4.33301 9.33309 4.33301ZM9.92664 9.60341C13.9656 9.20511 18.0339 9.20511 22.0728 9.60341L23.2801 9.72247C24.4357 9.83644 25.3608 10.7311 25.5134 11.8823L25.6288 12.7529C25.6691 13.057 25.7056 13.3615 25.7383 13.6663H6.26117C6.29382 13.3615 6.3303 13.057 6.37061 12.7529L6.48601 11.8823C6.63861 10.7311 7.56371 9.83644 8.71937 9.72247L9.92664 9.60341ZM6.1022 15.6663C5.97129 18.1838 6.10026 20.7106 6.48866 23.2066C6.62978 24.1135 7.36655 24.8101 8.27993 24.9002L9.8706 25.057C13.9468 25.459 18.0526 25.459 22.1288 25.057L23.7195 24.9002C24.6329 24.8101 25.3697 24.1135 25.5108 23.2066C25.8992 20.7106 26.0281 18.1838 25.8972 15.6663H6.1022Z"
                        fill="var(--secondary)"
                        />
                    </svg>
                    </div>
                    <Grid
                    item
                    xs={8}
                    sm={12}
                    style={{
                        display: "grid",
                        alignContent: "center",
                        marginLeft: "10px",
                    }}
                    >
                    <p className="small text-dark" style={{ margin: 0 }}>
                        {
                        course?.number_sessions !== "0 clase de duración" ?
                        course?.schedules?.number_sessions ? course?.schedules?.number_sessions : course?.number_sessions
                        :null    
                    }
                    </p>
                    </Grid>
            </div>
            <div
                className="card-sessions-container"
            >
                {
                course?.past_course ? (
                    course?.number_sessions !== "0 clase de duración" ?
                    <p className="past-course-availability-notice">
                        {">> "}Ya disponible para verlo cuando quieras.{" <<"}
                    </p>
                :
                null
                )
                :    
                course?.schedules.sessions?.length <= 3 ?
                course?.schedules.sessions
                ? course?.schedules.sessions.map((session) => (
                    <Grid item xs={3} className="card-schedule">
                        <Grid>
                        <Grid item xs={12}>
                            <p
                            className="small text-dark"
                            style={{
                                marginBottom: "4px",
                                textAlign: "center",
                            }}
                            >
                            {session.day}
                            </p>
                        </Grid>
                        <Grid item xs={12}>
                            <p
                            className="label text-dark"
                            style={{
                                margin: 0,
                                textAlign: "center",
                            }}
                            >
                            {session.duration}
                            </p>
                        </Grid>
                        </Grid>
                    </Grid>
                    ))
                : null
                :
                <div>
                <Link
                className="text-dark"
                style={{ underline: "none", color: "var(--primary)" }}
                to={"/curso/" + course?.id}
            >
                Ver detalle de fechas {">>"}
            </Link>
            </div>
                }
            </div>
            </div>
            {children}
        </div>
    );
};
export default cardCourse;
