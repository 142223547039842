import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BottomBar from '../bottomBarHome'
import TopBar from '../topBarHome';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Paper } from "@mui/material";
import Switch from '@mui/material/Switch';
import { useState, useEffect } from "react";


export default function Honorarios() {
  const [precioMinimo, setprecioMinimo] = useState("2.30 ARS");
  const [precioSolicitado, setprecioSolicitado] = useState("3.05 ARS");
  const [precioPromedio, setprecioPromedio] = useState("2.66 ARS");

  const [checked, setChecked] = useState(true);

  const handleChange = (event) => {

    setChecked(event.target.checked);
    if (!event.target.checked) {
      Dolarprice()
    }
    else {
      arsPrice()
    }
  };

  function arsPrice() {
    setprecioMinimo("2.30 ARS")
    setprecioSolicitado("3.05 ARS")
    setprecioPromedio("2.66 ARS")
  }


  function Dolarprice() {
    setprecioMinimo("0,09 USD")
    setprecioSolicitado("0,12 USD")
    setprecioPromedio("0,11 USD")
  }


  return (<div id="home" >
    <TopBar></TopBar>
    <div className='reviews-image-honorarios'>
    {/* --------------------------------------title--------------------------------------------------------- */}
    <Box sx={{ textAlign: 'center', marginRight: '10%', marginLeft: '10%', marginBottom: '50px',paddingTop:'30px' }}>

      <h1 className="without-margin" style={{ textAlign: 'center' }} >Resultados de la encuesta de honorarios de traducción</h1>
      <p className="base without-margin" style={{ textAlign: 'center' }}>Los datos fueron recogidos en marzo de 2021.</p>
    </Box>

    {/* ----------------------------------------TEXT IMG-------------------------------------- */}
    <Grid container
      direction={{ xs: "column", sm: 'row' }}
      justifyContent="space-between"
      alignItems="center" sx={{ marginBottom: '8px', paddingInline: { xs: '30px', sm: '76px' } }}>

      <Grid item xs={2} sm={5} >
        <h5 className="primary without-margin">Necesidad</h5>
        <p className="base"  >La información que se presenta en esta sección surge del procesamiento de las respuestas de más de mil colegas a una encuesta sobre lo que cobran por sus servicios lingüísticos y sobre otros asuntos relacionados con nuestra industria.
          Como la amplia mayoría de quienes respondieron declararon trabajar en el par inglés-español, cuando existen diferencias entre diversos pares de idioma en los promedios obtenidos, estas se indican debajo de cada dato. Se toma el par inglés-español como punto de referencia.</p>
        <h5 className="primary without-margin">Objetivo</h5>
        <p className="base" > Esta información se publica con el fin de dar a conocer el estado actual de los honorarios de traducción. Esta información no es una sugerencia de honorarios, ya que cada colega es la única persona facultada para determinar el precio de sus servicios.</p>
      </Grid>
      <Grid item xs={8} sm={6} style={{ display: 'grid', alignContent: 'center' }}>
        <img src={require('../../static/images/homex3.png')} alt="home hero" width="100%" />

      </Grid>
    </Grid>

    {/* -----------------------------------3card------------------------------------------------------- */}
    <div className="padding-inline" style={{ marginBottom: '240px' }}>
      <h2 style={{ color: 'var(--primary)', textAlign: 'center', paddingBottom: '30px', marginLeft: '20%', marginRight: '20%', marginTop: '145px' }}>Honorarios por palabra
        en pesos argentinos</h2>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 30 }}>
        <p className='base-regular'>USD</p>
        <Switch
          color="warning"
          onChange={handleChange}
          checked={checked}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <p className='base-regular'>ARS</p>
      </div>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems=""
      >
        <Grid item xs={4} sm={4} md={4} sx={{ height: '100%', maxWidth: 360, marginTop: 5 }} >
          <Paper elevation={3} id="card-review">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M28 8.75C17.3685 8.75 8.75 17.3685 8.75 28C8.75 38.6315 17.3685 47.25 28 47.25C38.6315 47.25 47.25 38.6315 47.25 28C47.25 17.3685 38.6315 8.75 28 8.75ZM5.25 28C5.25 15.4355 15.4355 5.25 28 5.25C40.5645 5.25 50.75 15.4355 50.75 28C50.75 40.5645 40.5645 50.75 28 50.75C15.4355 50.75 5.25 40.5645 5.25 28Z" fill="#4400A5" />
                <path fillRule="evenodd" clipRule="evenodd" d="M21.8751 33.1424C23.7375 32.068 25.8498 31.5024 27.9999 31.5024C30.15 31.5024 32.2622 32.0681 34.1246 33.1425C35.9869 34.217 37.5339 35.7626 38.61 37.6239C39.0938 38.4607 38.8077 39.5311 37.9709 40.0149C37.1342 40.4986 36.0638 40.2125 35.58 39.3758C34.8112 38.046 33.706 36.9418 32.3755 36.1742C31.045 35.4065 29.5359 35.0024 27.9998 35.0024C26.4638 35.0024 24.9547 35.4065 23.6242 36.1741C22.2936 36.9417 21.1885 38.0458 20.4196 39.3756C19.9358 40.2124 18.8654 40.4985 18.0286 40.0147C17.1919 39.5309 16.9058 38.4605 17.3896 37.6238C18.4658 35.7624 20.0127 34.2169 21.8751 33.1424Z" fill="#4400A5" />
                <path d="M20.125 26.25C21.5747 26.25 22.75 25.0747 22.75 23.625C22.75 22.1753 21.5747 21 20.125 21C18.6753 21 17.5 22.1753 17.5 23.625C17.5 25.0747 18.6753 26.25 20.125 26.25Z" fill="#4400A5" />
                <path d="M35.875 26.25C37.3247 26.25 38.5 25.0747 38.5 23.625C38.5 22.1753 37.3247 21 35.875 21C34.4253 21 33.25 22.1753 33.25 23.625C33.25 25.0747 34.4253 26.25 35.875 26.25Z" fill="#4400A5" />
              </svg>
            </div>
            <div style={{ paddingRight: 20, paddingLeft: 20 }}>
              <h3 className="primary bold noneMargin text-center" style={{ marginTop: '8px' }}>Honorario mínimo</h3>
              <p className="medium noneMargin text-center" style={{ paddingTop: 10 }}>Cuál es el monto mínimo por debajo del cual la mayoría no acepta trabajar.</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <h2 className="primary"> {precioMinimo}</h2>
              {/* <h4 className="primary" style={{ marginTop: 56, marginLeft: 10 }}>ARS</h4> */}
            </div>
          </Paper>
        </Grid>

        <Grid item xs={4} sm={4} md={4} sx={{ height: '100%', maxWidth: 360, marginTop: 5 }} >
          <div className='border-gradient-honorario'>
            <Paper elevation={3} id="card-review">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M34.3065 7.98993C33.2788 6.21886 30.7212 6.21885 29.6935 7.98992L22.456 20.4627C21.9483 21.3376 21.0709 21.9347 20.0707 22.0857L6.50721 24.1343C4.22913 24.4784 3.43003 27.3547 5.20418 28.8246L15.3084 37.1958C16.2437 37.9707 16.6835 39.193 16.4563 40.3862L13.8132 54.2666C13.3954 56.4608 15.6988 58.1656 17.6752 57.125L30.447 50.4001C31.419 49.8883 32.5809 49.8883 33.553 50.4001L46.3247 57.125C48.3011 58.1656 50.6046 56.4608 50.1867 54.2666L47.5436 40.3862C47.3164 39.193 47.7562 37.9707 48.6915 37.1958L58.7958 28.8246C60.5699 27.3547 59.7708 24.4784 57.4927 24.1343L43.9292 22.0857C42.9291 21.9347 42.0516 21.3376 41.5439 20.4627L34.3065 7.98993Z" fill="url(#paint0_linear_1845_13894)" />
                  <path d="M34.3065 7.98993C33.2788 6.21886 30.7212 6.21885 29.6935 7.98992L22.456 20.4627C21.9483 21.3376 21.0709 21.9347 20.0707 22.0857L6.50721 24.1343C4.22913 24.4784 3.43003 27.3547 5.20418 28.8246L15.3084 37.1958C16.2437 37.9707 16.6835 39.193 16.4563 40.3862L13.8132 54.2666C13.3954 56.4608 15.6988 58.1656 17.6752 57.125L30.447 50.4001C31.419 49.8883 32.5809 49.8883 33.553 50.4001L46.3247 57.125C48.3011 58.1656 50.6046 56.4608 50.1867 54.2666L47.5436 40.3862C47.3164 39.193 47.7562 37.9707 48.6915 37.1958L58.7958 28.8246C60.5699 27.3547 59.7708 24.4784 57.4927 24.1343L43.9292 22.0857C42.9291 21.9347 42.0516 21.3376 41.5439 20.4627L34.3065 7.98993Z" stroke="url(#paint1_linear_1845_13894)" stroke-linejoin="round" />
                  <path d="M34.3065 7.98993C33.2788 6.21886 30.7212 6.21885 29.6935 7.98992L22.456 20.4627C21.9483 21.3376 21.0709 21.9347 20.0707 22.0857L6.50721 24.1343C4.22913 24.4784 3.43003 27.3547 5.20418 28.8246L15.3084 37.1958C16.2437 37.9707 16.6835 39.193 16.4563 40.3862L13.8132 54.2666C13.3954 56.4608 15.6988 58.1656 17.6752 57.125L30.447 50.4001C31.419 49.8883 32.5809 49.8883 33.553 50.4001L46.3247 57.125C48.3011 58.1656 50.6046 56.4608 50.1867 54.2666L47.5436 40.3862C47.3164 39.193 47.7562 37.9707 48.6915 37.1958L58.7958 28.8246C60.5699 27.3547 59.7708 24.4784 57.4927 24.1343L43.9292 22.0857C42.9291 21.9347 42.0516 21.3376 41.5439 20.4627L34.3065 7.98993Z" stroke="url(#paint2_linear_1845_13894)" stroke-opacity="0.2" stroke-linejoin="round" />
                  <defs>
                    <linearGradient id="paint0_linear_1845_13894" x1="32" y1="6.66162" x2="32" y2="57.4383" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#FACC48" />
                      <stop offset="0.515625" stop-color="#FFE9B5" />
                      <stop offset="1" stop-color="#ECB541" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_1845_13894" x1="54.7712" y1="56.4384" x2="19.0311" y2="-11.2832" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#B889EE" stop-opacity="0.51" />
                      <stop offset="1" stop-color="#E8E3F1" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_1845_13894" x1="32" y1="6.66162" x2="32" y2="57.4383" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#B9A1EA" stop-opacity="0.81" />
                      <stop offset="0.317708" stop-color="#B9A1EA" stop-opacity="0.08" />
                      <stop offset="1" stop-color="#B9A1EA" stop-opacity="0.08" />
                    </linearGradient>
                  </defs>
                </svg>


              </div>
              <div style={{ paddingRight: 20, paddingLeft: 20 }}>
                <h3 className="primary bold noneMargin text-center" style={{ marginTop: '8px' }}>Honorario solicitado</h3>
                <p className="medium noneMargin text-center" style={{ paddingTop: 10 }} >Cuánto piden tus colegas cuando les preguntan cuánto cobran por palabra.</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h2 className="primary"> {precioSolicitado}</h2>
                {/* <h4 className="primary" style={{ marginTop: 56, marginLeft: 10 }}>ARS</h4> */}
              </div>
            </Paper>
          </div>
        </Grid>

        <Grid item xs={4} sm={4} md={4} sx={{ height: '100%', maxWidth: 360, marginTop: 5 }} >
          <Paper elevation={3} id="card-review">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M28 8.75C17.3685 8.75 8.75 17.3685 8.75 28C8.75 38.6315 17.3685 47.25 28 47.25C38.6315 47.25 47.25 38.6315 47.25 28C47.25 17.3685 38.6315 8.75 28 8.75ZM5.25 28C5.25 15.4355 15.4355 5.25 28 5.25C40.5645 5.25 50.75 15.4355 50.75 28C50.75 40.5645 40.5645 50.75 28 50.75C15.4355 50.75 5.25 40.5645 5.25 28Z" fill="#4400A5" />
                <path fillRule="evenodd" clipRule="evenodd" d="M17.5 35C17.5 34.0335 18.2835 33.25 19.25 33.25H36.75C37.7165 33.25 38.5 34.0335 38.5 35C38.5 35.9665 37.7165 36.75 36.75 36.75H19.25C18.2835 36.75 17.5 35.9665 17.5 35Z" fill="#4400A5" />
                <path d="M20.125 26.25C21.5747 26.25 22.75 25.0747 22.75 23.625C22.75 22.1753 21.5747 21 20.125 21C18.6753 21 17.5 22.1753 17.5 23.625C17.5 25.0747 18.6753 26.25 20.125 26.25Z" fill="#4400A5" />
                <path d="M35.875 26.25C37.3247 26.25 38.5 25.0747 38.5 23.625C38.5 22.1753 37.3247 21 35.875 21C34.4253 21 33.25 22.1753 33.25 23.625C33.25 25.0747 34.4253 26.25 35.875 26.25Z" fill="#4400A5" />
              </svg>
            </div>
            <div style={{ paddingRight: 20, paddingLeft: 20 }}>
              <h3 className="primary bold noneMargin text-center" style={{ marginTop: '8px' }}>Honorario promedio</h3>
              <p className="medium noneMargin text-center" style={{ paddingTop: 10 }} >Cuánto cobran por palabra en promedio tus colegas.</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <h2 className="primary">{precioPromedio}</h2>
              {/* <h4 className="primary" style={{ marginTop: 56, marginLeft: 10 }}>ARS</h4> */}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
    </div>
    {/* ---------------------------------------recargos----------------------------------------------------------- */}
    <div className='desktop' style={{ paddingBottom: 160 }}>
      <div style={{ marginBottom: 150 }}>
        <Grid
          container
          spacing={12}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={2} sm={4} md={3} style={{ maxWidth: 360, marginLeft: 100 }}>
            <h5 className="primary">Recargos en honorarios</h5>
          </Grid>
          <Grid item xs={2} sm={4} md={4} style={{ maxWidth: 472 }}>
            <Box sx={{ minWidth: 275 }}>
              <h6 className="without-margin">Por fin de semana</h6>
              <Typography style={{ display: 'flex' }}>
                <h2 className="secondary-dark without-margin">25%</h2>
                <h4 style={{ marginTop: 20, marginLeft: 10 }} className="without-margin">de recargo</h4>
              </Typography>
              <p className="base without-margin">Solo el 25% de los colegas cobra recargo por trabajar en fines de semana.</p>
            </Box>
          </Grid>
          <Grid item xs={2} sm={4} md={4} style={{ maxWidth: 472 }}>
            <Box sx={{ minWidth: 275 }}>
              <h6 className="without-margin">Por urgencias</h6>
              <Typography style={{ display: 'flex' }}>
                <h2 className="secondary-dark without-margin">25%</h2>
                <h4 style={{ marginTop: 20, marginLeft: 10 }} className="without-margin">de recargo</h4>
              </Typography>
              <p className="base without-margin">Solo el 25% de los colegas cobra recargo por trabajar en fines de semana.</p>
            </Box>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          container
          spacing={12}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={2} sm={4} md={3} style={{ maxWidth: 360, marginLeft: 100 }}>
            <h5 className="primary">Honorarios de traductor</h5>
          </Grid>
          <Grid item xs={2} sm={4} md={4} style={{ maxWidth: 472 }}>
            <Box sx={{ minWidth: 275 }}>
              <h6 className="without-margin">Honorarios por hora</h6>
              <Typography style={{ display: 'flex' }}>
                <h2 className="secondary-dark without-margin">835</h2>
                <h4 style={{ marginTop: 20, marginLeft: 10 }} className="without-margin">ARS</h4>
              </Typography>
              <p className="base without-margin">Cuánto cobran en promedio tus colegas por edición, corrección o cualquier otra tarea que se cobre por hora.</p>
            </Box>
          </Grid>
          <Grid item xs={2} sm={4} md={4} style={{ maxWidth: 472 }}>
            <Box sx={{ minWidth: 275 }}>
              <h6 className="without-margin">Ingreso mensuales promedio</h6>
              <Typography style={{ display: 'flex' }}>
                <h2 className="secondary-dark without-margin">70.800</h2>
                <h4 style={{ marginTop: 20, marginLeft: 10 }} className="without-margin">ARS</h4>
              </Typography>
              <p className="base without-margin">Cuánto cobran en promedio tus colegas por mes trabajando a tiempo completo.</p>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>

    <div className='mobile' style={{ paddingBottom: 160, marginLeft: 20 }}>
      <div style={{ marginBottom: 150 }}>
        <Grid
          container
          spacing={12}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12} sm={4} md={3} style={{ maxWidth: 360 }}>
            <h5 className="primary">Recargos en honorarios</h5>
          </Grid>
          <Grid item xs={2} sm={4} md={4} style={{ maxWidth: 472 }}>
            <Box sx={{ minWidth: 275, marginBottom: 5 }}>
              <h6 className="without-margin">Por fin de semana</h6>
              <Typography style={{ display: 'flex' }}>
                <h2 className="secondary-dark without-margin">25%</h2>
                <h4 style={{ marginLeft: 10 }} className="without-margin">de recargo</h4>
              </Typography>
              <p className="base without-margin">Solo el 25% de los colegas cobra recargo por trabajar en fines de semana.</p>
            </Box>
          </Grid>
          <Grid item xs={2} sm={4} md={4} style={{ maxWidth: 472 }}>
            <Box sx={{ minWidth: 275 }}>
              <h6 className="without-margin">Por urgencias</h6>
              <Typography style={{ display: 'flex' }}>
                <h2 className="secondary-dark without-margin">25%</h2>
                <h4 style={{ marginLeft: 10 }} className="without-margin">de recargo</h4>
              </Typography>
              <p className="base without-margin">Solo el 25% de los colegas cobra recargo por trabajar en fines de semana.</p>
            </Box>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          container
          spacing={12}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12} sm={4} md={3} style={{ maxWidth: 360 }}>
            <h5 className="primary">Honorarios de traductor</h5>
          </Grid>
          <Grid item xs={2} sm={4} md={4} style={{ maxWidth: 472 }}>
            <Box sx={{ minWidth: 275, marginBottom: 5 }}>
              <h6 className="without-margin">Honorarios por hora</h6>
              <Typography style={{ display: 'flex' }}>
                <h2 className="secondary-dark without-margin">835</h2>
                <h4 style={{ marginLeft: 10 }} className="without-margin">ARS</h4>
              </Typography>
              <p className="base without-margin">Cuánto cobran en promedio tus colegas por edición, corrección o cualquier otra tarea que se cobre por hora.</p>
            </Box>
          </Grid>
          <Grid item xs={2} sm={4} md={4} style={{ maxWidth: 472 }}>
            <Box sx={{ minWidth: 275 }}>
              <h6 className="without-margin">Ingreso mensuales promedio</h6>
              <Typography style={{ display: 'flex' }}>
                <h2 className="secondary-dark without-margin">70.800</h2>
                <h4 style={{ marginLeft: 10 }} className="without-margin">ARS</h4>
              </Typography>
              <p className="base without-margin">Cuánto cobran en promedio tus colegas por mes trabajando a tiempo completo.</p>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
    {/* -----------------mas info orientativa cheks------------------------------------------------------------------------------- */}
    <div className='desktop' style={{ backgroundColor: 'var(--secondary-dark) ', paddingBottom: 5 }}>
      <div className="padding-inline">
        <h3 className="primary" style={{ paddingTop: 50, margin: '0px' }}>Más información orientativa</h3>

        <Grid container
          spacing={8}
          direction={{ xs: "column", sm: 'row' }}
          justifyContent="space-between"
          alignItems="flex-start" sx={{ margin: '0px', marginBottom: '60px', marginTop: '64px' }}>
          <Grid md={6} sx={{ pr: 4 }}>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 39 % de tus colegas está conforme con el volumen de trabajo que tiene, el 57 % quiere trabajar más y el 4 % quiere trabajar menos.</p>
            </Stack>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 43 % de tus colegas trabaja en traducción a tiempo completo.</p>
            </Stack>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 42 % de tus colegas que trabajan a tiempo completo tardó menos de un año en alcanzar esa cantidad de trabajo. El 36 % tardó de uno a tres años en lograrlo y el 22 % tardó más de tres años.</p>
            </Stack>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 48 % de tus colegas aceptan descuentos por coincidencias totales y parciales porque consideran que no tienen otra opción. El 28 % los aceptan porque consideran que es lo justo y el 24 % no aceptan descuentos por repeticiones.</p>
            </Stack>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 53 % de tus colegas tiene al menos un cliente fuera de su país. Más de la mitad de ellos tienen la mayoría de sus clientes fuera de su país.</p>
            </Stack>

          </Grid>
          <Grid md={6} sx={{ pr: 4 }}>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 47 % de tus colegas trabaja solo con clientes de su país, pero a la mayoría (94 %) les gustaría trabajar también con clientes del exterior. Uno de los principales impedimentos es que no sabrían cómo cobrarles.</p>
            </Stack>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 52 % de tus colegas busca clientes activamente. El 48 % restante espera a que sean los clientes quienes se comuniquen primero.</p>
            </Stack>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>Tus colegas tienen entre dos y tres áreas de especialidad. El 88 % aceptaría proyectos fuera de su área de especialidad.</p>
            </Stack>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 77 % de tus colegas usa herramientas de traducción asistida por computadora. La mayoría de quienes no las usan quieren aprender a usarlas.</p>
            </Stack>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 45 % de los clientes especifica con qué herramienta quiere que se haga la traducción.</p>
            </Stack>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 77 % de tus colegas aceptaría trabajar en proyectos de posedición de traducción automática.</p>
            </Stack>
          </Grid>
        </Grid>
      </div>
    </div>

    <div className='mobile' style={{ backgroundColor: 'var(--secondary-dark) ' }}>
      <div className="" style={{ paddingRight: 50, paddingLeft: 20 }}>
        <h3 className="primary" style={{ paddingTop: 50, margin: '0px' }}>Más información orientativa</h3>

        <Grid container
          spacing={8}
          direction={{ xs: "column", sm: 'row' }}
          justifyContent="space-between"
          alignItems="flex-start" sx={{ margin: '0px', marginBottom: '168px', marginTop: '64px' }}>
          <Grid md={6} sx={{ pr: 4 }}>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 39 % de tus colegas está conforme con el volumen de trabajo que tiene, el 57 % quiere trabajar más y el 4 % quiere trabajar menos.</p>
            </Stack>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 43 % de tus colegas trabaja en traducción a tiempo completo.</p>
            </Stack>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 42 % de tus colegas que trabajan a tiempo completo tardó menos de un año en alcanzar esa cantidad de trabajo. El 36 % tardó de uno a tres años en lograrlo y el 22 % tardó más de tres años.</p>
            </Stack>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 48 % de tus colegas aceptan descuentos por coincidencias totales y parciales porque consideran que no tienen otra opción. El 28 % los aceptan porque consideran que es lo justo y el 24 % no aceptan descuentos por repeticiones.</p>
            </Stack>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 53 % de tus colegas tiene al menos un cliente fuera de su país. Más de la mitad de ellos tienen la mayoría de sus clientes fuera de su país.</p>
            </Stack>

          </Grid>
          <Grid md={6} sx={{ pr: 4 }}>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 47 % de tus colegas trabaja solo con clientes de su país, pero a la mayoría (94 %) les gustaría trabajar también con clientes del exterior. Uno de los principales impedimentos es que no sabrían cómo cobrarles.</p>
            </Stack>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 52 % de tus colegas busca clientes activamente. El 48 % restante espera a que sean los clientes quienes se comuniquen primero.</p>
            </Stack>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>Tus colegas tienen entre dos y tres áreas de especialidad. El 88 % aceptaría proyectos fuera de su área de especialidad.</p>
            </Stack>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 77 % de tus colegas usa herramientas de traducción asistida por computadora. La mayoría de quienes no las usan quieren aprender a usarlas.</p>
            </Stack>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 45 % de los clientes especifica con qué herramienta quiere que se haga la traducción.</p>
            </Stack>

            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg style={{ minWidth: 20 }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.0506 1.28262C21.5387 1.77078 21.5387 2.56223 21.0506 3.05039L9.38388 14.7171C8.89573 15.2052 8.10427 15.2052 7.61612 14.7171L0.949449 8.05039C0.461293 7.56223 0.461293 6.77078 0.949449 6.28262C1.4376 5.79447 2.22906 5.79447 2.71722 6.28262L8.5 12.0654L19.2828 1.28262C19.7709 0.794465 20.5624 0.794465 21.0506 1.28262Z" fill="#76729A" />
              </svg>
              <p className='text-button'>El 77 % de tus colegas aceptaría trabajar en proyectos de posedición de traducción automática.</p>
            </Stack>
          </Grid>
        </Grid>
      </div>
    </div>

    {/* -------------------------ESPECIALIADES Y SERVICIOS--------------------------------------------------------------- */}
    <div className='desktop' style={{ marginTop: 30 }}>
      <h2 className="primary without-margin" style={{ textAlign: 'center', paddingBottom: '64px' }}>Especialidades y servicios</h2>
      <Grid container
        direction={{ xs: "column", sm: 'row' }}
        spacing={0}
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid md={5} className="specialties-services-box">
          <div style={{ display: 'flex', justifyContent: 'center' }} >
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M28 8.75C17.3685 8.75 8.75 17.3685 8.75 28C8.75 38.6315 17.3685 47.25 28 47.25C38.6315 47.25 47.25 38.6315 47.25 28C47.25 17.3685 38.6315 8.75 28 8.75ZM5.25 28C5.25 15.4355 15.4355 5.25 28 5.25C40.5645 5.25 50.75 15.4355 50.75 28C50.75 40.5645 40.5645 50.75 28 50.75C15.4355 50.75 5.25 40.5645 5.25 28Z" fill="#FF6A36" />
              <path fillRule="evenodd" clipRule="evenodd" d="M37.9712 31.7342C38.8079 32.2179 39.094 33.2884 38.6103 34.1251C37.5341 35.9865 35.9872 37.532 34.1248 38.6065C32.2625 39.681 30.1502 40.2467 28.0001 40.2467C25.85 40.2467 23.7377 39.6811 21.8754 38.6066C20.013 37.5322 18.466 35.9867 17.3898 34.1253C16.9061 33.2886 17.1922 32.2181 18.0289 31.7344C18.8656 31.2506 19.9361 31.5367 20.4198 32.3734C21.1887 33.7032 22.2939 34.8074 23.6244 35.575C24.9549 36.3426 26.464 36.7467 28.0001 36.7467C29.5362 36.7467 31.0452 36.3425 32.3757 35.5749C33.7062 34.8073 34.8114 33.7031 35.5802 32.3733C36.064 31.5366 37.1345 31.2504 37.9712 31.7342Z" fill="#FF6A36" />
              <path d="M20.125 26.25C21.5747 26.25 22.75 25.0747 22.75 23.625C22.75 22.1753 21.5747 21 20.125 21C18.6753 21 17.5 22.1753 17.5 23.625C17.5 25.0747 18.6753 26.25 20.125 26.25Z" fill="#FF6A36" />
              <path d="M35.875 26.25C37.3247 26.25 38.5 25.0747 38.5 23.625C38.5 22.1753 37.3247 21 35.875 21C34.4253 21 33.25 22.1753 33.25 23.625C33.25 25.0747 34.4253 26.25 35.875 26.25Z" fill="#FF6A36" />
            </svg>
          </div>
          <h5 className="primary" style={{ textAlign: 'center' }}>Especialidades
            más populares</h5>
          <Box sx={{ pr: 4, pl: 4 }}>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción médica: 24 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción jurídica: 17,5 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción técnica: 16,2 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción audiovisual: 7,4 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción científica: 5,6 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción educativa: 5,5 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción para organizaciones
                internacionales: 3,4 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
            <svg width="10px" height="6px" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción publicitaria: 3,1 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción económica: 2,9 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción literaria: 2,7 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción turística: 1,7 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Otras especialidades: 10 %</p>
            </Stack>

            <p className='medium secondary'>Los porcentajes representan la cantidad de colegas que consideran esa disciplina su principal área de especialidad.</p>
          </Box>
        </Grid>
        <Grid md={5} className="specialties-services-box">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M28 8.75C17.3685 8.75 8.75 17.3685 8.75 28C8.75 38.6315 17.3685 47.25 28 47.25C38.6315 47.25 47.25 38.6315 47.25 28C47.25 17.3685 38.6315 8.75 28 8.75ZM5.25 28C5.25 15.4355 15.4355 5.25 28 5.25C40.5645 5.25 50.75 15.4355 50.75 28C50.75 40.5645 40.5645 50.75 28 50.75C15.4355 50.75 5.25 40.5645 5.25 28Z" fill="#FF6A36" />
              <path fillRule="evenodd" clipRule="evenodd" d="M37.9712 31.7342C38.8079 32.2179 39.094 33.2884 38.6103 34.1251C37.5341 35.9865 35.9872 37.532 34.1248 38.6065C32.2625 39.681 30.1502 40.2467 28.0001 40.2467C25.85 40.2467 23.7377 39.6811 21.8754 38.6066C20.013 37.5322 18.466 35.9867 17.3898 34.1253C16.9061 33.2886 17.1922 32.2181 18.0289 31.7344C18.8656 31.2506 19.9361 31.5367 20.4198 32.3734C21.1887 33.7032 22.2939 34.8074 23.6244 35.575C24.9549 36.3426 26.464 36.7467 28.0001 36.7467C29.5362 36.7467 31.0452 36.3425 32.3757 35.5749C33.7062 34.8073 34.8114 33.7031 35.5802 32.3733C36.064 31.5366 37.1345 31.2504 37.9712 31.7342Z" fill="#FF6A36" />
              <path d="M20.125 26.25C21.5747 26.25 22.75 25.0747 22.75 23.625C22.75 22.1753 21.5747 21 20.125 21C18.6753 21 17.5 22.1753 17.5 23.625C17.5 25.0747 18.6753 26.25 20.125 26.25Z" fill="#FF6A36" />
              <path d="M35.875 26.25C37.3247 26.25 38.5 25.0747 38.5 23.625C38.5 22.1753 37.3247 21 35.875 21C34.4253 21 33.25 22.1753 33.25 23.625C33.25 25.0747 34.4253 26.25 35.875 26.25Z" fill="#FF6A36" />
            </svg>
          </div>
          <h5 className="primary" style={{ textAlign: 'center' }}>Servicios
            más populares</h5>
          <Box sx={{ pr: 4, pl: 4 }}>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción: 97,6 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Edición: 66,8 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Revisión (proofreading): 54,2 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Subtitulado: 32 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Transcripción: 31 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Interpretación: 23,3 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Localización de aplicaciones: 10,2 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Gestión de proyectos: 8 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Localización de videojuegos: 7,9 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Otros servicios: 6,5 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>
              <p> Clases: 29,6 %</p>
            </Stack>
            <p className='medium secondary'>Los porcentajes representan la cantidad de colegas que indicaron que brindan ese servicio.</p>
          </Box>
        </Grid>
      </Grid>
    </div>

    <div className='mobile' style={{ marginTop: 30 }}>
      <h2 className="primary without-margin" style={{ textAlign: 'center', paddingBottom: '64px' }}>Especialidades y servicios</h2>
      <Grid container
        direction={{ xs: "column", sm: 'row' }}
        spacing={0}
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid md={5} className="specialties-services-box specialties-services-box-mobile">
          <div style={{ display: 'flex', justifyContent: 'center' }} >
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M28 8.75C17.3685 8.75 8.75 17.3685 8.75 28C8.75 38.6315 17.3685 47.25 28 47.25C38.6315 47.25 47.25 38.6315 47.25 28C47.25 17.3685 38.6315 8.75 28 8.75ZM5.25 28C5.25 15.4355 15.4355 5.25 28 5.25C40.5645 5.25 50.75 15.4355 50.75 28C50.75 40.5645 40.5645 50.75 28 50.75C15.4355 50.75 5.25 40.5645 5.25 28Z" fill="#FF6A36" />
              <path fillRule="evenodd" clipRule="evenodd" d="M37.9712 31.7342C38.8079 32.2179 39.094 33.2884 38.6103 34.1251C37.5341 35.9865 35.9872 37.532 34.1248 38.6065C32.2625 39.681 30.1502 40.2467 28.0001 40.2467C25.85 40.2467 23.7377 39.6811 21.8754 38.6066C20.013 37.5322 18.466 35.9867 17.3898 34.1253C16.9061 33.2886 17.1922 32.2181 18.0289 31.7344C18.8656 31.2506 19.9361 31.5367 20.4198 32.3734C21.1887 33.7032 22.2939 34.8074 23.6244 35.575C24.9549 36.3426 26.464 36.7467 28.0001 36.7467C29.5362 36.7467 31.0452 36.3425 32.3757 35.5749C33.7062 34.8073 34.8114 33.7031 35.5802 32.3733C36.064 31.5366 37.1345 31.2504 37.9712 31.7342Z" fill="#FF6A36" />
              <path d="M20.125 26.25C21.5747 26.25 22.75 25.0747 22.75 23.625C22.75 22.1753 21.5747 21 20.125 21C18.6753 21 17.5 22.1753 17.5 23.625C17.5 25.0747 18.6753 26.25 20.125 26.25Z" fill="#FF6A36" />
              <path d="M35.875 26.25C37.3247 26.25 38.5 25.0747 38.5 23.625C38.5 22.1753 37.3247 21 35.875 21C34.4253 21 33.25 22.1753 33.25 23.625C33.25 25.0747 34.4253 26.25 35.875 26.25Z" fill="#FF6A36" />
            </svg>
          </div>
          <h5 className="primary" style={{ textAlign: 'center' }}>Especialidades
            más populares</h5>
            <Box sx={{ pr: 4, pl: 4 }}>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción médica: 24 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción jurídica: 17,5 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción técnica: 16,2 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción audiovisual: 7,4 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción científica: 5,6 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción educativa: 5,5 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción para organizaciones
                internacionales: 3,4 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción publicitaria: 3,1 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción económica: 2,9 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción literaria: 2,7 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción turística: 1,7 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#4400A5" />
              </svg>

              <p>Otras especialidades: 10 %</p>
            </Stack>

            <p className='medium secondary'>Los porcentajes representan la cantidad de colegas que consideran esa disciplina su principal área de especialidad.</p>
          </Box>
        </Grid>
        <Grid md={5} className="specialties-services-box specialties-services-box-mobile">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M28 8.75C17.3685 8.75 8.75 17.3685 8.75 28C8.75 38.6315 17.3685 47.25 28 47.25C38.6315 47.25 47.25 38.6315 47.25 28C47.25 17.3685 38.6315 8.75 28 8.75ZM5.25 28C5.25 15.4355 15.4355 5.25 28 5.25C40.5645 5.25 50.75 15.4355 50.75 28C50.75 40.5645 40.5645 50.75 28 50.75C15.4355 50.75 5.25 40.5645 5.25 28Z" fill="#FF6A36" />
              <path fillRule="evenodd" clipRule="evenodd" d="M37.9712 31.7342C38.8079 32.2179 39.094 33.2884 38.6103 34.1251C37.5341 35.9865 35.9872 37.532 34.1248 38.6065C32.2625 39.681 30.1502 40.2467 28.0001 40.2467C25.85 40.2467 23.7377 39.6811 21.8754 38.6066C20.013 37.5322 18.466 35.9867 17.3898 34.1253C16.9061 33.2886 17.1922 32.2181 18.0289 31.7344C18.8656 31.2506 19.9361 31.5367 20.4198 32.3734C21.1887 33.7032 22.2939 34.8074 23.6244 35.575C24.9549 36.3426 26.464 36.7467 28.0001 36.7467C29.5362 36.7467 31.0452 36.3425 32.3757 35.5749C33.7062 34.8073 34.8114 33.7031 35.5802 32.3733C36.064 31.5366 37.1345 31.2504 37.9712 31.7342Z" fill="#FF6A36" />
              <path d="M20.125 26.25C21.5747 26.25 22.75 25.0747 22.75 23.625C22.75 22.1753 21.5747 21 20.125 21C18.6753 21 17.5 22.1753 17.5 23.625C17.5 25.0747 18.6753 26.25 20.125 26.25Z" fill="#FF6A36" />
              <path d="M35.875 26.25C37.3247 26.25 38.5 25.0747 38.5 23.625C38.5 22.1753 37.3247 21 35.875 21C34.4253 21 33.25 22.1753 33.25 23.625C33.25 25.0747 34.4253 26.25 35.875 26.25Z" fill="#FF6A36" />
            </svg>
          </div>
          <h5 className="primary" style={{ textAlign: 'center' }}>Servicios
            más populares</h5>
          <Box sx={{ pr: 4, pl: 4 }}>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Traducción: 97,6 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Edición: 66,8 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Revisión (proofreading): 54,2 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Subtitulado: 32 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Transcripción: 31 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Interpretación: 23,3 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Localización de aplicaciones: 10,2 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Gestión de proyectos: 8 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Localización de videojuegos: 7,9 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>

              <p>Otros servicios: 6,5 %</p>
            </Stack>
            <Stack direction="row" spacing={2} style={{ marginBottom: 15 }}>
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.02038 0.313073C9.21565 0.508335 9.21565 0.824917 9.02038 1.02018L4.35372 5.68685C4.15845 5.88211 3.84187 5.88211 3.64661 5.68685L0.979943 3.02018C0.784681 2.82492 0.784681 2.50833 0.979943 2.31307C1.1752 2.11781 1.49179 2.11781 1.68705 2.31307L4.00016 4.62619L8.31328 0.313073C8.50854 0.11781 8.82512 0.11781 9.02038 0.313073Z" fill="#FF6A36" />
              </svg>
              <p> Clases: 29,6 %</p>
            </Stack>
            <p className='medium secondary'>Los porcentajes representan la cantidad de colegas que indicaron que brindan ese servicio.</p>
          </Box>
        </Grid>
      </Grid>
    </div>

    {/* -------------------¿Cobran menos quienes recién empiezan en la profesión?---------------------------------------- */}
    <div className="container-contact">
    <div style={{ paddingBottom: 190 }}>
      <Grid container
        direction={{ xs: "column", sm: 'row' }}
        justifyContent="center"
        alignItems="center" sx={{ marginBottom: '0px', paddingInline: { xs: '30px', sm: '76px' }, marginTop: '292px' }}>
        <Grid item xs={2} sm={6} >
          <img src={require('../../static/images/homex3.png')} alt="home section" width="100%" />
        </Grid>
        <Grid item xs={6} sm={6} md={6} style={{ display: 'grid', alignContent: 'center' }}>
          <h3 style={{ margin: 0, marginBottom: '30px', color: 'var(--primary)' }} >¿Cobran menos quienes recién empiezan en la profesión?</h3>
          <p className="base" >Nuestra encuesta reveló que no existen grandes diferencias entre lo que cobra un traductor que recién empieza y sus colegas con hasta diez años de experiencia. En nuestros análisis de tarifas en pesos argentinos encontramos que, incluso en los casos en los que un novel cobra menos que un colega con hasta diez años de experiencia, la diferencia de honorarios es solo del 14 %. Cuando analizamos honorarios en dólares no encontramos una diferencia entre lo que cobra un novel y un colega con hasta diez años de experiencia.</p>
          <p className="base" >Los colegas con más de diez años de experiencia, en general, cobran un 27 % más si analizamos los honorarios en pesos argentinos. Sin embargo, si analizamos los honorarios en dólares la diferencia de quienes tienen más de diez años de experiencia por sobre sus colegas menos experimentados es de solo el 14 %.</p>
        </Grid>
      </Grid>
    </div>
    </div >
    <BottomBar></BottomBar>
  </div >
  )

}

export const style = {
  listCoursesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    margin: '24px',
    marginLeft: '0px',
    borderRadius: '24px',
    maxWidth: 'none !important'
  }
}


