
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import React, { useContext, useState, useEffect } from 'react'


type CertificateRegularProps = {
  infoCertificate: certificate
}

type certificate = {
  certificate_id: number,
  course_duration: string,
  course_name: string,
  date_issued: string,
  recipient_name: string,
  speakers_name: [string],
  course_date:string,
}

export default function CertificateRegular({ infoCertificate }: CertificateRegularProps) {

  return (
    <div className='border-gradient'>
      <div id='certificate-regular'>
          <Grid container direction="column" justifyContent='center' className='text-center'>
            <div>
              <img src={require("../../static/certificate/isoLogo.svg").default} alt="Isologo" />
            </div>
            <div className='organeRectangle marginAuto' style={{ marginBottom: '23px', marginTop: '3px' }}>
              <p className='overline text-white without-margin'>CERTIFICADO DE ASISTENCIA</p>
            </div>
            <p className='text'>Se hace constar que</p>

            <h2 className='text-center bold without-margin text' >{infoCertificate.recipient_name}</h2>
            <p className='text'>ha asistido al curso «{infoCertificate.course_name}», de {infoCertificate.course_duration} reloj de duración, dictado por {infoCertificate.speakers_name ? infoCertificate.speakers_name.map((speaker: any, index: number) =>
              infoCertificate.speakers_name.length - 1 != index ?
                <span>
                  {speaker}  /
                </span> : <span>
                  {speaker}
                </span>
            ) : null
            }, por lo que se le extiende el presente certificado.</p>
            <Grid container
              direction='row'
              justifyContent="space-around"
              alignItems="center"
              mt={12}>
              <Grid item  xs={4} container direction="column" justifyContent='center'>
                <p className='overline text without-margin uppercase text-center'>{infoCertificate.course_date}</p>
                <div style={{ backgroundColor: 'var(--primary)', height: '1px', margin: '14px auto', width: '213px' }}></div>
                <p className='overline text without-margin uppercase text-center'>ID: {infoCertificate.certificate_id}</p>
              </Grid>
              <Grid item  xs={4} container direction="column" justifyContent='center' alignContent='center'>
                <img src={require("../../static/certificate/logoAlone.svg").default} alt="Logo" width='80px' />
              </Grid>
              <Grid item  xs={4} container direction="column" justifyContent='center' style={{ paddingBottom: '24px' }}>
                <img src={require("../../static/certificate/firma-juan.svg").default} alt="Firma Juan" />
              </Grid>
            </Grid>
          </Grid>
      </div>
    </div>
  );
}
