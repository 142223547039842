import SideBar from '../sideBar/sideBar'
import TopBar from "../topBarHome";
import BottomBar from "../bottomBarHome";
import ForumUnavailable from './forumUnavailable';

export default function ForumView() {
    const forum_visible = process.env.REACT_APP_FORUM_VISIBLE;
    const token = localStorage.getItem("token");
    console.log(typeof(forum_visible))
    console.log(forum_visible)
        return (
            <>
            {!token ? <TopBar /> : <SideBar></SideBar>}
                <div className={token ? "view" : "view-no-auth"} id="catalogue">
                    <h1 style={{ marginBottom: '8px' }}>Comunidad</h1>
                    <p style={{ marginTop: '0px', marginBottom: '40px' }}>Este es un espacio para debatir y para ayudarnos mutuamente.</p>
                    {
                    forum_visible ==="true" ?
                        "forum"
                    :
                        <ForumUnavailable/>
                    }
                    
                </div>
            {!token ? <BottomBar /> : null}
        </>
        )
}