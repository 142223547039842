
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import returnSpeakersString from '../../utils/returnSpeakersString';
import './CourseInfo.css'

type CourseInfoProps = {
  speakers: [any],
  schedules: any
  registration_fee_ars: string,
  registration_fee_usd: string,
  duration: number,
  labels: [any]
}

export default function CourseInfo({ speakers, schedules, registration_fee_ars, registration_fee_usd, duration, labels }: CourseInfoProps) {


  return (
    <div className="course-detail-box">
      <div className="header-image" style={labels?.length ? { backgroundColor:labels[0].specialty_color }:{}}>
        {labels?.length ?  (
        <>
        <img height="80px" src={labels[0].picture} alt={labels[0].name} />
        <p style={{textAlign: 'center', color:'white'}}>Especialidad: {labels[0].name}</p>
        </>)
        : null
        }
     

      </div>
      <div className="course-detail-body">
        <div style={{textAlign: 'center', marginTop: '10px'}}>
          <h6 className="text-center" style={{ marginBottom: '5px', marginTop: 0, fontWeight: '700' }}>
            Dictado por {returnSpeakersString(speakers)}
          </h6>
          <p className="text-center" style={{ marginBottom: '5px', marginTop: 0, fontWeight: '700', color:"var(--primary)" }}>
            {duration}
          </p>
          {!schedules ?
            <p className="small text-center" style={{marginBottom: '10px'}}>Disponible en formato de grabación para verlo cuando quieras.</p>
            : <div>
            <p className="label text-center without-margin" style={{marginBottom:20}}>{schedules?.starting_date}</p> 
            <Grid container
              justifyContent="center"
              alignItems="center"
              direction="row">
                {schedules?.sessions.map((session: any) =>
                <Grid item xs={4} className="schedules">
                    <Grid item xs={12} >
                      <p className="small bold" style={{ marginBottom: '0px', textAlign: 'center'}}>{session.day}</p>
                    </Grid>
                    <Grid item xs={12} >
                      <p className="label" style={{ marginTop: "0px", textAlign: 'center'}}>{session.duration}</p>
                    </Grid>
                </Grid>
              )}
            </Grid>
            </div>
          }
        </div>

      <CardActions>
        <div style={{ width: "100%"}}className="pricing-box">
          <div style={{ marginBottom: '0px', color: 'var(--text-dark)', textAlign: 'center' }} className="small">
            Arancel:
          </div>
          {registration_fee_ars ?
            <div className='prices'>
              <div className='fee-box'>
              <h5 className="primary without-margin">
                {registration_fee_ars}
              </h5>
              <p className="medium primary currency">ARS</p>
              </div>
              <div className='fee-box'>
              <h5 className="primary without-margin">
                {registration_fee_usd}
              </h5>
              <p className="medium primary currency">USD</p>
              </div>
            </div>
            : 
            <div className='fee-box'>
              <h5 style={{ marginBottom: '0px' }} className="primary without-margin">
                Gratuito
              </h5>
            </div>
          }
        </div>
      </CardActions>
      </div>
    </div>
  );
}