import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate, Link } from "react-router-dom";
import { getPeerboard } from "../services/services";
import ListItemButton from "./sideBar/listItemButton";
import MenuIcon from "./svgIcons/menu";
import './topBarHome.css';

const drawerWidth = 240;
const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

export default function TopBar(props) {
  const [tokenReady] = useState(localStorage.getItem("token"));
  let navigate = useNavigate();
  const { windows } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let location = "/" + window.location.href.split("/")[3];

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  const switchTheme = () => {
    const newTheme =
      localStorage.getItem("theme") === "light" ? "dark" : "light";

    document.body.setAttribute("data-theme", newTheme);
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function setTo(to) {
    navigate(to, { replace: true });
  }

  async function linkToPeerboard() {
    const peerboard = await getPeerboard();
    window.open(peerboard.data.peerboard_url, "_blank");
  }

  const drawer = (
    <div
      id="sideBar"
      style={{
        height: "100%",
        display: "grid",
        gridTemplateRows: "8% 22% 70%",
      }}
    >
      <div
        style={{ alignContent: "center", display: "grid", paddingLeft: "20px" }}
      >
        <Link to={"/"}>
          {theme === "light" ? (
            <img src={require("../static/icon/logo-juan.svg").default} alt="logo"/>
          ) : (
            <img src={require("../static/icon/logo-juan-white.svg").default}  alt="logo"/>
          )}
        </Link>
      </div>
      <List sx={{ paddingLeft: "20px" }}>
        {!tokenReady ? (
          <ListItem
            onClick={() => setTo("/cursos/")}
            style={{
              color: location === "/cursos/" ? "var(--primary)" : "",
            }}
            button
            key="Capacitaciones"
          >
            <ListItemText primary="Capacitaciones" />
          </ListItem>
        ) : (
          <ListItem
            onClick={() => setTo("/cursos/")}
            style={{
              color: location === "/cursos/" ? "var(--primary)" : "",
            }}
            button
            key="Capacitaciones"
          >
            <ListItemText primary="Capacitaciones" />
          </ListItem>
        )}
        {/* <ListItem
            onClick={() => setTo("/honorarios")}
            style={{
              color: location == "/honorarios" ? "var(--primary)" : "",
            }}
            button
            key="Honorarios"
          >
            <ListItemText primary="Honorarios" />
          </ListItem> */}
          {/* <ListItem
            onClick={() => setTo("/especializaciones")}
            style={{
              color: location == "/especializaciones" ? "var(--primary)" : "",
            }}
            button
            key="Especializaciones"
          >
            <ListItemText primary="Especializaciones" />
          </ListItem> */}
        <ListItem
            onClick={() => setTo("/comunidad")}
            style={{
              color: location == "/comunidad" ? "var(--primary)" : "",
            }}
            button
            key="Comunidad"
          >
            <ListItemText primary="Comunidad" />
          </ListItem>
        <ListItem
        onClick={() => setTo("/#contacto")}
          style={{ color: location === "/#contacto" ? "var(--primary)" : "" }}
          button
          key={"Contacto"}
        >
          <ListItemText primary={"Contacto"} />
        </ListItem>

        <ListItem>
          <FormControlLabel
            onClick={switchTheme}
            checked={theme === "light" ? false : true}
            control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked />}
            label=""
          />
        </ListItem>
      </List>
    </div>
  );

  const container =
    windows !== undefined ? () => window().document.body : undefined;

  return (
    <div id="bar">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          className="topBarHome"
          position="fixed"
          sx={{
            width: { sm: `100%` },
          }}
        >
          <Toolbar 
          id="topBar" 
          style={{ 
            display:"flex",
            justifyContent:"space-between",
            paddingInline: "0px",
            width: "100%",
            color: "var(--text-button)"
          }}>
            <div className="mobile-hamburguer-button">
            <ListItemButton
                onClick={handleDrawerToggle}
                className="hamburger-menu"
                >
                    <MenuIcon width={25} height={25} fill="var(--text-button)"/>
                </ListItemButton>
                </div>
              {/* Box added to hide button on large displays */}
              <Box sx={{display: { md: "none" }}}>
              <div className="button">
                <Link
                  class=""
                  style={{ display: "flex", underline: "none", color: "white" }}
                  to={tokenReady ? "/myCourses" : "/login"}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {tokenReady ? (
                      <p
                        to={"/myCourses"}
                        class="button-medium"
                        style={{ color: "var(--text-button)", padding: "0 20px",textAlign: "center"}}
                      >
                        Ir a la plataforma
                      </p>
                    ) : (
                      <p
                        to={"/login"}
                        class="button-medium"
                        style={{ color: "var(--text-button)", padding: "0px 10px"}}
                      >
                        {" "}
                        Iniciar sesión
                      </p>
                    )}
                  </div>
                </Link>
              </div>
            </Box>
            <Box
            id="topbar-content"
            sx={{ display: { xs: "none", md: "block" } }}
            className="padding-inline">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={3}
              >
                <div>
                  <Link to={"/"}>
                    {" "}
                    {theme === "light" ? (
                      <img src={require("../static/icon/logo-juan.svg").default} alt="logo" />
                      ) : (
                      <img src={require("../static/icon/logo-juan-white.svg").default} alt="logo" />
                    )}
                  </Link>
                </div>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={4}
                >
                  {!tokenReady ? (
                    <Link to={"/cursos/"} className="link-header">
                      Capacitaciones
                    </Link>
                  ) : (
                    <Link to={"/cursos/"} className="link-header">
                      Capacitaciones
                    </Link>
                  )}
                  {/* <Link to={"/honorarios"} className="link-header">
                    Honorarios
                  </Link>
                  <Link to={"/especializaciones"} className="link-header">
                    Especializaciones
                  </Link> */}
                  <Link to={"/comunidad"} className="link-header">
                    Comunidad
                  </Link>
                  <a href="/#contacto" className="link-header">
                    Contacto
                  </a>
                </Stack>

                <Stack direction="row" spacing={1} alignItems="center">
                  <ListItem>
                    <FormControlLabel
                      onClick={switchTheme}
                      checked={theme === "light" ? false : true}
                      control={
                        <MaterialUISwitch sx={{ m: 1 }} defaultChecked />
                      }
                      label=""
                    />
                  </ListItem>

                  <div
                    className="button"
                    style={{ width: "168px ", marginLeft: 0, margin: "auto" }}
                  >
                    <Link
                      class="subtitle2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        underline: "none",
                        color: "white",
                      }}
                      to={tokenReady ? "/myCourses" : "/login"}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {tokenReady ? (
                          <p
                            to={"/login"}
                            class="button-medium"
                            style={{ color: "var(--text-button)", padding: "0 20px",textAlign: "center"}}
                          >
                            Ir a la plataforma
                          </p>
                        ) : (
                          <p
                            to={"/login"}
                            class="button-medium"
                            style={{ color: "var(--text-button)" }}
                          >
                            {" "}
                            Iniciar sesión
                          </p>
                        )}
                      </div>
                    </Link>
                  </div>
                </Stack>
              </Stack>
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          class="sideBar"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            class="sideBar"
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </div>
  );
}

export const style = {
  listCoursesContainer: {
    display: "grid",
    gridTemplateColumns: "33% 33% 33%",
    justifyContent: "center",
  },
  card: {
    margin: "20px",
    marginLeft: "0px",
  },
};
