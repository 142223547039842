import  { useState } from "react";
import { Grid, Paper, TextField, Button } from '@mui/material'
import { register } from '../../services/services';
import CircularProgress from '@mui/material/CircularProgress';
import './loginSignup.css'

export const Signup = () => {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nickName, setNickName] = useState('');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [rePass, setRePass] = useState('');
  const title = { paddingTop: 30 }
  const [registry, setRegistry] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const isValidFields = (fieldName) => {
    if (registry) {
      if (fieldName === 'email') {
        return email ?
          /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email)
            ? ""
            : "Dirección inválida"
          : "Tenés que ingresar una dirección de correo electrónico";
      } else if (fieldName === 'pass') {
        return pass ? "" : "Tenés que ingresar una contraseña";
      } else if (fieldName === 'rePass') {
        return pass === rePass ? "" : "Las contraseñas no coinciden";
      } else {
        return fieldName ? "" : `${fieldName} requerido`;
      }
    }
  };
  
  async function goRegistration() {
    setLoading(true)
    const dataForm = {
      name: name,
      last_name: lastName,
      nick_name: nickName,
      password: pass,
      email: email
    }
    try {
      const data = await register(dataForm);
     
      if (data.data.message === "¡Gracias por registrarte! Te enviamos un correo electrónico para que actives tu cuenta.") {
        setMessage(data.data.message)
      }
      setLoading(false)
    }
    catch (error) {
      if(error?.response?.data.message !== 'El mail no es válido'){
          setMessage(error.response.data.message)
        }
      setLoading(false)
    }

  }

  return (

    <Grid id='login' className='loginView'>
      <div id="register">
      <Paper id="paper" class="paperStyleLogin resp">
        {!loading ?
          message ?
            <Grid>
              <h4 style={title}>{message}</h4>
            </Grid>
            :
            !registry ?
            <div className="registration-form-container">
              <Grid>
                <h3 style={title} class='primary noneMargin'>¡Hola, te damos la bienvenida!</h3>
                <p class='text-dark' style={{ paddingRight: 40 }}>Que bueno volver a verte. Ingresa tus datos para comenzar a aprender.</p>
              </Grid>
              <div id='inputLogin' class='caption' style={{ paddingTop: 40 }}>
                <Grid container spacing={0}
                direction="row"
                justifyContent="space-between">
                  <Grid xs={6} style={{ paddingRight:8}}>

                <TextField
                  className="login-signup-input-fields"
                  error={!!isValidFields('name')}
                  helperText={isValidFields('name')}
                  value={name} onChange={(event) => setName(event.target.value)} 
                  label='Nombre'
                  placeholder='Nombre' />
                  </Grid>
                  <Grid xs={6} style={{paddingLeft:8}}>
                <TextField
                  className="login-signup-input-fields"
                  error={!!isValidFields('lastName')}
                  helperText={isValidFields('lastName')}
                  value={lastName} onChange={(event) => setLastName(event.target.value)}  
                  label='Apellido' placeholder='Apellido' />
                </Grid>
                </Grid>
                <TextField
                  className="login-signup-input-fields"
                  error={!!isValidFields('nickName')}
                  helperText={isValidFields('nickName')}
                  value={nickName} onChange={(event) => setNickName(event.target.value)} style={{ marginTop: 26,width:'100%'}} 
                  label='Apodo' placeholder='Apodo' fullWidth />
                <p class='label'>Tu apodo o el nombre que prefieras que usemos para dirigirnos a vos</p>
                
                
              </div>
            <div className="registration-button-container">
              <button
                id="btnRecuperar"
                disabled={!name || !lastName || !nickName}
                onClick={() => setRegistry(true)}
                className={!name || !lastName || !nickName ? 'button-inscripcion button-inscripcion-disabled' : 'button-inscripcion'}
                style={{ width:'100%',}}>
                  Siguiente
              </button>
              </div>
            </div>

              :
              <div>
                
                <Button id="back" class="button-medium" onClick={() => setRegistry(false)} sx={{ mr: 1 }}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.35323 4.97994C9.54849 5.1752 9.54849 5.49179 9.35323 5.68705L7.04011 8.00016L9.35323 10.3133C9.54849 10.5085 9.54849 10.8251 9.35323 11.0204C9.15796 11.2156 8.84138 11.2156 8.64612 11.0204L5.97945 8.35372C5.78419 8.15845 5.78419 7.84187 5.97945 7.64661L8.64612 4.97994C8.84138 4.78468 9.15796 4.78468 9.35323 4.97994Z" fill="#F88D00" />
                                    </svg>
                                    Volver 
                                </Button>
                
                <div id='inputLogin' class='caption' style={{ marginTop: 40, marginBottom: 40 }}>
                  <TextField
                    className='login-signup-text-fields'
                    error={!!isValidFields('email')}
                    helperText={isValidFields('email')}
                    value={email} onChange={(event) => setEmail(event.target.value)} style={{ borderRadius: 10, marginTop: 26 }} label='Correo electronico' placeholder='Correo electronico' fullWidth />
                  <TextField
                    type='password'
                    error={!!isValidFields('pass')}
                    helperText={isValidFields('pass')}
                    value={pass} onChange={(event) => setPass(event.target.value)} style={{ borderRadius: 10, marginTop: 26 }} label='Contraseña' placeholder='Contraseña' fullWidth />
                  <TextField
                    type='password'
                    error={!!isValidFields('rePass')}
                    helperText={isValidFields('rePass')}
                    value={rePass} onChange={(event) => setRePass(event.target.value)} style={{ borderRadius: 10, marginTop: 26 }} label='Repetir contraseña' placeholder='Repetir contraseña' fullWidth />

                </div>
                <button
                  onClick={() => goRegistration()}
                  disabled={!!isValidFields('pass') || !!isValidFields('rePass') || !!isValidFields('email')}
                  id="btnRecuperar"
                  style={{width:'100%'}}
                  className={!!isValidFields('pass') || !!isValidFields('rePass') || !!isValidFields('email') ? 'button-inscripcion button-inscripcion-disabled' : 'button-inscripcion'}>
                    Registrarse
                  </button>
              </div>
          :
          <Grid container
            style={{ height: '100%',position:'relative',minHeight:'inherit' }}
            direction="row"
            justifyContent="center"
            alignItems="center">
            <CircularProgress style={{position:'absolute',right:'-20px'}}/>
          </Grid>
        }
      </Paper>
      </div>
    </Grid>

  );
};





export default Signup;
