const countries = [
    {
      name_en: "Afghanistan",
      label: "Afganistán",
      dial_code: "+93",
      code: "AF"
    },
    {
      name_en: "Albania",
      label: "Albania",
      dial_code: "+355",
      code: "AL"
    },
    {
      name_en: "Algeria",
      label: "Argelia",
      dial_code: "+213",
      code: "DZ"
    },
    {
      name_en: "AmericanSamoa",
      label: "Samoa Americana",
      dial_code: "+1684",
      code: "AS"
    },
    {
      name_en: "Andorra",
      label: "Andorra",
      dial_code: "+376",
      code: "AD"
    },
    {
      name_en: "Angola",
      label: "Angola",
      dial_code: "+244",
      code: "AO"
    },
    {
      name_en: "Anguilla",
      label: "Anguilla",
      dial_code: "+1264",
      code: "AI"
    },
    {
      name_en: "Antarctica",
      label: "Antártida",
      dial_code: "+672",
      code: "AQ"
    },
    {
      name_en: "Antigua and Barbuda",
      label: "Antigua y Barbuda",
      dial_code: "+1268",
      code: "AG"
    },
    {
      name_en: "Argentina",
      label: "Argentina",
      dial_code: "+54",
      code: "AR"
    },
    {
      name_en: "Armenia",
      label: "Armenia",
      dial_code: "+374",
      code: "AM"
    },
    {
      name_en: "Aruba",
      label: "Aruba",
      dial_code: "+297",
      code: "AW"
    },
    {
      name_en: "Australia",
      label: "Australia",
      dial_code: "+61",
      code: "AU"
    },
    {
      name_en: "Austria",
      label: "Austria",
      dial_code: "+43",
      code: "AT"
    },
    {
      name_en: "Azerbaijan",
      label: "Azerbaiyán",
      dial_code: "+994",
      code: "AZ"
    },
    {
      name_en: "Bahamas",
      label: "Bahamas",
      dial_code: "+1242",
      code: "BS"
    },
    {
      name_en: "Bahrain",
      label: "Baréin",
      dial_code: "+973",
      code: "BH"
    },
    {
      name_en: "Bangladesh",
      label: "Banglades",
      dial_code: "+880",
      code: "BD"
    },
    {
      name_en: "Barbados",
      label: "Barbados",
      dial_code: "+1246",
      code: "BB"
    },
    {
      name_en: "Belarus",
      label: "Bielorrusia",
      dial_code: "+375",
      code: "BY"
    },
    {
      name_en: "Belgium",
      label: "Bélgica",
      dial_code: "+32",
      code: "BE"
    },
    {
      name_en: "Belize",
      label: "Belice",
      dial_code: "+501",
      code: "BZ"
    },
    {
      name_en: "Benin",
      label: "Benin",
      dial_code: "+229",
      code: "BJ"
    },
    {
      name_en: "Bermuda",
      label: "Bermudas",
      dial_code: "+1441",
      code: "BM"
    },
    {
      name_en: "Bhutan",
      label: "Butan",
      dial_code: "+975",
      code: "BT"
    },
    {
      name_en: "Bolivia",
      label: "Bolivia",
      dial_code: "+591",
      code: "BO"
    },
    {
      name_en: "Bosnia and Herzegovina",
      label: "Bosnia-Herzegovina",
      dial_code: "+387",
      code: "BA"
    },
    {
      name_en: "Botswana",
      label: "Botsuana",
      dial_code: "+267",
      code: "BW"
    },
    {
      name_en: "Brazil",
      label: "Brasil",
      dial_code: "+55",
      code: "BR"
    },
    {
      name_en: "British Indian Ocean Territory",
      label: "Territorio Británico del Océano Índico",
      dial_code: "+246",
      code: "IO"
    },
    {
      name_en: "Brunei Darussalam",
      label: "Brunei",
      dial_code: "+673",
      code: "BN"
    },
    {
      name_en: "Bulgaria",
      label: "Bulgaria",
      dial_code: "+359",
      code: "BG"
    },
    {
      name_en: "Burkina Faso",
      label: "Burkina Faso",
      dial_code: "+226",
      code: "BF"
    },
    {
      name_en: "Burundi",
      label: "Burundi",
      dial_code: "+257",
      code: "BI"
    },
    {
      name_en: "Cambodia",
      label: "Camboya",
      dial_code: "+855",
      code: "KH"
    },
    {
      name_en: "Cameroon",
      label: "Camerún",
      dial_code: "+237",
      code: "CM"
    },
    {
      name_en: "Canada",
      label: "Canadá",
      dial_code: "+1",
      code: "CA"
    },
    {
      name_en: "Cape Verde",
      label: "Cabo Verde",
      dial_code: "+238",
      code: "CV"
    },
    {
      name_en: "Cayman Islands",
      label: "Islas Caimán",
      dial_code: "+ 345",
      code: "KY"
    },
    {
      name_en: "Central African Republic",
      label: "República Centroafricana",
      dial_code: "+236",
      code: "CF"
    },
    {
      name_en: "Chad",
      label: "Chad",
      dial_code: "+235",
      code: "TD"
    },
    {
      name_en: "Chile",
      label: "Chile",
      dial_code: "+56",
      code: "CL"
    },
    {
      name_en: "China",
      label: "China",
      dial_code: "+86",
      code: "CN"
    },
    {
      name_en: "Christmas Island",
      label: "Isla de Navidad",
      dial_code: "+61",
      code: "CX"
    },
    {
      name_en: "Cocos (Keeling) Islands",
      label: "Islas Cocos",
      dial_code: "+61",
      code: "CC"
    },
    {
      name_en: "Colombia",
      label: "Colombia",
      dial_code: "+57",
      code: "CO"
    },
    {
      name_en: "Comoros",
      label: "Comoras",
      dial_code: "+269",
      code: "KM"
    },
    {
      name_en: "Congo",
      label: "Congo",
      dial_code: "+242",
      code: "CG"
    },
    {
      name_en: "Congo, The Democratic Republic of the",
      label: "República Democrática del Congo",
      dial_code: "+243",
      code: "CD"
    },
    {
      name_en: "Cook Islands",
      label: "Islas Cook",
      dial_code: "+682",
      code: "CK"
    },
    {
      name_en: "Costa Rica",
      label: "Costa Rica",
      dial_code: "+506",
      code: "CR"
    },
    {
      name_en: "Cote d'Ivoire",
      label: "Costa de Marfil",
      dial_code: "+225",
      code: "CI"
    },
    {
      name_en: "Croatia",
      label: "Croacia",
      dial_code: "+385",
      code: "HR"
    },
    {
      name_en: "Cuba",
      label: "Cuba",
      dial_code: "+53",
      code: "CU"
    },
    {
      name_en: "Cyprus",
      label: "Chipre",
      dial_code: "+537",
      code: "CY"
    },
    {
      name_en: "Czechia",
      label: "Chequia",
      dial_code: "+420",
      code: "CZ"
    },
    {
      name_en: "Denmark",
      label: "Dinamarca",
      dial_code: "+45",
      code: "DK"
    },
    {
      name_en: "Djibouti",
      label: "Yibuti",
      dial_code: "+253",
      code: "DJ"
    },
    {
      name_en: "Dominica",
      label: "Dominica",
      dial_code: "+1767",
      code: "DM"
    },
    {
      name_en: "Dominican Republic",
      label: "República Dominicana",
      dial_code: "+1849",
      code: "DO"
    },
    {
      name_en: "Ecuador",
      label: "Ecuador",
      dial_code: "+593",
      code: "EC"
    },
    {
      name_en: "Egypt",
      label: "Egipto",
      dial_code: "+20",
      code: "EG"
    },
    {
      name_en: "El Salvador",
      label: "El Salvador",
      dial_code: "+503",
      code: "SV"
    },
    {
      name_en: "Equatorial Guinea",
      label: "Guinea Ecuatorial",
      dial_code: "+240",
      code: "GQ"
    },
    {
      name_en: "Eritrea",
      label: "Eritrea",
      dial_code: "+291",
      code: "ER"
    },
    {
      name_en: "Estonia",
      label: "Estonia",
      dial_code: "+372",
      code: "EE"
    },
    {
      name_en: "Ethiopia",
      label: "Etiopía",
      dial_code: "+251",
      code: "ET"
    },
    {
      name_en: "Falkland Islands (Malvinas)",
      label: "Islas Malvinas",
      dial_code: "+500",
      code: "FK"
    },
    {
      name_en: "Faroe Islands",
      label: "Islas Feroe",
      dial_code: "+298",
      code: "FO"
    },
    {
      name_en: "Fiji",
      label: "Fiyi",
      dial_code: "+679",
      code: "FJ"
    },
    {
      name_en: "Finland",
      label: "Finlandia",
      dial_code: "+358",
      code: "FI"
    },
    {
      name_en: "France",
      label: "Francia",
      dial_code: "+33",
      code: "FR"
    },
    {
      name_en: "French Guiana",
      label: "Guayana Francesa",
      dial_code: "+594",
      code: "GF"
    },
    {
      name_en: "French Polynesia",
      label: "Polinesia Francesa",
      dial_code: "+689",
      code: "PF"
    },
    {
      name_en: "Gabon",
      label: "Gabón",
      dial_code: "+241",
      code: "GA"
    },
    {
      name_en: "Gambia",
      label: "Gambia",
      dial_code: "+220",
      code: "GM"
    },
    {
      name_en: "Georgia",
      label: "Georgia",
      dial_code: "+995",
      code: "GE"
    },
    {
      name_en: "Germany",
      label: "Alemania",
      dial_code: "+49",
      code: "DE"
    },
    {
      name_en: "Ghana",
      label: "Ghana",
      dial_code: "+233",
      code: "GH"
    },
    {
      name_en: "Gibraltar",
      label: "Gibraltar",
      dial_code: "+350",
      code: "GI"
    },
    {
      name_en: "Greece",
      label: "Grecia",
      dial_code: "+30",
      code: "GR"
    },
    {
      name_en: "Greenland",
      label: "Groenlandia",
      dial_code: "+299",
      code: "GL"
    },
    {
      name_en: "Grenada",
      label: "Granada",
      dial_code: "+1473",
      code: "GD"
    },
    {
      name_en: "Guadeloupe",
      label: "Guadalupe",
      dial_code: "+590",
      code: "GP"
    },
    {
      name_en: "Guam",
      label: "Guam",
      dial_code: "+1671",
      code: "GU"
    },
    {
      name_en: "Guatemala",
      label: "Guatemala",
      dial_code: "+502",
      code: "GT"
    },
    {
      name_en: "Guernsey",
      label: "Guernsey",
      dial_code: "+44",
      code: "GG"
    },
    {
      name_en: "Guinea",
      label: "Guinea",
      dial_code: "+224",
      code: "GN"
    },
    {
      name_en: "Guinea-Bissau",
      label: "Guinea-Bisau",
      dial_code: "+245",
      code: "GW"
    },
    {
      name_en: "Guyana",
      label: "Guyana",
      dial_code: "+595",
      code: "GY"
    },
    {
      name_en: "Haiti",
      label: "Haití",
      dial_code: "+509",
      code: "HT"
    },
    {
      name_en: "Holy See (Vatican City State)",
      label: "Ciudad del Vaticano",
      dial_code: "+379",
      code: "VA"
    },
    {
      name_en: "Honduras",
      label: "Honduras",
      dial_code: "+504",
      code: "HN"
    },
    {
      name_en: "Hong Kong",
      label: "Hong Kong",
      dial_code: "+852",
      code: "HK"
    },
    {
      name_en: "Hungary",
      label: "Hungría",
      dial_code: "+36",
      code: "HU"
    },
    {
      name_en: "Iceland",
      label: "Islandia",
      dial_code: "+354",
      code: "IS"
    },
    {
      name_en: "India",
      label: "India",
      dial_code: "+91",
      code: "IN"
    },
    {
      name_en: "Indonesia",
      label: "Indonesia",
      dial_code: "+62",
      code: "ID"
    },
    {
      name_en: "Iran, Islamic Republic of",
      label: "Irán",
      dial_code: "+98",
      code: "IR"
    },
    {
      name_en: "Iraq",
      label: "Iraq",
      dial_code: "+964",
      code: "IQ"
    },
    {
      name_en: "Ireland",
      label: "Irlanda",
      dial_code: "+353",
      code: "IE"
    },
    {
      name_en: "Isle of Man",
      label: "Isla de Man",
      dial_code: "+44",
      code: "IM"
    },
    {
      name_en: "Israel",
      label: "Israel",
      dial_code: "+972",
      code: "IL"
    },
    {
      name_en: "Italy",
      label: "Italia",
      dial_code: "+39",
      code: "IT"
    },
    {
      name_en: "Jamaica",
      label: "Jamaica",
      dial_code: "+1876",
      code: "JM"
    },
    {
      name_en: "Japan",
      label: "Japón",
      dial_code: "+81",
      code: "JP"
    },
    {
      name_en: "Jersey",
      label: "Jersey",
      dial_code: "+44",
      code: "JE"
    },
    {
      name_en: "Jordan",
      label: "Jordania",
      dial_code: "+962",
      code: "JO"
    },
    {
      name_en: "Kazakhstan",
      label: "Kazajistán",
      dial_code: "+7",
      code: "KZ"
    },
    {
      name_en: "Kenya",
      label: "Kenia",
      dial_code: "+254",
      code: "KE"
    },
    {
      name_en: "Kiribati",
      label: "Kiribati",
      dial_code: "+686",
      code: "KI"
    },
    {
      name_en: "Korea, Democratic People's Republic of",
      label: "Corea del Norte",
      dial_code: "+850",
      code: "KP"
    },
    {
      name_en: "Korea, Republic of",
      label: "Corea del Sur",
      dial_code: "+82",
      code: "KR"
    },
    {
      name_en: "Kosovo",
      label: "Kosovo",
      dial_code: "+383",
      code: "XK"
    },
    {
      name_en: "Kuwait",
      label: "Kuwait",
      dial_code: "+965",
      code: "KW"
    },
    {
      name_en: "Kyrgyzstan",
      label: "Kirguistán",
      dial_code: "+996",
      code: "KG"
    },
    {
      name_en: "Lao People's Democratic Republic",
      label: "Laos",
      dial_code: "+856",
      code: "LA"
    },
    {
      name_en: "Latvia",
      label: "Letonia",
      dial_code: "+371",
      code: "LV"
    },
    {
      name_en: "Lebanon",
      label: "Líbano",
      dial_code: "+961",
      code: "LB"
    },
    {
      name_en: "Lesotho",
      label: "Lesoto",
      dial_code: "+266",
      code: "LS"
    },
    {
      name_en: "Liberia",
      label: "Liberia",
      dial_code: "+231",
      code: "LR"
    },
    {
      name_en: "Libyan Arab Jamahiriya",
      label: "Libia",
      dial_code: "+218",
      code: "LY"
    },
    {
      name_en: "Liechtenstein",
      label: "Liechtenstein",
      dial_code: "+423",
      code: "LI"
    },
    {
      name_en: "Lithuania",
      label: "Lituania",
      dial_code: "+370",
      code: "LT"
    },
    {
      name_en: "Luxembourg",
      label: "Luxemburgo",
      dial_code: "+352",
      code: "LU"
    },
    {
      name_en: "Macao",
      label: "Macao",
      dial_code: "+853",
      code: "MO"
    },
    {
      name_en: "Macedonia, The Former Yugoslav Republic of",
      label: "República de Macedonia",
      dial_code: "+389",
      code: "MK"
    },
    {
      name_en: "Madagascar",
      label: "Madagascar",
      dial_code: "+261",
      code: "MG"
    },
    {
      name_en: "Malawi",
      label: "Malaui",
      dial_code: "+265",
      code: "MW"
    },
    {
      name_en: "Malaysia",
      label: "Malasia",
      dial_code: "+60",
      code: "MY"
    },
    {
      name_en: "Maldives",
      label: "Maldivas",
      dial_code: "+960",
      code: "MV"
    },
    {
      name_en: "Mali",
      label: "Malí",
      dial_code: "+223",
      code: "ML"
    },
    {
      name_en: "Malta",
      label: "Malta",
      dial_code: "+356",
      code: "MT"
    },
    {
      name_en: "Marshall Islands",
      label: "Islas Marshall",
      dial_code: "+692",
      code: "MH"
    },
    {
      name_en: "Martinique",
      label: "Martinica",
      dial_code: "+596",
      code: "MQ"
    },
    {
      name_en: "Mauritania",
      label: "Mauritania",
      dial_code: "+222",
      code: "MR"
    },
    {
      name_en: "Mauritius",
      label: "Mauricio",
      dial_code: "+230",
      code: "MU"
    },
    {
      name_en: "Mayotte",
      label: "Mayotte",
      dial_code: "+262",
      code: "YT"
    },
    {
      name_en: "Mexico",
      label: "México",
      dial_code: "+52",
      code: "MX"
    },
    {
      name_en: "Micronesia, Federated States of",
      label: "Estados Federados de Micronesia",
      dial_code: "+691",
      code: "FM"
    },
    {
      name_en: "Moldova, Republic of",
      label: "Moldavia",
      dial_code: "+373",
      code: "MD"
    },
    {
      name_en: "Monaco",
      label: "Monaco",
      dial_code: "+377",
      code: "MC"
    },
    {
      name_en: "Mongolia",
      label: "Mongolia",
      dial_code: "+976",
      code: "MN"
    },
    {
      name_en: "Montenegro",
      label: "Montenegro",
      dial_code: "+382",
      code: "ME"
    },
    {
      name_en: "Montserrat",
      label: "Montserrat",
      dial_code: "+1664",
      code: "MS"
    },
    {
      name_en: "Morocco",
      label: "Marruecos",
      dial_code: "+212",
      code: "MA"
    },
    {
      name_en: "Mozambique",
      label: "Mozambique",
      dial_code: "+258",
      code: "MZ"
    },
    {
      name_en: "Myanmar",
      label: "Birmania",
      dial_code: "+95",
      code: "MM"
    },
    {
      name_en: "Namibia",
      label: "Namibia",
      dial_code: "+264",
      code: "NA"
    },
    {
      name_en: "Nauru",
      label: "Nauru",
      dial_code: "+674",
      code: "NR"
    },
    {
      name_en: "Nepal",
      label: "Nepal",
      dial_code: "+977",
      code: "NP"
    },
    {
      name_en: "Netherlands",
      label: "Holanda",
      dial_code: "+31",
      code: "NL"
    },
    {
      name_en: "Netherlands Antilles",
      label: "Antillas Holandesas",
      dial_code: "+599",
      code: "AN"
    },
    {
      name_en: "New Caledonia",
      label: "Nueva Caledonia",
      dial_code: "+687",
      code: "NC"
    },
    {
      name_en: "New Zealand",
      label: "Nueva Zelanda",
      dial_code: "+64",
      code: "NZ"
    },
    {
      name_en: "Nicaragua",
      label: "Nicaragua",
      dial_code: "+505",
      code: "NI"
    },
    {
      name_en: "Niger",
      label: "Niger",
      dial_code: "+227",
      code: "NE"
    },
    {
      name_en: "Nigeria",
      label: "Nigeria",
      dial_code: "+234",
      code: "NG"
    },
    {
      name_en: "Niue",
      label: "Niue",
      dial_code: "+683",
      code: "NU"
    },
    {
      name_en: "NorfolkIsland",
      label: "IslaNorfolk",
      dial_code: "+672",
      code: "NF"
    },
    {
      name_en: "NorthernMarianaIslands",
      label: "IslasMarianasdelNorte",
      dial_code: "+1670",
      code: "MP"
    },
    {
      name_en: "Norway",
      label: "Noruega",
      dial_code: "+47",
      code: "NO"
    },
    {
      name_en: "Oman",
      label: "Omán",
      dial_code: "+968",
      code: "OM"
    },
    {
      name_en: "Pakistan",
      label: "Pakistán",
      dial_code: "+92",
      code: "PK"
    },
    {
      name_en: "Palau",
      label: "Palaos",
      dial_code: "+680",
      code: "PW"
    },
    {
      name_en: "Panama",
      label: "Panamá",
      dial_code: "+507",
      code: "PA"
    },
    {
      name_en: "Papua New Guinea",
      label: "Papúa Nueva Guinea",
      dial_code: "+675",
      code: "PG"
    },
    {
      name_en: "Paraguay",
      label: "Paraguay",
      dial_code: "+595",
      code: "PY"
    },
    {
      name_en: "Peru",
      label: "Perú",
      dial_code: "+51",
      code: "PE"
    },
    {
      name_en: "Philippines",
      label: "Filipinas",
      dial_code: "+63",
      code: "PH"
    },
    {
      name_en: "Pitcairn",
      label: "Islas Pitcairn",
      dial_code: "+872",
      code: "PN"
    },
    {
      name_en: "Poland",
      label: "Polonia",
      dial_code: "+48",
      code: "PL"
    },
    {
      name_en: "Portugal",
      label: "Portugal",
      dial_code: "+351",
      code: "PT"
    },
    {
      name_en: "Puerto Rico",
      label: "Puerto Rico",
      dial_code: "+1939",
      code: "PR"
    },
    {
      name_en: "Qatar",
      label: "Qatar",
      dial_code: "+974",
      code: "QA"
    },
    {
      name_en: "Romania",
      label: "Rumania",
      dial_code: "+40",
      code: "RO"
    },
    {
      name_en: "Russia",
      label: "Rusia",
      dial_code: "+7",
      code: "RU"
    },
    {
      name_en: "Rwanda",
      label: "Ruanda",
      dial_code: "+250",
      code: "RW"
    },
    {
      name_en: "Réunion",
      label: "Reunion",
      dial_code: "+262",
      code: "RE"
    },
    {
      name_en: "Saint Barthélemy",
      label: "San Bartolome",
      dial_code: "+590",
      code: "BL"
    },
    {
      name_en: "Saint Helena, Ascension and Tristan Da Cunha",
      label: "Santa Elena, Ascensión y Tristán de Acuña",
      dial_code: "+290",
      code: "SH"
    },
    {
      name_en: "Saint Kitts and Nevis",
      label: "San Cristóbal y Nieves",
      dial_code: "+1869",
      code: "KN"
    },
    {
      name_en: "Saint Lucia",
      label: "Santa Lucía",
      dial_code: "+1758",
      code: "LC"
    },
    {
      name_en: "Saint Martin",
      label: "Isla de San Martín",
      dial_code: "+590",
      code: "MF"
    },
    {
      name_en: "Saint Pierre and Miquelon",
      label: "San Pedro y Miquelon",
      dial_code: "+508",
      code: "PM"
    },
    {
      name_en: "Saint Vincent and the Grenadines",
      label: "San Vicente y las Granadinas",
      dial_code: "+1784",
      code: "VC"
    },
    {
      name_en: "Samoa",
      label: "Samoa",
      dial_code: "+685",
      code: "WS"
    },
    {
      name_en: "San Marino",
      label: "San Marino",
      dial_code: "+378",
      code: "SM"
    },
    {
      name_en: "Sao Tome and Principe",
      label: " Santo Tomé y Príncipe",
      dial_code: "+239",
      code: "ST"
    },
    {
      name_en: "Saudi Arabia",
      label: "Arabia Saudita",
      dial_code: "+966",
      code: "SA"
    },
    {
      name_en: "Senegal",
      label: "Senegal",
      dial_code: "+221",
      code: "SN"
    },
    {
      name_en: "Serbia",
      label: "Serbia",
      dial_code: "+381",
      code: "RS"
    },
    {
      name_en: "Seychelles",
      label: "Seychelles",
      dial_code: "+248",
      code: "SC"
    },
    {
      name_en: "Sierra Leone",
      label: "Sierra Leona",
      dial_code: "+232",
      code: "SL"
    },
    {
      name_en: "Singapore",
      label: "Singapur",
      dial_code: "+65",
      code: "SG"
    },
    {
      name_en: "Slovakia",
      label: "Eslovaquia",
      dial_code: "+421",
      code: "SK"
    },
    {
      name_en: "Slovenia",
      label: "Eslovenia",
      dial_code: "+386",
      code: "SI"
    },
    {
      name_en: "Solomon Islands",
      label: "Islas Salomón",
      dial_code: "+677",
      code: "SB"
    },
    {
      name_en: "Somalia",
      label: "Somalia",
      dial_code: "+252",
      code: "SO"
    },
    {
      name_en: "South Africa",
      label: "Sudáfrica",
      dial_code: "+27",
      code: "ZA"
    },
    {
      name_en: "South Sudan",
      label: "Sudán del Sur",
      dial_code: "+211",
      code: "SS"
    },
    {
      name_en: "Spain",
      label: "España",
      dial_code: "+34",
      code: "ES"
    },
    {
      name_en: "Sri Lanka",
      label: "Sri Lanka",
      dial_code: "+94",
      code: "LK"
    },
    {
      name_en: "State of Palestine",
      label: "Estado de Palestina",
      dial_code: "+970",
      code: "PS"
    },
    {
      name_en: "Sudan",
      label: "Sudán",
      dial_code: "+249",
      code: "SD"
    },
    {
      name_en: "Suriname",
      label: "Surinam",
      dial_code: "+597",
      code: "SR"
    },
    {
      name_en: "Svalbard and Jan Mayen",
      label: "Svalbard y Jan Mayen",
      dial_code: "+47",
      code: "SJ"
    },
    {
      name_en: "Swaziland",
      label: "Suazilandia",
      dial_code: "+268",
      code: "SZ"
    },
    {
      name_en: "Sweden",
      label: "Suecia",
      dial_code: "+46",
      code: "SE"
    },
    {
      name_en: "Switzerland",
      label: "Suiza",
      dial_code: "+41",
      code: "CH"
    },
    {
      name_en: "Syrian Arab Republic",
      label: "Siria",
      dial_code: "+963",
      code: "SY"
    },
    {
      name_en: "Taiwan, Province of China",
      label: "Taiwán",
      dial_code: "+886",
      code: "TW"
    },
    {
      name_en: "Tayikistan",
      label: "Tayikistán",
      dial_code: "+992",
      code: "TJ"
    },
    {
      name_en: "Tanzania, United Republic of",
      label: "Tanzania",
      dial_code: "+255",
      code: "TZ"
    },
    {
      name_en: "Thailand",
      label: "Tailandia",
      dial_code: "+66",
      code: "TH"
    },
    {
      name_en: "Timor-Leste",
      label: "Timor Oriental",
      dial_code: "+670",
      code: "TL"
    },
    {
      name_en: "Togo",
      label: "Togo",
      dial_code: "+228",
      code: "TG"
    },
    {
      name_en: "Tokelau",
      label: "Tokelau",
      dial_code: "+690",
      code: "TK"
    },
    {
      name_en: "Tonga",
      label: "Tonga",
      dial_code: "+676",
      code: "TO"
    },
    {
      name_en: "Trinidad and Tobago",
      label: "Trinidad y Tobago",
      dial_code: "+1868",
      code: "TT"
    },
    {
      name_en: "Tunisia",
      label: "Túnez",
      dial_code: "+216",
      code: "TN"
    },
    {
      name_en: "Turkey",
      label: "Turquía",
      dial_code: "+90",
      code: "TR"
    },
    {
      name_en: "Turkmenistan",
      label: "Turkmenistán",
      dial_code: "+993",
      code: "TM"
    },
    {
      name_en: "Turks and Caicos Islands",
      label: "Islas Turcas y Caicos",
      dial_code: "+1649",
      code: "TC"
    },
    {
      name_en: "Tuvalu",
      label: "Tuvalu",
      dial_code: "+688",
      code: "TV"
    },
    {
      name_en: "Uganda",
      label: "Uganda",
      dial_code: "+256",
      code: "UG"
    },
    {
      name_en: "Ukraine",
      label: "Ucrania",
      dial_code: "+380",
      code: "UA"
    },
    {
      name_en: "United Arab Emirates",
      label: "Emiratos Árabes Unidos",
      dial_code: "+971",
      code: "AE"
    },
    {
      name_en: "United Kingdom",
      label: "Reino Unido",
      dial_code: "+44",
      code: "GB"
    },
    {
      name_en: "United States",
      label: "Estados Unidos",
      dial_code: "+1",
      code: "US"
    },
    {
      name_en: "Uruguay",
      label: "Uruguay",
      dial_code: "+598",
      code: "UY"
    },
    {
      name_en: "Uzbekistan",
      label: "Uzbekistán",
      dial_code: "+998",
      code: "UZ"
    },
    {
      name_en: "Vanuatu",
      label: "Vanuatu",
      dial_code: "+678",
      code: "VU"
    },
    {
      name_en: "Venezuela, Bolivarian Republic of",
      label: "Venezuela",
      dial_code: "+58",
      code: "VE"
    },
    {
      name_en: "Vietnam",
      label: "Vietnam",
      dial_code: "+84",
      code: "VN"
    },
    {
      name_en: "Virgin Islands, British",
      label: "Islas Vírgenes Británicas",
      dial_code: "+1284",
      code: "VG"
    },
    {
      name_en: "Virgin Islands, U.S.",
      label: "Islas Vírgenes de los Estados Unidos",
      dial_code: "+1340",
      code: "VI"
    },
    {
      name_en: "Wallis and Futuna",
      label: "Wallis y Futuna",
      dial_code: "+681",
      code: "WF"
    },
    {
      name_en: "Yemen",
      label: "Yemen",
      dial_code: "+967",
      code: "YE"
    },
    {
      name_en: "Zambia",
      label: "Zambia",
      dial_code: "+260",
      code: "ZM"
    },
    {
      name_en: "Zimbabwe",
      label: "Zimbabue",
      dial_code: "+263",
      code: "ZW"
    },
    {
      name_en: "Åland Islands",
      label: "Åland",
      dial_code: "+358",
      code: "AX"
    }
  ];

  export default countries;