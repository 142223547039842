const MenuIcon = (props) => (
    <svg
    width={props.width}
    height={props.height}
    viewBox="0 -5 32 32"
    xmlns="http://www.w3.org/2000/svg"
    >
        <path
        fill={props.fill}
        d="M30 18a2 2 0 0 1 0 4H2a2 2 0 0 1 0-4h28Zm0-9a2 2 0 0 1 0 4H2a2 2 0 0 1 0-4h28Zm0-9a2 2 0 0 1 0 4H2a2 2 0 0 1 0-4h28Z"
        />
    </svg>
);
export default MenuIcon;