import { useState, useEffect} from 'react';
import ListCourseView from "./components/cursos/listCoursesView.jsx";
import MyListCourses from "./components/myCourses/listMyCourses.jsx";
import SignInOutContainer from './components/login/loginSignupContainer';
import StepForm from './components/form-inscripcion-curso/StepForm'
import DetailMyCourse from './components/myCourse/detailMyCourse'
import BasicTabs from './components/Info-Personal/info-personal';
import Home from './components/home/home'
import Honorarios from './components/honorarios/honorarios';
import Especializaciones from './components/especializaciones/especializaciones';
import Certificate from './components/certificate/certificate_container';
import Forum from './components/forum/forumView.jsx'
import ScrollToTop from './components/ScrollToTop';
import ErrorGlobal from './features/error/error';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate
} from "react-router-dom";





function App() {
  const [tokenReady,setTokenReady] = useState(localStorage.getItem('token'))
  let token = localStorage.getItem('token');


  
  useEffect(() =>{
  setTokenReady(token)
  },[token])

  function RequireAuth({ children }) {
    setTokenReady(localStorage.getItem('token'));
    let location = useLocation();
    if (!tokenReady) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    return children;
  }
  
  
  function RequireNoAuth({ children }) {
    setTokenReady(localStorage.getItem('token'));
    let location = useLocation();
  
    if(location.pathname === '/recover_password' || location.pathname === '/activate_account'){
      localStorage.removeItem("token");
    }
    else{
      if (tokenReady) {
        return <Navigate to="/cursos" state={{ from: location }} replace />;
      }
    }
    return children;
  }


  return (
    <div className='app-container'>
    <Router >
      <ErrorGlobal />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home /> }/> 
          <Route path="/honorarios" element={<Honorarios /> }/>
          <Route path="/especializaciones" element={<Especializaciones /> }/>
          <Route path="/certificado/:idCertificate" element={<Certificate /> }/> 
          <Route path="/login" element={<RequireNoAuth><SignInOutContainer /> </RequireNoAuth>} />
          <Route path="/activate_account" element={<RequireNoAuth><SignInOutContainer /> </RequireNoAuth>} />
          <Route path="/recover_password" element={<RequireNoAuth><SignInOutContainer /> </RequireNoAuth>} />
          <Route path="/cursos" element={<ListCourseView />} /> 
          <Route path="/comunidad" element={<Forum />} /> 
          <Route path="/myCourses" element={<RequireAuth><MyListCourses /> </RequireAuth>} />
          <Route path="/curso/:courseId" element={<StepForm />} /> 
          <Route path="/myCourse/:courseId" element={<RequireAuth><DetailMyCourse /> </RequireAuth>} /> 
          <Route path="/infoPersonal" element={<RequireAuth><BasicTabs /> </RequireAuth>} />
          <Route path="/infoPersonal/:specialization" element={<RequireAuth><BasicTabs /> </RequireAuth>} />
          {token ? [
            <Route path="*" element={<RequireNoAuth></RequireNoAuth>} />,
            <Route path="/" element={<RequireNoAuth></RequireNoAuth>} />
          ]
            : [
              <Route path="*" element={<RequireNoAuth><Navigate to="/cursos" replace /></RequireNoAuth>} />,
              <Route path="/" element={<RequireAuth></RequireAuth>} />
            ]
          }
        </Routes>
      </ScrollToTop>
    </Router>
  </div>
  );
}

export default App;


