import React, { useContext , useEffect } from "react";
import Button from '@mui/material/Button'
import { AppContext } from './form-inscripcion-curso/Context'

export default function ReactPayPal(props) {
  const [paid, setPaid] = React.useState(false);
  const [error, setError] = React.useState(null);
  const paypalRef = React.useRef();
  const { handleNext } = useContext(
    AppContext
  )
  useEffect(() => {
    //@ts-nocheck
    try{
      window.paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "Inscripción a curso",
                amount: {
                  currency_code: "USD",
                  value: props.feeUsd,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          setPaid(true);
          props.handlePaid(order)
         
        },
        onError: (err) => {
          console.error(err);
        },
      })
      .render(paypalRef.current);
    }
    catch(error){
    console.log(error)
    }
  }, []);


  if (error) {
    return (<div>Error Occurred in processing payment.! Please try again.</div>);
  }
  return (
    <div>
      <div ref={paypalRef} />
    </div>
  );
}




