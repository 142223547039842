import { useState, useEffect } from 'react'
import { Grid, Paper, TextField, Button, Typography, Link } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import { getToken, recoveryPassRequest, recoveryPass } from '../../services/services';
import store from "../../app/store";
import { postError, postSeverity } from "../../features/error/slices";

const Login = ({ handleChange }) => {
    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const [rePass, setRePass] = useState('');
    const [error, setError] = useState('');
    const [submit, setSubmit] = useState(false);
    const [recoverPass, setRecoverPass] = useState(false);
    const [backToLogin, setBackToLogin] = useState(true);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailBlur, setEmailBlur] = useState(false);
    const [passwordBlur, setPasswordBlur] = useState(false);
    let location = '/' + window.location.href.split("/")[3];
    const urlParams = new URLSearchParams(window.location.search);
    const emailUrl = urlParams.get("email");
    const temporaryToken = urlParams.get("temporary_token");
    const title = { paddingTop: 30 }
    const emailInvalid = () => {
        if (submit || emailBlur ) {
            return user ?
                /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(user)
                    ? error === "El usuario o la contraseña son incorrectos."
                        ? error
                        : ""
                    : "La dirección de correo electrónico no es válida."
                : "Tenés que ingresar una dirección de correo electrónico."
        }
        return ""
    }
    const passInvalid = () => {
           
            return pass
                ? error === "El usuario o la contraseña son incorrectos"
                    ? error
                    : ""
                : "Ingresá tu contraseña.";
    }

    useEffect(() => {
        setError('')
    }, [user, pass])

    useEffect(() => {
        store.dispatch(postError(error));
        store.dispatch(postSeverity("error"));
    }, [error])

    const formValid = () => {
        if (!error) {
            if (user) {
                return !emailInvalid() && !passInvalid()
            }
            
        }
        else {
            return false
        }
    }

    async function goTo() {
        setSubmit(true)
        setLoading(true)
     
        const dataForm = {
            user: user,
            password: pass
        }
        if (formValid()) {
            try {
                const data = await getToken(dataForm);
             
                if (data.data.token) {
                    let url = window.location.href.substring(0,window.location.href.lastIndexOf("/") + 1);
                    localStorage.setItem('token', data.data.token);
                     window.location.replace(`${url}myCourses`);
                    setLoading(false)
                }
            }
            catch (error) {
              
                setLoading(false)
                if (error?.response?.data === "Please register") {
                    setLoading(false)
                    handleChange(1, 1)
                } else {
                setError(error?.response?.data)
              
                }
            }
        }

    }

    async function recoverPasswordRequest() {
        setSubmit(true)
        const dataForm = {
            email: user,
        }
        if (user) {
            setLoading(true)
            try {
                const data = await recoveryPassRequest(dataForm);
               
                if (data.status === 200) {
                    setMessage(data.data)
                    setLoading(false)
                }
            }
            catch (error) {
           
                if (error.response.data === "Please register, that email doesnt exist.") {
                   
                    handleChange(1, 1)
                    setLoading(false)
                }
                setLoading(false)
                setError(error.response.data)
            }
        }

    }


    async function recoverPassword() {
        setSubmit(true)
        const dataForm = {
            password: pass,
            email: emailUrl,
            temporaryToken: temporaryToken
        }
       
        if (!isValidFields('rePass')) {
            setLoading(true)
            try {
                const data = await recoveryPass(dataForm);
           
                if (data.data.message) {
              
                    setLoading(false)
                    handleChange('error', data.data.message)
                }
                if (data.data.token) {
                   let url = window.location.href.substring(0,window.location.href.lastIndexOf("/") + 1);
                    setLoading(false)
                    localStorage.setItem('token', data.data.token);
                    window.location.replace(`${url}cursos`);
                }

            }
            catch (error) {
         
                if (error.response.data === "Please register, that email doesnt exist.") {
          
                    setLoading(false)
                    handleChange(1, 1)
                }
                setLoading(false)
                setError(error.response.data)
            }
        }

    }

    const isValidFields = (fieldName) => {
        if (fieldName === 'pass') {
            return pass ? "" : "Tenés que ingresar una contraseña.";
        } else if (fieldName === 'rePass') {
            return pass === rePass ? "" : "Las contraseñas no coinciden.";
        } else {
            return fieldName ? "" : `El campo de ${fieldName} es obligatorio.`;
        }
    }

    const handleIncompleteLoginFields = () => {
        setEmailBlur(true)
        setPasswordBlur(true)
    }

    return (
        <Grid id='login-container' class='loginView'>
            <div id="login-form">
                {!loading ?
                    !location.includes('/recover_password') || !backToLogin ?
                        !recoverPass ?
                            <div>
                                <Grid>
                                    <h3 style={title} class='primary noneMargin'>¡Hola!</h3>
                                    <p class='text-dark' style={{ paddingRight: 40 }}>Qué bueno volver a verte. Ingresá tus datos para comenzar a aprender.</p>
                                </Grid>
                                <div id='inputLogin' class='caption' style={{ paddingTop: 40, paddingBottom: 40 }}>
                                    <TextField value={user}
                                        className="login-signup-input-fields"
                                        autoComplete="off"
                                        error={emailInvalid()}
                                        helperText={emailInvalid()}
                                        onBlur={() => {setEmailBlur(true)}}
                                        onFocus={() => {setPasswordBlur(true)}}
                                        onChange={(event) => setUser(event.target.value)}
                                        label='Dirección de correo electrónico'
                                        placeholder='Dirección de correo electrónico' 
                                        fullWidth required />
                                    <TextField
                                        className="login-signup-input-fields"
                                        autoComplete="off"
                                        error={passInvalid()}
                                        helperText={passwordBlur ? passInvalid():null}
                                        value={pass}
                                        onFocus={() => {setEmailBlur(true)}}
                                        onChange={(event) => setPass(event.target.value)}
                                        style={{marginTop: 26 }}
                                        label='Contraseña' placeholder='Contraseña'
                                        type='password'
                                        id='password'
                                        fullWidth required />
                                </div>
                                
                                <div>
                                    <button
                                        onClick={() => {formValid() ? goTo() : handleIncompleteLoginFields()}}
                                        style={{ width: '100%'}}
                                        className={formValid() ? 'button-inscripcion' : 'button-inscripcion button-inscripcion-disabled'}
                                        >
                                            Iniciar sesión
                                        </button>
                                    <div style={{ color: '#7F55D3', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >

                                        <Typography class='secondary-dark subtitle2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                            <Link href="#" class='subtitle2'
                                                onClick={() => setRecoverPass(true)}
                                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}  >
                                                Olvidé mi contraseña.
                                            </Link>
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            :
                            !message ?
                                <div>
                                    <Grid>
                                        <Button id="back" class="button-medium" onClick={() => setRecoverPass(false)} sx={{ mr: 1 }}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clipRule="evenodd" d="M9.35323 4.97994C9.54849 5.1752 9.54849 5.49179 9.35323 5.68705L7.04011 8.00016L9.35323 10.3133C9.54849 10.5085 9.54849 10.8251 9.35323 11.0204C9.15796 11.2156 8.84138 11.2156 8.64612 11.0204L5.97945 8.35372C5.78419 8.15845 5.78419 7.84187 5.97945 7.64661L8.64612 4.97994C8.84138 4.78468 9.15796 4.78468 9.35323 4.97994Z" fill="#F88D00" />
                                            </svg>
                                            Volver
                                        </Button>
                                    </Grid>
                                    <div id='inputLogin' class='caption' style={{ paddingTop: 40, paddingBottom: 40 }}>
                                        <TextField
                                            value={user}
                                            id='email'
                                            className="login-signup-input-fields"
                                            error={emailInvalid()}
                                            helperText={emailInvalid()}
                                            onChange={(event) => setUser(event.target.value)}
                                            label='Usuario o Correo electrónico' placeholder='Usuario o Correo electrónico' fullWidth required />
                                    </div>
                                    <div  >
                                        <button
                                            disabled={emailInvalid()}
                                            onClick={() => recoverPasswordRequest()}
                                            id="btnRecuperar"
                                            className={emailInvalid() ? 'button-inscripcion button-inscripcion-disabled' : 'button-inscripcion'}
                                            style={{ width:'100%',}}>
                                                Recuperar mi contraseña
                                            </button>
                                    </div>
                                </div>
                                :
                                <div>
                                    <h4 style={title} class='noneMargin'>{message}</h4>
                                </div>
                        :
                        <div>
                            <Grid>
                                <Button id="back" class="button-medium" onClick={() => setBackToLogin(false)} sx={{ mr: 1 }}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clipRule="evenodd" d="M9.35323 4.97994C9.54849 5.1752 9.54849 5.49179 9.35323 5.68705L7.04011 8.00016L9.35323 10.3133C9.54849 10.5085 9.54849 10.8251 9.35323 11.0204C9.15796 11.2156 8.84138 11.2156 8.64612 11.0204L5.97945 8.35372C5.78419 8.15845 5.78419 7.84187 5.97945 7.64661L8.64612 4.97994C8.84138 4.78468 9.15796 4.78468 9.35323 4.97994Z" fill="#F88D00" />
                                    </svg>
                                    Volver
                                </Button>
                            </Grid>
                            <div id='inputLogin' class='caption' style={{ paddingTop: 40, paddingBottom: 40 }}>
                                <TextField
                                    className="login-signup-input-fields"
                                    type='password'
                                    error={!!isValidFields('pass')}
                                    helperText={isValidFields('pass')}
                                    value={pass} onChange={(event) => setPass(event.target.value)} style={{ borderRadius: 10, marginTop: 26}} label='Nueva contraseña' placeholder='Nueva contraseña' fullWidth />
                                <TextField
                                    className="login-signup-input-fields"
                                    type='password'
                                    error={!!isValidFields('rePass')}
                                    helperText={isValidFields('rePass')}
                                    value={rePass} onChange={(event) => setRePass(event.target.value)} style={{ borderRadius: 10, marginTop: 26 }} label='Repetí la nueva contraseña' placeholder='Repetí la nueva contraseña' fullWidth />
                            </div>
                            <div >
                                <button
                                    disabled={!!isValidFields('pass') || !!isValidFields('rePass')}
                                    onClick={() => recoverPassword()}
                                    id="btnRecuperar"
                                    className={!!isValidFields('pass') || !!isValidFields('rePass') ? 'button-inscripcion button-inscripcion-disabled' : 'button-inscripcion'}
                                    style={{ width: '100%'}}>
                                        Guardar nueva contraseña
                                    </button>
                            </div>
                        </div>
                    :
                    <Grid  container
                    style={{height: '100%'}}
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <CircularProgress />
                    </Grid>
                }
            </div>
        </Grid>
    )
}

export default Login
