let pageLocation = '/cursos'
const MoreCoursesIcon = (props) => (
    pageLocation === props.currentLocation ?
        (<svg
            width={props.width+'px'}
            height={props.height+'px'}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            style={{
              fillRule: "evenodd",
              clipRule: "evenodd",
              strokeLinejoin: "round",
              strokeMiterlimit: 2,
            }}
          >
            <path
              d="M1.029 6.171A6.171 6.171 0 0 1 7.2 0h13.714c1.136 0 2.057.921 2.057 2.057v20.572c0 .757-.614 1.371-1.371 1.371H5.829a4.801 4.801 0 0 1-4.752-4.114h-.048V6.171Zm19.885 10.286H5.829a2.743 2.743 0 1 0 0 5.486h15.085v-5.486ZM6.857 6.171c0-.568.461-1.028 1.029-1.028h9.6a1.028 1.028 0 1 1 0 2.057h-9.6a1.03 1.03 0 0 1-1.029-1.029Zm1.029 3.086a1.03 1.03 0 0 0 0 2.057h6.857a1.028 1.028 0 1 0 0-2.057H7.886Z"
              fill={props.lightColor}
            />
            <path
              d="M7.2 0h13.714c1.136 0 2.057.921 2.057 2.057v20.572c0 .757-.614 1.371-1.371 1.371H12v-2.057h8.914v-5.486H12v-5.143h2.743a1.028 1.028 0 1 0 0-2.057H12V7.2h5.486a1.029 1.029 0 0 0 0-2.057H12V0H7.2Z"
              fill={props.darkColor}
            />
          </svg>)
        :
        (<svg
            width={props.width}
            height={props.height}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            style={{
              fillRule: "evenodd",
              clipRule: "evenodd",
              strokeLinejoin: "round",
              strokeMiterlimit: 2,
            }}
          >
            <path
            className='icon-svg-path-color'
            d="M7.135 6.162c0-.537.436-.973.973-.973h9.081a.973.973 0 1 1 0 1.946H8.108a.973.973 0 0 1-.973-.973Zm.973 2.919a.973.973 0 0 0 0 1.946h6.487a.973.973 0 0 0 0-1.946H8.108Z"
            />
            <path
            className='icon-svg-path-color'
            d="M7.459 0a6.162 6.162 0 0 0-6.162 6.162v12.973A4.865 4.865 0 0 0 6.162 24h14.27a2.27 2.27 0 0 0 2.271-2.27V2.27A2.27 2.27 0 0 0 20.432 0H7.459Zm13.298 14.27v-12a.324.324 0 0 0-.325-.324H7.459a4.217 4.217 0 0 0-4.216 4.216v9.081a4.842 4.842 0 0 1 2.919-.973h14.595Zm0 1.946H6.162a2.92 2.92 0 0 0 0 5.838h14.27c.18 0 .325-.145.325-.324v-5.514Z" />
          </svg>)
            
);
export default MoreCoursesIcon;