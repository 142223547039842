import { useState, useEffect } from 'react';
import store from "../../app/store";
import { postError, postSeverity } from "../../features/error/slices";
import './saveButton.css';

const SaveButton = ({ formFields, onPress }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setSuccess(false);
  }, [formFields]);

  const handlePress = async () => {
    if (loading || success) return
    setLoading(true);
    try {
      const response = await onPress();
      ;
      if (response.status === 200) {
        setTimeout(() => {
          setSuccess(true)
          setLoading(false);
        }, 1000);
      } else {
        store.dispatch(postError(response.data.message));
        store.dispatch(postSeverity("error"));
      }
    } catch (error) {
      setLoading(false)
    }
  };

  return (
    <div className="animated-button-container">
      <div
        className={'button-inscripcion ' + (success ? 'save-button-success' : loading ? 'save-button-loading' : 'save-button-ready')}
        onClick={handlePress}
      >
        {loading ? (
            <div className="loadingSpinner"><div></div><div></div><div></div><div></div></div>
        ) : success ? (
          <p className="save-button-text">¡Guardado correctamente!</p>
        ) : (
          <p className="save-button-text">Guardar</p>
        )}
      </div>
    </div>
  );
};
export default SaveButton;