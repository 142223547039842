import { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import Confirm from "./Confirm";
import { AppContext } from "./Context";
import SyllabusCard from "../reusable/SyllabusCard";
import SpeakersCard from "../reusable/SpeakersCard";
import CourseInfo from "./CourseInfo";
import { useParams } from "react-router-dom";
import {
  getCourse,
  getCourseAuth,
  getInfoProfile,
  postCheckMPNoAuth,
  postCheckMPAuth,
  postCheckPaypalAuth,
  postCheckPaypalNoAuth,
  enrolFreeAuth,
  enrolFreeNoAuth,
  updateUser,
} from "../../services/services";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { initialValues } from "./InitialValues";
import BottomBar from "../bottomBarHome";
import TopBar from "../topBarHome";
import CourseUnavailable from "./CourseUnavailable";
import SideBar from "../sideBar/sideBar";
import './stepForm.css';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const StepForm = (props: any) => {
  const [open, setOpen] = useState(false);
  const [loadingAuthUser, setLoadingAuthUser] = useState(false);
  const [orderPaypal, setOrderPaypal] = useState({});
  const token = localStorage.getItem("token");
  const { activeStep, handleNext, formValues, handleReset } =
    useContext(AppContext);
  const [infoCourse, setInfoCourse]: any = useState({});
  let paypalResult: any = {};
  let { courseId }: any = useParams();
  const { width } = useWindowDimensions();
  let location = "/" + window.location.href.split("/")[4];
  const urlParams = new URLSearchParams(window.location.search);
  const paymentId: any = urlParams.get("payment_id");
  const [cannotRegister, setCannotRegister] = useState(false);
  const [alreadyPurchased, setAlreadyPurchased] = useState(false);
  const user:any = useSelector((state: any) => state.user.value)

  const handleSteps = (step: number) => {
    switch (step) {
      case 0:
        return (
          <FirstStep
            isFree={infoCourse.is_free}
            handleInscription={handleInscription}
          />
        );
      case 1:
        return (
          <SecondStep
            fee={infoCourse.registration_fee_ARS}
            feeUsd={infoCourse?.registration_fee_USD}
            course={courseId}
            handlePaid={handlePaid}
          />
        );
      case 2:
        return <Confirm />;
      default:
        throw new Error("Unknown step");
    }
  };

  useEffect(() => {
    handleReset();

    getInfoCourse(courseId);
    if (location.includes("preference_id")) {
      setOpen(!open);

      if (token) {
        inscriptionAuth(courseId, user, "mp");
      } else {
        inscriptionNoAuth(courseId, user, "mp");
      }
    }
    if (token) {
      userProfile();
      setLoadingAuthUser(true);
    }
  }, []);

  async function userProfile() {
    const profileResponse: any = await getInfoProfile();

    initialValues.firstName.value = profileResponse.data.user.name || "";
    initialValues.lastName.value = profileResponse.data.user.last_name || "";
    initialValues.apodo.value = profileResponse.data.user.nick_name || "";
    initialValues.pais.value = profileResponse.data.user.country || "";
    initialValues.dni.value = profileResponse.data.user.dni || "";
    initialValues.email.value = profileResponse.data.user.email || "";
    initialValues.localidad.value = profileResponse.data.user.city || "";
    initialValues.provincia.value = profileResponse.data.user.state || "";

    initialValues.firstName.disabled = profileResponse.data.user.name
      ? true
      : false;
    initialValues.lastName.disabled = profileResponse.data.user.last_name
      ? true
      : false;
    initialValues.apodo.disabled = profileResponse.data.user.nick_name
      ? true
      : false;
    initialValues.pais.disabled = profileResponse.data.user.country
      ? true
      : false;
    initialValues.dni.disabled = profileResponse.data.user.dni ? true : false;
    initialValues.email.disabled = profileResponse.data.user.email
      ? true
      : false;
    initialValues.localidad.disabled = profileResponse.data.user.city
      ? true
      : false;
    initialValues.provincia.disabled = profileResponse.data.user.state
      ? true
      : false;
    setLoadingAuthUser(false);
  }

  async function getInfoCourse(id?: string) {
    try {
      setOpen(!open);
      const dataForm = {
        id: id,
      };
      let data: any
      if (token) {
         data = await getCourseAuth(dataForm);
      }
      else {
        data = await getCourse(dataForm);
      }

      const dataCourses = data.data;
      setInfoCourse(dataCourses.course);
      if (!location.includes("preference_id")) {
        if (dataCourses.course.purchased) {
          setAlreadyPurchased(true);
        }
      }
    } catch (error : any) {
        setCannotRegister(true);
    }
  }

  async function inscriptionAuth(id: any, userData: any, plataform: string) {
    try {
      updateUserAuth();
      let dataForm: any = {};
      let data: any;
      if (plataform === "mp") {
        dataForm = {
          payment_id: parseInt(paymentId),
          course_id: parseInt(courseId),
        };

        data = await postCheckMPAuth(dataForm);
        if (data.data.message === "¡Pago exitoso!") {
          handleNext();
        }
      } else if (plataform === "paypal") {
        dataForm = paypalResult;

        dataForm.course_id = parseInt(courseId);

        data = await postCheckPaypalAuth(dataForm);
      } else {
        dataForm.course_id = parseInt(courseId);
        data = await enrolFreeAuth(dataForm);
        handleNext();
      }

      setOpen(false);
      handleNext();
    } catch (error: any) {
      setOpen(false);
    }
  }

  async function updateUserAuth() {
    const dataForm = {
      country: formValues.pais.value,
      state: formValues.provincia.value,
      city: formValues.localidad.value,
      dni: formValues.dni.value,
    };
    updateUser(dataForm);
  }

  async function inscriptionNoAuth(id: any, userData: any, plataform: string) {

    try {
      let dataForm: any = {};
      let data: any;
      if (plataform === "mp") {
        dataForm = {
          payment_id: parseInt(paymentId),
          course_id: parseInt(courseId),
          email: userData.email,
          name: userData.name,
          last_name: userData.last_name,
          nick_name: userData.nick_name,
          country: userData.country,
          dni: parseInt(userData.dni),
        };

        data = await postCheckMPNoAuth(dataForm);
        if (data.data.message === "¡Pago exitoso!") {
          handleNext();
        }
      } else if (plataform === "paypal") {
        dataForm = paypalResult;
        dataForm.course_id = parseInt(courseId);
        dataForm.email = userData.email;
        dataForm.name = userData.name;
        dataForm.last_name = userData.last_name;
        dataForm.nick_name = userData.nick_name;
        dataForm.country = userData.country;

        data = await postCheckPaypalNoAuth(dataForm);
      } else {
        dataForm.course_id = parseInt(courseId);
        dataForm.email = userData.email;
        dataForm.name = userData.name;
        dataForm.last_name = userData.last_name;
        dataForm.nick_name = userData.nick_name;
        dataForm.country = userData.country;
        data = await enrolFreeNoAuth(dataForm);
        handleNext();
      }

      setOpen(false);
      handleNext();
    } catch (error: any) {
      setOpen(false);
    }
  }

  async function handlePaid(event: any) {
    const userString: any = localStorage.getItem("user");
    const userData = JSON.parse(userString);

    setOrderPaypal(event);
    paypalResult = event;

    if (token) {
      inscriptionAuth(courseId, userData, "paypal");
    } else {
      inscriptionNoAuth(courseId, userData, "paypal");
    }
  }

  async function handleInscription() {
    const userString: any = localStorage.getItem("user");
    const userData = JSON.parse(userString);

    if (token) {
      inscriptionAuth(courseId, userData, "free");
    } else {
      inscriptionNoAuth(courseId, userData, "free");
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {!token ? <TopBar /> : <SideBar></SideBar>}
      {width > 1024 ? (
        <div
          id="inscription"
          className={token ? "view" : "view-no-auth"}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open && loadingAuthUser}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div>
            { cannotRegister || alreadyPurchased ? 
            //MUESTRO EL AVISO DE CURSO NO DISPONIBLE.
              <CourseUnavailable objection={ alreadyPurchased ? "purchased" : "unavailable"} courseId={courseId}/>
            :
            //MUESTRO FORMULARIO PARA PROCEDER CON LA COMPRA
            <div>
              <div>
                <h2
                  className="primary without-margin"
                >
                  {infoCourse.name}
                </h2>
                <p className="button-medium">Inscripción al curso</p>
              </div>
              <div>
                <div className="registration-form-and-course-info-container">
                    <div className="card course-registration-form">
                      {handleSteps(activeStep)}
                    </div>
                    <div className="card course-registration-info">
                      <CourseInfo
                        speakers={infoCourse?.speakers}
                        labels={infoCourse?.labels}
                        schedules={infoCourse?.schedules}
                        registration_fee_usd={infoCourse?.registration_fee_USD}
                        registration_fee_ars={infoCourse?.registration_fee_ARS}
                        duration={infoCourse?.number_sessions}
                      />
                    </div>
                  
                </div>
                {
                  infoCourse?.additional_info?
                    <div className="big-card-content card" dangerouslySetInnerHTML={{ __html: infoCourse?.additional_info }}></div>
                  :
                    null
                }
                <div>
                  <p className="button-medium">
                    Más datos del curso
                  </p>
                    <div className="card" style={{ marginBottom: "30px" }}>
                      <SyllabusCard temario={infoCourse?.syllabus}/>
                    </div>
                    <div className="card" style={{ marginBottom: "30px" }}>
                      <SpeakersCard speakers={infoCourse?.speakers}/>
                    </div>
                  </div>
              </div>
            </div>
          }
          </div>
        </div>
      ) : (
        <div id="inscription" className={token ? 'mobile view' : 'mobile view-no-auth'}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open && loadingAuthUser}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div>
            { cannotRegister || alreadyPurchased ? 
            //MUESTRO EL AVISO DE CURSO NO DISPONIBLE.
              <CourseUnavailable objection={ alreadyPurchased ? "purchased" : "unavailable"} courseId={courseId}/>
            :
            //MUESTRO FORMULARIO PARA PROCEDER CON LA COMPRA
            <div>
              <Box className="container-title-course" sx={{ mt: 5, mb: 1 }}>
                <h2
                  className="primary without-margin"
                  style={{ marginLeft: 2, marginBottom: 16 }}
                >
                  {infoCourse.name}
                </h2>
                <p className="button-medium">Inscripción al curso</p>
              </Box>
              <div>
                <Grid container spacing={4} alignItems="stretch">
                  <Grid item xs={12}>
                    <Box className="card" style={{ height: "100%" }}>
                      {handleSteps(activeStep)}
                    </Box>
                  </Grid>
                  <Grid item xs={12} justifyContent="flex-end">
                    <div
                      className="card"
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      <CourseInfo
                        speakers={infoCourse?.speakers}
                        labels={infoCourse?.labels}
                        schedules={infoCourse?.schedules}
                        registration_fee_usd={infoCourse?.registration_fee_USD}
                        registration_fee_ars={infoCourse?.registration_fee_ARS}
                        duration={infoCourse?.number_sessions}
                      />
                    </div>
                    {
                      infoCourse?.additional_info?
                        <div className="big-card-content card" dangerouslySetInnerHTML={{ __html: infoCourse?.additional_info }}></div>
                      :
                        null
                    }
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: "30px" }}>
                  <div className="card" style={{ marginBottom: "30px" }}>
                      <SyllabusCard temario={infoCourse?.syllabus}/>
                    </div>
                    <div className="card" style={{ marginBottom: "30px" }}>
                      <SpeakersCard speakers={infoCourse?.speakers}/>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          }
          </div>
        </div>
      )}
      {!token ? <BottomBar /> : null}
    </>
  );
};

export default StepForm;