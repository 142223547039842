type SyllabusCardProps = {
  temario: string,
}

export default function SyllabusCard({ temario }: SyllabusCardProps) {

  return (
    <div >
      <div className="big-card-content">
        <h5 className='big-card-title'>
          Temario:
        </h5>
        <div style={{ color: 'var(--dark)' }} dangerouslySetInnerHTML={{ __html: temario }}>
        </div>
      </div>
    </div>
  );
}

