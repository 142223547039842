import React, { useEffect, useState, useCallback } from 'react';
import { createUser, getPreferenceMp, getPreferenceMpNoAuth } from '../services/services';
import store from "../app/store";
import { postUser } from "../features/error/slices";
import { useSelector } from 'react-redux';
export default function Product(props) {
  const FORM_ID = 'payment-form';
  const [preferenceId, setPreferenceId] = useState(null);
  const token = localStorage.getItem('token');
  const user = useSelector(state => state.user.value)
  const memoizedPreference = useCallback(preference, [props.course, props.fee, props.dni, user.email, token]);
  useEffect(() => {
    memoizedPreference();
  }, [memoizedPreference]);

  async function preference(){
    const orderData = {
      "course": props.course,
      "registration_fee_ARS": props.fee,
      "email": user.email,
      "dni" : props.dni
    };
    try{
      store.dispatch(postUser({dni:props.dni}))
      const updatedUser = store.getState().user.value;
      await createUser(updatedUser)
      let order
      if(token){
        order = await getPreferenceMp(orderData);
      }
      else{
        order = await getPreferenceMpNoAuth(orderData);
      }
      setPreferenceId(order.data.preference_id);
  }
  catch(error){
    console.log(error);
  }
  }
  useEffect(() => {
    if (preferenceId) {
      var myScript1 = document.getElementById("mp-script");
      if(!myScript1){
      // con el preferenceId en mano, inyectamos el script de mercadoPago
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'mp-script';
      script.src =
        'https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js';
      script.setAttribute('data-preference-id', preferenceId);
      const form = document.getElementById(FORM_ID);
      form.appendChild(script);
      }
    }
  }, [preferenceId]);

  return (
    !preferenceId ? <div id="payment-button-loading">Cargando...</div> :
    <form id={FORM_ID} method="GET" style={{position:'inherit', display:props.display}}/>
  );
}