import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StepsProvider } from './components/form-inscripcion-curso/Context';
import { Provider } from 'react-redux'
import * as Sentry from "@sentry/react";
import store from './app/store';

const sentryEnvironment = process.env.REACT_APP_ENVIRONMENT;

Sentry.init({
  dsn: "https://32f9370d24bb4b38b9e64343ded2ee62@o4504934232293376.ingest.sentry.io/4505004585254912",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: sentryEnvironment,

  beforeSend(event, hint) {
    // Check if the error message is the known error created by PayPal library
    if (hint && hint.originalException && hint.originalException.message.includes("Detected container element removed from DOM")) {
      // Don't capture this error
      return null;
    }
    return event;
  },

});

ReactDOM.render(
    <StepsProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </StepsProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorker.unregister();