import { Link } from "react-router-dom";

export default function seeDetailsButton({id}){
    return (
    <div style={{width: "100%"}}>
    <div className="button">
    <Link
      className="subtitle2 text-dark"
      style={{
        display: "flex",
        justifyContent: "center",
        underline: "none",
        color: "white",
      }}
      to={"/myCourse/" + id}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <p
          to={"/myCourse/" + id}
          className="subtitle2 text-dark text-button"
        >
          {" "}
          Ver Detalle
        </p>

        <svg
          width="18"
          height="18"
          style={{
            marginLeft: 9,
            marginTop: 13,
            color: "var(--text-light)",
          }}
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.96742 11.9104L9.87742 9.00035L6.96742 6.09035C6.67492 5.79785 6.67492 5.32535 6.96742 5.03285C7.25992 4.74035 7.73242 4.74035 8.02492 5.03285L11.4674 8.47535C11.7599 8.76785 11.7599 9.24035 11.4674 9.53285L8.02492 12.9753C7.73242 13.2678 7.25992 13.2678 6.96742 12.9753C6.68242 12.6829 6.67492 12.2029 6.96742 11.9104Z"
            fill="#F9F9F9"
          />
        </svg>
      </div>
    </Link>
  </div>
  </div>
  )
}