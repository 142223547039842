const CourseMaterial = ({media_link}: { media_link: string }) => {
    return(
        <div className="card course-data-card" id="course-material">
            <div className="course-data-container">
                <div className="course-data-title-icon-container">
                    <svg
                    width="22"
                    height="15"
                    viewBox="0 0 22 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.3612 13.5795C17.9556 13.97 17.4178 14.2212 16.8252 14.2636L15.1873 14.3808C11.7335 14.6279 8.26637 14.6279 4.81252 14.3808L3.29851 14.2725C2.10248 14.1869 1.11598 13.3027 0.900458 12.1232C0.463793 9.73327 0.389037 7.29129 0.678696 4.87915L0.951182 2.61004C1.11289 1.26343 2.25529 0.25 3.61158 0.25H5.90314C7.0209 0.25 7.95216 1.04846 8.15768 2.10612L16.4721 2.10612C17.7785 2.10612 18.8912 3.05533 19.0971 4.34535L19.1612 4.74679C19.1746 4.83114 19.1876 4.91554 19.2001 5H19.5361C21.0085 5 22.0208 6.47983 21.4871 7.85212L20.2377 11.065C19.8497 12.0626 19.1988 12.9301 18.3612 13.5795ZM17.6159 4.58178L17.6799 4.98322C17.6808 4.98881 17.6817 4.99441 17.6826 5H8.37033C7.23463 5 6.21575 5.69815 5.80577 6.75727L3.47393 12.7812L3.40555 12.7763C2.89208 12.7396 2.46856 12.36 2.37603 11.8536C1.9664 9.61162 1.89627 7.32081 2.168 5.058L2.44048 2.78888C2.51167 2.19611 3.01455 1.75 3.61158 1.75H5.90314C6.34319 1.75 6.69991 2.10672 6.69991 2.54677C6.69991 3.13183 7.1742 3.60612 7.75926 3.60612H16.4721C17.0413 3.60612 17.5261 4.0197 17.6159 4.58178ZM5.03908 12.8931C8.38212 13.1266 11.7375 13.1238 15.0803 12.8846L16.7181 12.7674L16.9127 12.7492L16.9113 12.7468C17.7919 12.25 18.4732 11.4636 18.8397 10.5213L20.0891 7.30845C20.2404 6.91947 19.9535 6.5 19.5361 6.5H8.37033C7.85411 6.5 7.39098 6.81734 7.20462 7.29876L5.03908 12.8931Z"
                        fill="#F88D00"
                    />
                    </svg>
                    <p className="course-data-title">
                    Material de consulta
                    </p>
                </div>
                {
                    media_link ?
                        <a
                        className="button-inscripcion"
                        href={media_link}
                        target="_blank"
                        rel="noreferrer"
                        >
                        Ver Material
                        </a>
                    :
                    <p style={{textAlign:"center"}}>
                        No hay material de consulta para este curso.
                    </p>
                }
            </div>
        </div>
    )
}
export default CourseMaterial