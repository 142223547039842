import { useContext } from 'react'
import { AppContext } from './Context'
import { useNavigate, useParams } from "react-router-dom";

export default function Confirm() {
  let navigate = useNavigate();
  const { formValues, handleBack, handleNext } = useContext(AppContext)
  const { firstName, lastName, email, gender, date, city, phone } = formValues
  let { courseId } = useParams()
  const handleSubmit = () => {
    // Remove unwanted properties from formValue object
    let form = {}

    Object.keys(formValues).map((name) => {
      form = {
        ...form,
        [name]: formValues[name].value
      }
      return form
    })
    
    handleNext()
  }

  return (
    <>
    <div style={{marginRight: 20, marginLeft: 20,padding:20}}>
      <h5>¡Gracias por tu inscripción!</h5>
      <p className="base-regular"> En breve te va a llegar un correo electrónico con la información para acceder. Si no lo encontrás, fijate en la carpeta de correo no deseado (sobre todo si usás Hotmail). Si lo encontrás como correo no deseado, por fa, movelo a la bandeja de entrada para que no se te pierdan otros correos míos.
      </p>
      <p>
          ¡Nos vemos en el curso!
        </p>
        {localStorage.getItem('token') ?
          <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
            <button
              className="button-inscripcion"
              color="primary"
              onClick={() => navigate('/myCourse/' + courseId, { replace: true })}
            >
              Ir al curso
            </button>
          </div>
          : null
          }
      </div>
    </>
  )
}
