import { Link } from "react-router-dom";
import './cardBuyCourseCTA.css'

export default function courseCardAction({course}) {
    return (
        <div className="card-actions"
        >

            <div className="card-prices">
                <div
                style={{
                    marginBottom: "0px",
                    color: "var(--text-dark)",
                }}
                className="small text-dark"
                >
                Arancel
                </div>
                {course?.registration_fee_ARS ? (
                <div className="card-fees">
                    <div className="usd-fee">
                        <h5
                        style={{
                            marginBottom: "0px",
                            fontSize: "16px",
                            marginLeft: 0,
                        }}
                        className="primary without-margin"
                        >
                            {course.registration_fee_USD}
                        </h5>
                        <p
                        className="primary label card-currency"
                        style={{ marginLeft: "4px" }}
                        >
                            USD
                        </p>
                    </div>
                    <div className="ars-fee">
                        <p className="primary without-margin">{"("}</p>
                        <h5
                        className="primary card-fee"
                        >
                            {course.registration_fee_ARS}
                        </h5>
                        <p
                        className="primary label card-currency"
                        style={{
                            marginLeft: "4px",
                            marginRight: 0,
                        }}
                        >
                            ARS
                        </p>
                        <p className="primary without-margin">{")"}</p>
                    </div>
                </div>
                ) : (
                <h5
                    style={{ marginBottom: "0px" }}
                    className="primary without-margin"
                >
                    Gratis
                </h5>
                )}
            </div>
            <div >
                <Link
                className="button-inscripcion"
                style={{ underline: "none" }}
                to={"/curso/" + course?.id}
                >
                Inscribirse
                </Link>
            </div>
        </div>
    )
}