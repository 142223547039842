import ListCourses from './listCourses'
import SideBar from '../sideBar/sideBar'
import TopBar from "../topBarHome";
import BottomBar from "../bottomBarHome";

export default function ListCoursesView() {
    const token = localStorage.getItem("token");
        return (
            <>
            {!token ? <TopBar /> : <SideBar></SideBar>}
                <div className={token ? "view" : "view-no-auth"} id="catalogue">
                    <h1 style={{ marginBottom: '8px' }}>Más cursos</h1>
                    <p style={{ marginTop: '0px', marginBottom: '40px' }}>A continuación encontrarás los cursos disponibles en vivo o ya emitidos. ¡Inscribite para participar!</p>
                    <ListCourses public={!token}></ListCourses>
                </div>
            {!token ? <BottomBar /> : null}
        </>
        )
}



