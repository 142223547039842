import { ValidationSchema } from './Context'
const user: any = localStorage.getItem('user');

const userData = JSON.parse(user)

export let initialValues: ValidationSchema = {
  firstName: {
    required:true,
    value: userData?.name || '',
    error: '',
    validate: 'text',
    minLength: 2,
    maxLength: 20,
    helperText: 'Este campo solo acepta texto',
    disabled:false,
  },
  lastName: {
    value: userData?.last_name || '',
    error: '',
    validate: 'text',
    minLength: 2,
    maxLength: 20,
    required:true,
    disabled:false,
  },
  apodo: {
    value: userData?.nick_name || '',
    error: '',
    validate: 'text',
    required:true,
    disabled:false,
  },
  pais: {
    value: userData?.country || '',
    error: '',
    validate: 'select',
    required:true,
    disabled:false,
  },
  provincia: {
    value: userData?.provincia || '',
    error: '',
    validate: 'select',
    required:true,
    disabled:false,
  },
  
  email: {
    value: userData?.email || '',
    error: '',
    validate: 'email',
    required:true,
    disabled:false,
  },
  currency: {
    value: '',
    error: '',
    validate: 'text',
    required:true,
  },
  date: {
    value: '',
    error: ''
  },
  localidad: {
    value: userData?.localidad || '',
    error: '',
    validate: 'select',
    required:true,
    disabled:false,
  },
  agreenemt: {
    value: false,
    error: '',
    validate: 'checkbox',
    helperText: 'Please accept our terms and conditions'
  },
  phone: {
    value: '',
    error: '',
    validate: 'phone',
    maxLength: 15
  },
  dni: {
    value: userData?.dni || '',
    error: '',
    validate: 'dni',
    minLength: 8,
    maxLength: 8,
    required:true,
    disabled:false,
  }
}
