import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate, Link } from "react-router-dom";
import store from "../../app/store";
import { postUser } from "../../features/error/slices";
import {getInfoProfile } from "../../services/services";
import ListItemButton from "./listItemButton";
import MenuIcon from "../svgIcons/menu";
import MyProfileIcon from "../svgIcons/profileIcon";
import MySpecialtiesIcon from "../svgIcons/mySpecialties";
import MyCoursesIcon from "../svgIcons/myCourses";
import MoreCoursesIcon from "../svgIcons/moreCourses";
import CommunityIcon from "../svgIcons/community";
import LogoutIcon from "../svgIcons/logout";
import './sideBar.css'


const drawerWidth = 240;
const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

export default function SideBar(props) {

  let navigate = useNavigate();
  const { windows } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  //la location está así complicada para almacenar tmb las subcarpetas, que es necesario por ahora en el caso de "mis especializaciones"
  let location = window.location.href.split("/").length ===4 ? "/" + window.location.href.split("/")[3] : "/" + window.location.href.split("/")[3] + "/" + window.location.href.split("/")[4];

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  /*This useEffect is used to send the user info to the redux store so that other parts of the app can use it*/
  useEffect(() => {
    const getProfileInfo = async () => {
      const response = await getInfoProfile();
      store.dispatch(postUser(response.data.user));
    }
    getProfileInfo();
  }, []);

  const switchTheme = () => {
    const newTheme =
      localStorage.getItem("theme") === "light" ? "dark" : "light";
    document.body.setAttribute("data-theme", newTheme);
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function setTo(to) {
    navigate(to, { replace: true });
  }

  function logout() {
    const url = window.location.protocol + "//" + window.location.host;
    localStorage.removeItem("token");
    window.location.replace(url + "/cursos");
  }

  const drawer = (
    <div
      id="sideBar"
    >
      <div>
      <div
        className="sidebar-logo"
      >
        <Link to={"/"}>
          {theme === "light" ? (
            <img src={require("../../static/icon/logo-juan.svg").default} alt="logo" />
          ) : (
            <img src={require("../../static/icon/logo-juan-white.svg").default}  alt="logo" />
          )}
        </Link>
      </div>
      <div className="sideBar-menu">
        <p className="menu-button-group-title">Mi formación</p>
          <ListItemButton
            onClick={() => setTo("/infoPersonal")}
            className="sideBarButtons"
            key="Mi perfil">
            <div className="sideBarIcons">
              <MyProfileIcon width={20} height={20} color="var(--text-dark)" lightColor="var(--primary-light)" darkColor="var(--primary)" currentLocation={location}/>
            </div>
            <p className="sideBarButtonText" style={{"color" : location === "/infoPersonal" ? "var(--primary)" : "var(--text-dark)"}}>Mi perfil</p>
          </ListItemButton>
          <ListItemButton
            onClick={() => setTo("/myCourses")}
            className="sideBarButtons"
            key="Mis cursos">
            <div className="sideBarIcons">
              <MyCoursesIcon width={20} height={20} color="var(--text-dark)" lightColor="var(--primary-light)" darkColor="var(--primary)" currentLocation={location}/>
            </div>
            <p className="sideBarButtonText" style={{"color" : location === "/myCourses" ? "var(--primary)" : "var(--text-dark)"}}>Mis cursos</p>
          </ListItemButton>
          <ListItemButton
            onClick={() => setTo("/infoPersonal/especializaciones")}
            className="sideBarButtons"
            key="Mis especializaciones">
            <div className="sideBarIcons">
              <MySpecialtiesIcon width={20} height={20} color="var(--text-dark)" lightColor="var(--primary-light)" darkColor="var(--primary)" currentLocation={location}/>
            </div>
            <p className="sideBarButtonText" style={{"color" : location === "/infoPersonal/especializaciones" ? "var(--primary)" : "var(--text-dark)"}}>Mis especializaciones</p>
          </ListItemButton>
        <p className="menu-button-group-title">Recursos</p>
        <ListItemButton
          onClick={() => setTo("/cursos")}
          className="sideBarButtons"
          key="Más cursos">
          <div className="sideBarIcons">
            <MoreCoursesIcon width={20} height={20} color="var(--text-dark)" lightColor="var(--primary-light)" darkColor="var(--primary)" currentLocation={location}/>
          </div>
          <p className="sideBarButtonText" style={{"color" : location === "/cursos" ? "var(--primary)" : "var(--text-dark)"}}>Más cursos</p>
        </ListItemButton>
        <ListItemButton
          onClick={() => setTo("/comunidad")}
          className="sideBarButtons"
          key="Comunidad">
          <div className="sideBarIcons">
            <CommunityIcon width={20} height={20} color="var(--text-dark)" lightColor="var(--primary-light)" darkColor="var(--primary)" currentLocation={location}/>
          </div>
          <p className="sideBarButtonText" style={{"color" : location === "/comunidad" ? "var(--primary)" : "var(--text-dark)"}}>Comunidad</p>
        </ListItemButton>
      </div>
      </div>

      <List style={{ alignContent: "end", display: "grid" }}>
        <div className="mobile">
        </div>
        <ListItem>
          <FormControlLabel
            onClick={switchTheme}
            checked={theme === "light" ? false : true}
            control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked />}
            label=""
          />
        </ListItem>
        <ListItemButton
        className="sideBarButtons"
        onClick={() => logout()} 
        key={"Salir"}>
          <div className="sideBarIcons">
            <LogoutIcon width={20} height={20} />
          </div>
          <p className="sideBarButtonText" >Salir</p>
        </ListItemButton>
      </List>
    </div>
  );

  const container =
    windows !== undefined ? () => window().document.body : undefined;

  return (
    <div className="topbar-dashboard">
      {location !== "/" ? (
        <Box sx={{ display: "flex" }} >
          <CssBaseline />
          <header
          className="top-bar-dashboard-container"
          >
            <div
            className="top-bar-dashboard"
            >
              <div
                className="mobile" 
              >
                <Link to={"/"}>
                  {theme === "light" ? (
                    <img
                    src={require("../../static/icon/logo-juan.svg").default}
                    alt="logo"
                    />
                  ) : (
                    <img
                    src={
                        require("../../static/icon/logo-juan-white.svg").default
                      }
                    alt="logo"
                    />
                  )}
                </Link>
              </div>
              <div className="mobile">
                <ListItemButton
                onClick={handleDrawerToggle}
                className="hamburger-menu"
                >
                    <MenuIcon width={25} height={25} fill="var(--text-button)"/>
                </ListItemButton>
              </div>
              
            </div>
          </header>
          <Box
            class="sideBar"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <Drawer
              className="sideBar mobile"
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              className="desktop"
              sx={{
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
        </Box>
      ) : null}
    </div>
  );
}