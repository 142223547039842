import React, { useCallback, useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { AppContext } from "./Context";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MP from "../mercadopago";
import dollarIcon from './../../static/images/dollars.jpeg';
import pesosIcon from './../../static/images/pesos.jpg';
import Paypal from "../paypal";
import './SecondStep.css'

export default function SecondStep(props: any) {
  const currencies = [
    {
      "code": "ARS", 
      "image": pesosIcon,
      "name": "Pesos argentinos"
    },
    {
      "code": "USD",
      "image": dollarIcon,
      "name": `Dólares de EE.${'\xa0'}UU.`
    }
  ];
  const { formValues, handleChange, handleBack, handleNext, variant, margin } =
    useContext(AppContext);
  const {
    currency,
    dni,
    firstName,
    lastName,
    email,
    apodo,
    pais,
    provincia,
    localidad,
  } = formValues;
  const isError = useCallback(
    () =>
      Object.keys({ currency, dni }).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues, currency, dni]
  );
  useEffect(() => {}, [isError]);

  function handleChangeCurrency(event: any, values: any) {
    event.target = {
      type: "text",
      name: "currency",
      value: values,
    };
    handleChange(event);
    let dataUser = {
      dni: dni.value,
      name: firstName.value,
      last_name: lastName.value,
      email: email.value,
      nick_name: apodo.value,
      country: pais.value,
      provincia: provincia.value,
      localidad: localidad.value,
    };
    let dataString = JSON.stringify(dataUser);

    localStorage.setItem("user", dataString);
  }

  function handleDNI() {
    let dataUser = {
      dni: dni.value,
      name: firstName.value,
      last_name: lastName.value,
      email: email.value,
      nick_name: apodo.value,
      country: pais.value,
      provincia: provincia.value,
      localidad: localidad.value,
    };
    let dataString = JSON.stringify(dataUser);
    localStorage.setItem("user", dataString);
  }

  return (
    <>
      <div style={{ padding: 20 }}>
        <h5 className="without-margin">Información de pago</h5>
        <p>
          Si vas a pagar con una tarjeta de débito o de crédito emitida en
          Argentina, o en efectivo mediante Rapipago o Pago Fácil en Argentina,
          elegí la opción "ARS (Pesos argentinos)". Si vas a pagar con una tarjeta de débito o de
          crédito emitida en cualquier otro país, o con saldo de PayPal, elegí la opción
          "USD (Dólares de EE.{'\xa0'}UU.)".
          <br />
          <br />
          Esta elección garantiza que el pago se procese correctamente.
        </p>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            
          <RadioGroup
            aria-labelledby="currencies"
            name="radio-buttons-group"
            onChange={handleChangeCurrency}
            row
          >
            <div id='currency-selector'>
            {
              currencies.map((currency, i=0) => (
                <FormControlLabel
                id="currency-option"
                  key={i++}
                  value={currency.code}
                  control={<Radio sx={{'color': 'var(--primary)', '&.Mui-checked': {
                    'color': 'var(--primary)'}}} size="medium" />}
                  labelPlacement="top"
                  sx={{ 
                    width: '48%',
                    backgroundColor:'var(--bg-dark)',
                    boxShadow: 1,
                    borderRadius: 3,
                    margin:'5px'
                  }}
                  label={
                    <div id="currency-description">
                      <img src={currency.image} alt={currency.code} width="70%" className={currency.code} style={{margin:'10px'}}/>
                      <p id="currency-name">{currency.code}<br></br>({currency.name})</p>
                    </div>
                  } />
              ))
            }
          </div>
          </RadioGroup>
          
            
          </Grid>

          <Grid item xs={12}>
            {currency.value === "ARS" ? (
              <TextField
                variant={variant}
                margin={margin}
                fullWidth
                label="DNI Argentino:"
                name="dni"
                placeholder="DNI Argentino:"
                value={dni.value}
                onChange={handleChange}
                onInput={handleDNI}
                onKeyUp={handleDNI}
                error={Boolean(dni.error)}
                helperText={dni.error}
                required={dni.required}
              />
            ) : null}
          </Grid>
        </Grid>
        {/*botones de pago*/}
        {currency.value ==='ARS' && !dni.error && dni.value!=="" ? (
        <MP fee={props.fee} course={props.course} dni={dni.value}/>
         ) : null}
        {currency.value === "ARS" ? (
            dni.error || dni.value===""? (
              <>
              <p>Completá tu DNI correctamente para que podamos enviarte la factura. Si tu DNI tiene menos de ocho caracteres, ponele ceros al principio hasta completar los ocho caracteres.</p>
              <Button
                id="payment-button-deactivated"
                className="button-inscripcion"
                variant="contained"
                style={{
                  marginTop: 0,
                  display: dni.value ? "none" : "block",
                }}
                sx={{ mt: 3, ml: 1 }}
                disabled={isError()}
                color="primary"
                onClick={!isError() ? handleNext : () => null}
              >
                Pagar
              </Button>
              </>
            ) : null
          ) : (
            <>
              <p style={{ display: currency.value ? "block" : "none" }}>Usá el botón amarillo que dice: "PayPal" si tenés cuenta en PayPal y querés usar tu saldo o una tarjeta asociada a tu cuenta de PayPal. Usá el botón negro que dice: "Tarjeta de débito o crédito" si no tenés cuenta en PayPal o si querés pagar usando una tarjeta de crédito o de debito sin ingresar a tu cuenta de PayPal.</p>
              <div id="PayPal-iframe" style={{ display: currency.value ? "block" : "none" }}>
                <Paypal
                  handlePaid={(e: any) => props.handlePaid(e)}
                  feeUsd={props.feeUsd}
                />
              </div>
            </>
          )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 3,
            alignItems: "center",
            marginRight: { md:"30px"},
          }}
        >
          <Button
            id="back"
            className="button-medium"
            onClick={handleBack}
            sx={{ mr: 1 , paddingRight: { xs:'8px !important'} , mt: { xs:'0'} }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.35323 4.97994C9.54849 5.1752 9.54849 5.49179 9.35323 5.68705L7.04011 8.00016L9.35323 10.3133C9.54849 10.5085 9.54849 10.8251 9.35323 11.0204C9.15796 11.2156 8.84138 11.2156 8.64612 11.0204L5.97945 8.35372C5.78419 8.15845 5.78419 7.84187 5.97945 7.64661L8.64612 4.97994C8.84138 4.78468 9.15796 4.78468 9.35323 4.97994Z"
                fill="#F88D00"
              />
            </svg>
            Volver
          </Button>

          <svg
            width="40"
            height="8"
            viewBox="0 0 40 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="4" cy="4" r="4" fill="#F8BF7F" />
            <rect x="16" width="24" height="8" rx="4" fill="var(--primary)" />
          </svg>
        </Box>
      </div>
    </>
  );
}