import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid, Input, Link, Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getInfoProfile,
  updateUser,
  getSpecializations,
  getCertificates,
} from "../../services/services";
import store from "../../app/store";
import SideBar from "../sideBar/sideBar";
import { useSelector } from "react-redux";
import { useNavigate, useParams, Link as LinkRouter } from "react-router-dom";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import Tooltip from "@mui/material/Tooltip";
import { postUser } from "../../features/error/slices";
import SaveButton from "../saveButton/saveButton";
import './info-personal.css'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs() {
  const user = useSelector((state) => state.user.value);
  const [value, setValue] = React.useState(0);
  const [loadingProfile, setLoadingProfile] = useState(true);

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [apodo, setNickname] = useState("");
  const [dni, setDni] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [specializationsAnimation, setSpecializationsAnimation] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [specializationsWithoutHours, setSpecializationsWithoutHours] =
    useState([]);

  const [certificates, setCertificates] = useState([]);

  const navigate = useNavigate();

  let { specialization } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    async function userSpecializations() {
      const labelwithhours = [];
      const specializationsResponse = await getSpecializations();
      specializationsResponse.data.labels.forEach((cert) => {
        let temp = Object.assign({}, cert);
        temp.hours_done_by_user = 0;
        labelwithhours.push(temp);
      });
      if (specialization === "especializaciones") {
        setSpecializations(specializationsResponse.data.labels);
      } else {
        setSpecializations(labelwithhours);
      }
      setSpecializationsAnimation(specializationsResponse.data.labels);
      setSpecializationsWithoutHours(labelwithhours);
    }

    if (specialization === "especializaciones") {
      setValue(1);
    }
    if (user.name) {
      setName(user.name || "");
      setLastName(user.last_name || "");
      setNickname(user.nick_name || "");
      setDni(user.dni || "");
      setCountry(user.country || "");
      setCity(user.city || "");
      setEmail(user.email || "");
      setPassword(user.password || "");
      Promise.all([userSpecializations(), userCertificates()]).then(() => {
        setLoadingProfile(false);
      });
    } else {
      Promise.all([
        userSpecializations(),
        userCertificates(),
        userProfile(),
      ]).then(() => {
        setLoadingProfile(false);
      });
    }
  }, [specialization, user.city, user.country, user.dni, user.email, user.last_name, user.name, user.nick_name, user.password]);

  useEffect(() => {
    if (value === 1) {
      setSpecializations(specializationsAnimation);
    } else {
      setSpecializations(specializationsWithoutHours);
    }
  }, [value, specializationsAnimation, specializationsWithoutHours]);

  async function userProfile() {
    const profileResponse = await getInfoProfile();

    setName(profileResponse.data.user.name || "");
    setLastName(profileResponse.data.user.last_name || "");
    setNickname(profileResponse.data.user.nick_name || "");
    setDni(profileResponse.data.user.dni || "");
    setCountry(profileResponse.data.user.country || "");
    setCity(profileResponse.data.user.city || "");
    setEmail(profileResponse.data.user.email || "");
    setPassword(profileResponse.data.user.password || "");
  }

  async function userCertificates() {
    const certificatesResponse = await getCertificates();

    setCertificates(certificatesResponse.data.certificates);
  }
  const updateUserAuth = async () => {
      const dataForm = {
        name: name,
        last_name: lastName,
        nick_name: apodo,
        dni: dni,
        country: country,
        city: city,
        email: email,
        password: password,
      };
  
      const response = await updateUser(dataForm);
      if (response.status === 200) {
          localStorage.setItem('token', response.data.token);
        //ACTUALIZO EL USER EN EL STATE DE REDUX
          const profileResponse = await getInfoProfile();
          store.dispatch(postUser(profileResponse.data.user));
          setName(profileResponse.data.user.name);
      }
      return (response)
    }

  return (
    <>
    <SideBar></SideBar>
    <div id="profile" class="view">
      <div style={{ marginBottom: "64px" }}>
        <Link
          onClick={() => navigate(-1)}
          style={{
            display: "flex",
            alignItems: "center",
            margin: "30px 0px",
            textDecoration: "none",
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.35323 4.97994C9.54849 5.1752 9.54849 5.49179 9.35323 5.68705L7.04011 8.00016L9.35323 10.3133C9.54849 10.5085 9.54849 10.8251 9.35323 11.0204C9.15796 11.2156 8.84138 11.2156 8.64612 11.0204L5.97945 8.35372C5.78419 8.15845 5.78419 7.84187 5.97945 7.64661L8.64612 4.97994C8.84138 4.78468 9.15796 4.78468 9.35323 4.97994Z"
              fill="#F88D00"
            />
          </svg>
          <p className="button-back without-margin" style={{ marginLeft: 5 }}>
            Volver
          </p>
        </Link>
        <h2 class="apodo">{apodo}</h2>
        <p class="medium without-margin">
          {name} {lastName}
        </p>
      </div>
      <Box class="resp">
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              id={value === 0 ? "button-step-active" : "button-step"}
              label="Información personal"
            />
            <Tab
              id={value === 1 ? "button-step-active" : "button-step"}
              label="Especialización"
            />
            <Tab
              id={value === 2 ? "button-step-active" : "button-step"}
              label="Certificados"
            />
          </Tabs>
        </Box>
        {loadingProfile ? (
          <Stack
            spacing={2}
            justifyContent="center"
            alignItems="center"
            mt={20}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <Box>
            <TabPanel value={value} index={0}>
              <h5>Información personal</h5>

              <div>
                <Grid container>
                  <Grid item xs={6} md={6} style={{ paddingLeft: 0 }}>
                    <Input
                      fullWidth
                      style={{
                        paddingLeft: 17,
                        borderRadius: 10,
                        border: "none",
                        backgroundColor: "#FFFF",
                      }}
                      onChange={(e) => setName(e.target.value)}
                      autoComplete="off"
                      class="caption"
                      value={name}
                      label="Nombre"
                      placeholder="Nombre"
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Input
                      fullWidth
                      style={{
                        paddingLeft: 17,
                        borderRadius: 10,
                        border: "none",
                        backgroundColor: "#FFFF",
                      }}
                      onChange={(e) => setLastName(e.target.value)}
                      autoComplete="off"
                      label="Apellido"
                      placeholder="Apellido"
                      value={lastName}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingLeft: 17,
                      borderRadius: 10,
                      border: "none",
                      backgroundColor: "#FFFF",
                      marginTop: 15,
                    }}
                  >
                    <Input
                      onChange={(e) => setNickname(e.target.value)}
                      autoComplete="off"
                      label="Apodo"
                      placeholder="Apodo"
                      value={apodo}
                      fullWidth
                    />
                  </Grid>
                  <p class="label" style={{ marginLeft: 10 }}>
                    Tu apodo o el nombre que prefieras que usemos para
                    dirigirnos a vos
                  </p>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingLeft: 17,
                      borderRadius: 10,
                      border: "none",
                      backgroundColor: "#FFFF",
                    }}
                  >
                    <Input
                      onChange={(e) => setDni(e.target.value)}
                      autoComplete="off"
                      style={{ borderRadius: 10, backgroundColor: "#FFFF" }}
                      label="Dni"
                      placeholder="DNI"
                      value={dni}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} md={6} style={{ paddingLeft: 0 }}>
                    <Input
                      fullWidth
                      style={{
                        paddingLeft: 17,
                        borderRadius: 10,
                        border: "none",
                        backgroundColor: "#FFFF",
                        marginTop: 15,
                      }}
                      onChange={(e) => setCountry(e.target.value)}
                      autoComplete="off"
                      label="País"
                      placeholder="País"
                      value={country}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Input
                      fullWidth
                      style={{
                        paddingLeft: 17,
                        borderRadius: 10,
                        border: "none",
                        backgroundColor: "#FFFF",
                        marginTop: 15,
                      }}
                      onChange={(e) => setCity(e.target.value)}
                      autoComplete="off"
                      label="Ciudad"
                      value={city}
                      placeholder="Ciudad"
                    />
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingLeft: 17,
                        borderRadius: 10,
                        border: "none",
                        backgroundColor: "#FFFF",
                        marginTop: 15,
                      }}
                    >
                      <Input
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="username"
                        label="Correo electronico"
                        placeholder="Correo electronico"
                        name="username"
                        id="username"
                        value={email}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingLeft: 17,
                        borderRadius: 10,
                        border: "none",
                        backgroundColor: "#FFFF",
                        marginTop: 15,
                      }}
                    >
                      <Input
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="off"
                        label="Contraseña"
                        placeholder="Contraseña"
                        type="password"
                        name="password"
                        value={password}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              <div className="desktop">
                {" "}
                
                <SaveButton formFields={[]} onPress={updateUserAuth} />


              </div>

              <div className="mobile">
                {" "}
                <SaveButton formFields={[]} onPress={updateUserAuth} />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <h5>Trayectos de formación especializada</h5>
              {value === 1 ? (
                specializations.length ? (
                  <div>
                    {specializations.map((specialization, index) => (
                      <div
                        className="card-line"
                        style={{ maxWidth: 935, paddingBottom: "20px" }}
                      >
                        <div class="card-dekstop-hs">
                          <Grid container xs={8} spacing={1}>
                            <Grid item xs={1}>
                              <svg
                                width="22"
                                height="19"
                                viewBox="0 0 22 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M10.6116 0.302028C10.8551 0.232733 11.1119 0.23267 11.3553 0.301762C11.8729 0.44863 12.3952 0.584853 12.9192 0.72153C15.38 1.36334 17.8793 2.01516 20.1028 3.82618L21.1267 4.66016C21.542 4.99845 21.7498 5.49918 21.75 6V13C21.75 13.4142 21.4142 13.75 21 13.75C20.5858 13.75 20.25 13.4142 20.25 13V8.05668L20.0871 8.18963C19.3166 8.81834 18.5128 9.30655 17.6888 9.70286C17.7282 9.79397 17.75 9.89444 17.75 10V14.2939C17.75 15.4272 17.0548 16.4444 15.9989 16.8561L11.9989 18.4156C11.3565 18.666 10.6435 18.666 10.0011 18.4156L6.0011 16.8561C4.94522 16.4444 4.25 15.4272 4.25 14.2939V10C4.25 9.89177 4.27292 9.7889 4.31418 9.69597C3.48383 9.29733 2.67373 8.80626 1.89724 8.17382L0.873302 7.33983C0.0426437 6.66327 0.0422074 5.33693 0.872146 4.65967L1.91292 3.81037C4.12037 2.00904 6.60193 1.36111 9.04453 0.72335C9.56971 0.586228 10.0931 0.449573 10.6116 0.302028ZM20.25 6.0006C20.25 5.91051 20.2136 5.85102 20.1794 5.8232L19.1555 4.98922C17 3.5 15.0794 2.84319 12.6399 2.20216C12.1035 2.06121 11.5519 1.91626 10.984 1.75559C10.4135 1.91742 9.8599 2.06306 9.3217 2.20465C6.9018 2.84128 4.79323 3.39601 2.86128 4.97253L1.8205 5.82184C1.78631 5.84973 1.74996 5.90931 1.75 5.9994C1.75004 6.08949 1.78643 6.14898 1.82058 6.1768L2.84453 7.01078C4.79285 8.59767 6.92065 9.15681 9.36011 9.79784C9.89653 9.9388 10.4481 10.0837 11.016 10.2444C11.5865 10.0826 12.14 9.93696 12.6782 9.79538C15.0981 9.15875 17.2068 8.60399 19.1387 7.02747L20.1795 6.17816C20.2137 6.15029 20.25 6.09054 20.25 6.0006ZM12.9555 11.2766C14.0553 10.9895 15.163 10.7003 16.25 10.3046V14.2939C16.25 14.8091 15.934 15.2714 15.454 15.4585L11.454 17.018C11.1621 17.1318 10.8379 17.1318 10.546 17.018L6.54596 15.4585C6.06601 15.2714 5.75 14.8091 5.75 14.2939V10.2961C6.84877 10.6963 7.96869 10.9884 9.08078 11.2785C9.60482 11.4151 10.1271 11.5514 10.6447 11.6982C10.8881 11.7673 11.145 11.7673 11.3884 11.698C11.9069 11.5504 12.4303 11.4138 12.9555 11.2766Z"
                                  fill="#F88D00"
                                />
                              </svg>
                            </Grid>
                            <Grid item xs={10} style={{ marginBottom: 20 }}>
                              <h6 className="bold without-margin">
                                {specialization.label_name}
                              </h6>
                            </Grid>
                          </Grid>
                          <Grid container xs={4} spacing={1}>
                            <p className="small without-margin">
                              Horas de especialización:
                              {specialization.hours_done_by_user}/500 horas
                            </p>
                          </Grid>
                        </div>

                        <div class="card-mobile-hs">
                          <div>
                            <svg
                              width="22"
                              height="19"
                              viewBox="0 0 22 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.6116 0.302028C10.8551 0.232733 11.1119 0.23267 11.3553 0.301762C11.8729 0.44863 12.3952 0.584853 12.9192 0.72153C15.38 1.36334 17.8793 2.01516 20.1028 3.82618L21.1267 4.66016C21.542 4.99845 21.7498 5.49918 21.75 6V13C21.75 13.4142 21.4142 13.75 21 13.75C20.5858 13.75 20.25 13.4142 20.25 13V8.05668L20.0871 8.18963C19.3166 8.81834 18.5128 9.30655 17.6888 9.70286C17.7282 9.79397 17.75 9.89444 17.75 10V14.2939C17.75 15.4272 17.0548 16.4444 15.9989 16.8561L11.9989 18.4156C11.3565 18.666 10.6435 18.666 10.0011 18.4156L6.0011 16.8561C4.94522 16.4444 4.25 15.4272 4.25 14.2939V10C4.25 9.89177 4.27292 9.7889 4.31418 9.69597C3.48383 9.29733 2.67373 8.80626 1.89724 8.17382L0.873302 7.33983C0.0426437 6.66327 0.0422074 5.33693 0.872146 4.65967L1.91292 3.81037C4.12037 2.00904 6.60193 1.36111 9.04453 0.72335C9.56971 0.586228 10.0931 0.449573 10.6116 0.302028ZM20.25 6.0006C20.25 5.91051 20.2136 5.85102 20.1794 5.8232L19.1555 4.98922C17 3.5 15.0794 2.84319 12.6399 2.20216C12.1035 2.06121 11.5519 1.91626 10.984 1.75559C10.4135 1.91742 9.8599 2.06306 9.3217 2.20465C6.9018 2.84128 4.79323 3.39601 2.86128 4.97253L1.8205 5.82184C1.78631 5.84973 1.74996 5.90931 1.75 5.9994C1.75004 6.08949 1.78643 6.14898 1.82058 6.1768L2.84453 7.01078C4.79285 8.59767 6.92065 9.15681 9.36011 9.79784C9.89653 9.9388 10.4481 10.0837 11.016 10.2444C11.5865 10.0826 12.14 9.93696 12.6782 9.79538C15.0981 9.15875 17.2068 8.60399 19.1387 7.02747L20.1795 6.17816C20.2137 6.15029 20.25 6.09054 20.25 6.0006ZM12.9555 11.2766C14.0553 10.9895 15.163 10.7003 16.25 10.3046V14.2939C16.25 14.8091 15.934 15.2714 15.454 15.4585L11.454 17.018C11.1621 17.1318 10.8379 17.1318 10.546 17.018L6.54596 15.4585C6.06601 15.2714 5.75 14.8091 5.75 14.2939V10.2961C6.84877 10.6963 7.96869 10.9884 9.08078 11.2785C9.60482 11.4151 10.1271 11.5514 10.6447 11.6982C10.8881 11.7673 11.145 11.7673 11.3884 11.698C11.9069 11.5504 12.4303 11.4138 12.9555 11.2766Z"
                                fill="#F88D00"
                              />
                            </svg>
                          </div>
                          <div>
                            <p>Traducción médica</p>
                          </div>
                          <div>
                            <div>
                              <p>+ 12 horas nuevas</p>
                            </div>
                            <div>
                              <p>45/100</p>
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            color: "var(--secondary)",
                            marginTop: "30px",
                          }}
                        >
                          <ProgressBar
                            percent={
                              (specialization.hours_done_by_user / 500) * 100
                            }
                            unfilledBackground=" linear-gradient(180deg, rgba(185, 161, 234, 0.162) 0%, rgba(185, 161, 234, 0.016) 31.77%, rgba(185, 161, 234, 0.016) 100%), linear-gradient(330.01deg, rgba(184, 137, 238, 0.51) 4.54%, rgba(232, 227, 241, 0) 112.66%) "
                            filledBackground="#1F185B"
                            height="5px"
                            stepPositions={[20, 40, 100]}
                          >
                            <Step transition="scale" position={20}>
                              {({ accomplished }) => (
                                <img
                                  width="20"
                                  alt="meta"
                                  src={
                                    (20 / 500) * 100 <=
                                    (specialization.hours_done_by_user / 500) *
                                      100
                                      ? require("../../static/icon/check-meta.svg")
                                          .default
                                      : require("../../static/icon/prox-meta.svg")
                                          .default
                                  }
                                />
                              )}
                            </Step>
                            <Step transition="scale" position={40}>
                              {({ accomplished }) => (
                                <img
                                  width="20"
                                  alt="meta"
                                  src={
                                    (40 / 500) * 100 <=
                                    (specialization.hours_done_by_user / 500) *
                                      100
                                      ? require("../../static/icon/check-meta.svg")
                                          .default
                                      : require("../../static/icon/prox-meta.svg")
                                          .default
                                  }
                                />
                              )}
                            </Step>
                            <Step transition="scale" position={100}>
                              {({ accomplished }) => (
                                <img
                                  width="20"
                                  alt="meta"
                                  src={
                                    100 <=
                                    (specialization.hours_done_by_user / 500) *
                                      100
                                      ? require("../../static/icon/check-meta.svg")
                                          .default
                                      : require("../../static/icon/prox-meta.svg")
                                          .default
                                  }
                                />
                              )}
                            </Step>
                          </ProgressBar>
                        </div>
                        <Grid container direction="row">
                          {specialization?.certificates
                            ? specialization?.certificates?.map((cert) => (
                                <Grid item xs={4}>
                                  <div
                                    className="card"
                                    style={{
                                      padding: "44px 18px 18px 48px",
                                      marginTop: "31px",
                                      marginRight: "8px",
                                      paddingBottom: 18,
                                    }}
                                  >
                                    <Grid container spacing={4}>
                                      <Grid item xs={1}>
                                        <svg
                                          width="18"
                                          height="20"
                                          viewBox="0 0 18 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M9 4.25C7.20508 4.25 5.75 5.70507 5.75 7.5C5.75 9.29493 7.20508 10.75 9 10.75C10.7949 10.75 12.25 9.29493 12.25 7.5C12.25 5.70507 10.7949 4.25 9 4.25ZM7.25 7.5C7.25 6.5335 8.03351 5.75 9 5.75C9.9665 5.75 10.75 6.5335 10.75 7.5C10.75 8.4665 9.9665 9.25 9 9.25C8.03351 9.25 7.25 8.4665 7.25 7.5Z"
                                            fill="#F88D00"
                                          />
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M9 0.25C4.99594 0.25 1.75 3.49594 1.75 7.5C1.75 8.96813 2.18639 10.3343 2.93652 11.476L0.350482 15.9551C0.203417 16.2099 0.218821 16.527 0.389877 16.7663C0.560933 17.0056 0.856064 17.1228 1.14467 17.066L3.75854 16.5522L4.62047 19.0728C4.71564 19.3511 4.96471 19.5481 5.25745 19.5766C5.5502 19.6051 5.83258 19.4599 5.97965 19.2051L8.55942 14.7368C8.70516 14.7456 8.85207 14.75 9 14.75C9.14794 14.75 9.29485 14.7456 9.44059 14.7368L12.0204 19.2051C12.1676 19.4601 12.4504 19.6054 12.7434 19.5765C13.0364 19.5477 13.2854 19.3501 13.3801 19.0713L14.2376 16.5455L16.8537 17.0657C17.1425 17.1232 17.4382 17.0063 17.6097 16.767C17.7812 16.5276 17.7967 16.2101 17.6495 15.9551L15.0635 11.476C15.8136 10.3343 16.25 8.96813 16.25 7.5C16.25 3.49594 13.0041 0.25 9 0.25ZM3.25 7.5C3.25 4.32436 5.82437 1.75 9 1.75C12.1756 1.75 14.75 4.32436 14.75 7.5C14.75 10.6756 12.1756 13.25 9 13.25C5.82437 13.25 3.25 10.6756 3.25 7.5ZM6.98376 14.466C5.83078 14.1328 4.79597 13.5212 3.95691 12.7086L2.4747 15.2759L4.11167 14.9541C4.47972 14.8817 4.84463 15.0924 4.96599 15.4473L5.50579 17.0259L6.98376 14.466ZM12.4918 17.0217L11.0163 14.466C12.1692 14.1328 13.204 13.5212 14.0431 12.7086L15.5229 15.2717L13.8851 14.9461C13.516 14.8727 13.1497 15.0842 13.0287 15.4406L12.4918 17.0217Z"
                                            fill="#F88D00"
                                          />
                                        </svg>
                                      </Grid>
                                      <Grid item xs={10} mb={2}>
                                        <p
                                          className="button-medium without-margin"
                                          style={{ marginLeft: 15 }}
                                        >
                                          Certificado de {cert.tier} horas
                                        </p>
                                      </Grid>
                                      <LinkRouter
                                        className="button-inscripcion text-dark"
                                        style={{
                                          underline: "none",
                                          width: "100%",
                                        }}
                                        to={`/certificado/${cert.certificate}`}
                                      >
                                        Ver certificado
                                      </LinkRouter>
                                    </Grid>
                                  </div>
                                </Grid>
                              ))
                            : null}
                        </Grid>
                      </div>
                    ))}
                  </div>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      height: "100px",
                      width: "90vw",
                    }}
                  >
                    <Box
                      sx={{
                        textCenter: "center",
                        width: "552px",
                      }}
                    >
                      <h6 style={{ marginBottom: "19px" }}>
                        ¡Todavía no tenés trayectos de formación especializada!
                      </h6>
                      <p>
                        Empezá a capacitarte haciendo clic en Más cursos, en el
                        menú de la izquierda.
                        <br></br>
                        <br></br>
                        ¡Comencemos!
                      </p>
                    </Box>
                  </Box>
                )
              ) : null}
            </TabPanel>

            <TabPanel value={value} index={2}>
              <h5>Certificados</h5>
              <div id="certificate-card-container">
                {certificates.length ? (
                  certificates.map((cert) => (
                    <div id="certificate-card" className="card">
                        <div id="certificate-card-header">
                          <div id="certificate-card-icon">
                            <svg
                              width="18"
                              height="20"
                              viewBox="0 0 18 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9 4.25C7.20508 4.25 5.75 5.70507 5.75 7.5C5.75 9.29493 7.20508 10.75 9 10.75C10.7949 10.75 12.25 9.29493 12.25 7.5C12.25 5.70507 10.7949 4.25 9 4.25ZM7.25 7.5C7.25 6.5335 8.03351 5.75 9 5.75C9.9665 5.75 10.75 6.5335 10.75 7.5C10.75 8.4665 9.9665 9.25 9 9.25C8.03351 9.25 7.25 8.4665 7.25 7.5Z"
                                fill="#F88D00"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9 0.25C4.99594 0.25 1.75 3.49594 1.75 7.5C1.75 8.96813 2.18639 10.3343 2.93652 11.476L0.350482 15.9551C0.203417 16.2099 0.218821 16.527 0.389877 16.7663C0.560933 17.0056 0.856064 17.1228 1.14467 17.066L3.75854 16.5522L4.62047 19.0728C4.71564 19.3511 4.96471 19.5481 5.25745 19.5766C5.5502 19.6051 5.83258 19.4599 5.97965 19.2051L8.55942 14.7368C8.70516 14.7456 8.85207 14.75 9 14.75C9.14794 14.75 9.29485 14.7456 9.44059 14.7368L12.0204 19.2051C12.1676 19.4601 12.4504 19.6054 12.7434 19.5765C13.0364 19.5477 13.2854 19.3501 13.3801 19.0713L14.2376 16.5455L16.8537 17.0657C17.1425 17.1232 17.4382 17.0063 17.6097 16.767C17.7812 16.5276 17.7967 16.2101 17.6495 15.9551L15.0635 11.476C15.8136 10.3343 16.25 8.96813 16.25 7.5C16.25 3.49594 13.0041 0.25 9 0.25ZM3.25 7.5C3.25 4.32436 5.82437 1.75 9 1.75C12.1756 1.75 14.75 4.32436 14.75 7.5C14.75 10.6756 12.1756 13.25 9 13.25C5.82437 13.25 3.25 10.6756 3.25 7.5ZM6.98376 14.466C5.83078 14.1328 4.79597 13.5212 3.95691 12.7086L2.4747 15.2759L4.11167 14.9541C4.47972 14.8817 4.84463 15.0924 4.96599 15.4473L5.50579 17.0259L6.98376 14.466ZM12.4918 17.0217L11.0163 14.466C12.1692 14.1328 13.204 13.5212 14.0431 12.7086L15.5229 15.2717L13.8851 14.9461C13.516 14.8727 13.1497 15.0842 13.0287 15.4406L12.4918 17.0217Z"
                                fill="#F88D00"
                              />
                            </svg>
                          </div>
                          <div id="certificate-card-title">
                            <p
                              className="button-medium without-margin"
                              style={{ marginLeft: 15 }}
                            >
                              {cert.title.length >= 42 ? (
                                <Tooltip
                                  disableFocusListener
                                  disableTouchListener
                                  title={cert.title}
                                >
                                  <p
                                    className="button-medium without-margin"
                                    style={{ marginLeft: 15 }}
                                  >
                                    {cert.title.substring(0, 52)} ...
                                  </p>
                                </Tooltip>
                              ) : (
                                <p
                                  className="button-medium without-margin"
                                  style={{ marginLeft: 15 }}
                                >
                                  {cert.title}
                                </p>
                              )}
                            </p>
                          </div>
                        </div>
                          <LinkRouter
                            className="button-inscripcion text-dark"
                            style={{ underline: "none", width: "100%" }}
                            to={`/certificado/${cert.id}`}
                          >
                            Ver certificado
                          </LinkRouter>
                        
                    </div>
                  ))
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      height: "100px",
                      width: "90vw",
                    }}
                  >
                    <Box
                      sx={{
                        textCenter: "center",
                        width: "552px",
                      }}
                    >
                      <h6 style={{ marginBottom: "19px" }}>
                        ¡Todavía no tenes certificados!
                      </h6>
                      <p>
                        Para conseguirlos empezá a capacitarte haciendo clic en
                        Más cursos, en el menú de la izquierda.
                        <br></br>
                        <br></br>
                        ¡Comencemos!
                      </p>
                    </Box>
                  </Box>
                )}
              </div>
            </TabPanel>
          </Box>
        )}
      </Box>
    </div>
    </>
  );
}
