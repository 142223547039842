import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "@mui/material";
import Stack from "@mui/material/Stack";
import {  getRecommendedCourseByLabel, getMyCourse } from "../../services/services";
import CourseCard from '../reusable/courseCard/courseCard'
import CardBuyCourseCTA from '../reusable/cardBuyCourseCTA/cardBuyCourseCTA';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import SideBar from "../sideBar/sideBar";
import SessionLinks from "./SessionLinks";
import CourseMaterial from "./CourseMaterial";
import returnSpeakersString from '../../utils/returnSpeakersString';
import CertificateInfo from "./CertificateInfo";
import SpecialtyProgress from "./SpecialtyProgress";
import SyllabusCard from "../reusable/SyllabusCard";
import SpeakersCard from "../reusable/SpeakersCard";

const DetailMyCourse = () => {
  const [infoCourse, setInfoCourse]: any = useState({});
  const [listRecommendedCourse, setRecommendedCourse] = useState([]);
  let { courseId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function getInfoCourse(id?: string) {
      const dataForm = {id: id};
      const data: any = await getMyCourse(dataForm);
      const dataCourses = data.data;
      setInfoCourse(dataCourses.course);
      dataCourses.course.labels.forEach((label: any, index: any) => {
        if (index === 0) {
          getRecommendedCourse(label.id);
        }
      });
    }
    getInfoCourse(courseId);
  }, [courseId]);


  async function getRecommendedCourse(id?: string) {
    const dataForm = {label: id};
    const data: any = await getRecommendedCourseByLabel(dataForm);
    const dataCourses = data.data;
    setRecommendedCourse(dataCourses.recommended_courses);
  }

  return (
    <div id="myCourse">
      <SideBar></SideBar>
      <div id="curso" className="view">
          <Link
            onClick={() => navigate(-1)}
            style={{
              display: "flex",
              alignItems: "center",
              margin: "30px 0px",
              textDecoration: "none",
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.35323 4.97994C9.54849 5.1752 9.54849 5.49179 9.35323 5.68705L7.04011 8.00016L9.35323 10.3133C9.54849 10.5085 9.54849 10.8251 9.35323 11.0204C9.15796 11.2156 8.84138 11.2156 8.64612 11.0204L5.97945 8.35372C5.78419 8.15845 5.78419 7.84187 5.97945 7.64661L8.64612 4.97994C8.84138 4.78468 9.15796 4.78468 9.35323 4.97994Z"
                fill="#F88D00"
              />
            </svg>
            <p className="button-back without-margin" style={{ marginLeft: 5 }}>
              Volver
            </p>
          </Link>
          {Object.keys(infoCourse).length === 0 ?
            
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height:'500px'}}>
              <CircularProgress color="inherit"/>
            </div>
            :
            <>
              <h2 className="primary without-margin" style={{ paddingBottom: 16 }}>
            {infoCourse.name}
          </h2>
          <div style={{ marginBottom:'20px'}}>
            <p className="medium without-margin" style={{ display: "flex" }}>
              Dictado por {returnSpeakersString(infoCourse?.speakers)}
            </p>
            {infoCourse?.past_course ?
              infoCourse?.sessions?.length === 0 ?
                null
                :
                <p className="medium">
                  Ya podés ver la grabación.
                </p>
              :
              <p className="medium">
                Comienza el {infoCourse?.next_class?.proxima_clase}.
              </p>
            }
          </div>

          <div id="my-course-small-card-details">
            <div id="my-course-sessions-material">
              <SessionLinks sessions={infoCourse.sessions} past_course={infoCourse.past_course}></SessionLinks>
              <CourseMaterial media_link={infoCourse.media_link}></CourseMaterial>
            </div>
            <div id="my-course-certificate-specialty">
              <CertificateInfo certificate_id={infoCourse.certificate_id} id={infoCourse.id} />
              {infoCourse?.labels?.length ? (
                <SpecialtyProgress labels={infoCourse.labels} attended={infoCourse?.certificate_id ? true : false}/>
              ) : null}
            </div>
          </div>

          {/* -------------------------------------------------------------------------------------------------------------------------------------------- */}
          <p className="button-medium">
            Información del curso
          </p>
          <div className="syllabus-and-speakers">
              <div className="card">
                <SyllabusCard temario={infoCourse?.syllabus}/>
              </div>
              <div className="card">
                <SpeakersCard speakers={infoCourse?.speakers}/>
              </div>
          </div>
          {/* Recomendados */}
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {listRecommendedCourse.length ? (
              <div>
                <Stack
                  sx={{ width: "100%" }}
                  direction="row"
                  spacing={2}
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  <p style={{ marginBottom: 0 }}>Cursos recomendados para vos:</p>
                  
                </Stack>
                <div>
                  <div className="cards-container">
                    {listRecommendedCourse.map((course: any) => (
                      <CourseCard course={course} isRecommended={true}>
                      <CardBuyCourseCTA course={course}/>
                    </CourseCard>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
            </>
          }
        </div>
      </div>
  );
};

export const style = {
  listCoursesContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  card: {
    margin: "24px",
    marginLeft: "0px",
    borderImage: "linear-gradient(to top, '#00C853', '#B2FF59')",
  },
};

export default DetailMyCourse;
