import { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import {
  getAllCourses,
  getAllLabels,
  getAllCoursesWithOutAuth,
  getUpcomingCourses,
  getRecommendedCoursesUser,
  getRecommendedCourses,
} from "../../services/services";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CourseCard from '../reusable/courseCard/courseCard'
import CardBuyCourseCTA from '../reusable/cardBuyCourseCTA/cardBuyCourseCTA';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function ListCourses(props) {
  const theme = useTheme();
  const [listCourse, setlistCourse] = useState([]);
  const [listCoursePersistent, setlistCoursePersistent] = useState([]);
  const [listUpcomingCourse, setUpcomingCourse] = useState([]);
  const [listUpcomingCoursePersistent, setUpcomingCoursePersistent] = useState([]);
  const [listRecommendedCourse, setRecommendedCourse] = useState([]);
  const [listRecommendedCoursePersistent, setRecommendedCoursePersistent] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [filterLabels, setFilterLabels] = useState([]);
  const [labels, setLabels] = useState([]);
  const [loading, setloading] = useState(true);


  useEffect(() => {
    getLabels();
    getCourses();
    getListRecommendedCourse();
    getListUpcomingCourses();
  }, []);

  async function getCourses() {
    const token = localStorage.getItem("token");
    let data;
    if (token) {
      data = await getAllCourses();
    } else {
      data = await getAllCoursesWithOutAuth();
    }
    const dataCourses = data.data;
    setlistCourse(dataCourses.courses);
    setlistCoursePersistent(dataCourses.courses);
    setloading(false);
  }

  async function getListRecommendedCourse() {
    let data;
    if (!props.public) {
      data = await getRecommendedCoursesUser();
    } else {
      data = await getRecommendedCourses();
    }
    const dataCourses = data.data;
    setRecommendedCourse(dataCourses.recommended_courses);
    setRecommendedCoursePersistent(dataCourses.recommended_courses);
  }

  async function getListUpcomingCourses() {
    const data = await getUpcomingCourses();
    const dataCourses = data.data;
    setUpcomingCourse(dataCourses.courses);
    setUpcomingCoursePersistent(dataCourses.courses);
  }

  async function getLabels() {
    const data = await getAllLabels();
    setLabels(data.data.labels);
  }

  function filterByLabel(event) {
    const selectionsLabels = event.target.value;
    const {
      target: { value },
    } = event;
    setFilterLabels(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    let listFilter = listCoursePersistent;
    let listUpcomingFilter = listUpcomingCoursePersistent;
    let listRecommendedFilter = listRecommendedCoursePersistent;
    if (event.target.value.length) {
      listFilter = listCoursePersistent.filter((course) => {
        let filterCourse;
        selectionsLabels.forEach((label) => {
          if (typeof course.labels != "string") {
            course.labels.forEach((labelCourse) => {
              if (labelCourse.name === label) {
                filterCourse = course;
              }
            });
          } else if (course.labels === label) {
            filterCourse = course;
          }
        });
        if (filterCourse) {
          return course;
        }
      });

      listUpcomingFilter = listUpcomingCoursePersistent.filter((course) => {
        let filterCourse;
        selectionsLabels.forEach((label) => {
          if (typeof course.labels != "string") {
            course.labels.forEach((labelCourse) => {
              if (labelCourse.name === label) {
                filterCourse = course;
              }
            });
          } else if (course.labels === label) {
            filterCourse = course;
          }
        });
        if (filterCourse) {
          return course;
        }
      });

      listRecommendedFilter = listRecommendedCoursePersistent.filter(
        (course) => {
          let filterCourse;
          selectionsLabels.forEach((label) => {
            if (typeof course.labels != "string") {
              course.labels.forEach((labelCourse) => {
                if (labelCourse.name === label) {
                  filterCourse = course;
                }
              });
            } else if (course.labels === label) {
              filterCourse = course;
            }
          });
          if (filterCourse) {
            return course;
          }
        }
      );
    }

    setlistCourse(listFilter);
    setUpcomingCourse(listUpcomingFilter);
    setRecommendedCourse(listRecommendedFilter);
  }

  function filterByName(event) {
    const listFilter = listCoursePersistent.filter((course) =>
      course.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    const listUpcomingFilter = listUpcomingCoursePersistent.filter((course) =>
      course.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    const listRecommendedFilter = listRecommendedCoursePersistent.filter(
      (course) =>
        course.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setlistCourse(listFilter);
    setUpcomingCourse(listUpcomingFilter);
    setRecommendedCourse(listRecommendedFilter);
    setFilterName(event.target.value);
  }


  return (
    <div>
      <Stack direction="row" spacing={2} alignItems="center" id="filters">
        <FormControl sx={{ m: 1, width: 300 }} className="filters course-search-filter">
          <InputLabel id="demo-multiple-chip-label" className="filters course-search-filter-label">
            Todas las especialidades
          </InputLabel>
          <Select
            fullWidth
            className="filters course-search-filter-box"
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={filterLabels}
            onChange={filterByLabel}
            input={<FilledInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                  color: "var(--text-light)",
                }}
              >
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {labels.map((label) => (
              <MenuItem
                key={label.name}
                value={label.name}
                style={getStyles(label, label.name, theme)}
              >
                <div style={{ color: "var(--text)" }}>{label.name}</div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{ width: "70%", maxWidth: "552px" }}
          style={{ margin: "8px" }}
          className="filters course-search-filter"
        >
          <InputLabel htmlFor="component-outlined"
          className="course-search-filter-label">
            Cursos
            </InputLabel>
          <FilledInput
            className="course-search-filter-box"
            fullWidth
            id="component-outlined"
            value={filterName}
            onChange={filterByName}
            label="Name"
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
      </Stack>

      <div className="cards-container">
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
              width: "90vw",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div>
            {/* proximos cursos */}
            {listUpcomingCourse.length ? (
                  <>
                    <p>
                      Próximos cursos:
                    </p>
                    <div className="cards-container">
                      {listUpcomingCourse.map((course) => (
                        <CourseCard course={course} isRecommended={false}>
                          <CardBuyCourseCTA course={course}/>
                        </CourseCard>
                      ))}
                    </div>
                  </>
            ) : null}

            {/* recomendados */}
            {listRecommendedCourse.length ? (
              <>
                <p>
                  Cursos recomendados:
                </p>
                <div className="cards-container">
                    {listRecommendedCourse.map((course) => (
                      <CourseCard course={course} isRecommended={true}>
                      <CardBuyCourseCTA course={course}/>
                    </CourseCard>
                    ))}
                </div>
              </>
            ) : null}

            {/* cursos emitidos */}
            {listCourse ? (
              <>
                <p>
                  Cursos grabados:
                </p>
                <div className="cards-container">
                  {listCourse.map((course) => (
                    <CourseCard course={course} isRecommended={false}>
                    <CardBuyCourseCTA course={course}/>
                  </CourseCard>
                  ))}
                </div>
              </>
            ) : (
              <h4>No data</h4>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export const style = {
  listCoursesContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  card: {
    margin: "24px",
    marginLeft: "0px",
    borderImage: "linear-gradient(to top, '#00C853', '#B2FF59')",
  },
};
