import axios from "axios";
import store from "../app/store";
import { postError, postSeverity } from "../features/error/slices";
import url from "../static/constants";
const baseURL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem("token");

axios.defaults.baseURL = baseURL;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

axios.interceptors.response.use(
  (res) => {
    if (res.status === 201) {
     
    }
    return res;
  },
  (err) => {
    if (err.response.status === 403) {
      localStorage.removeItem("token");
      window.location.replace(url);
     
      return Promise.reject(err);
    } else if (err.response.status === 400) {
      if (err?.response?.data?.message === "that course doesnt exist") {
        window.location.replace(`${url}cursos`);
      } else if (err?.response?.data?.Message) {
        store.dispatch(postSeverity("error"));
        store.dispatch(postError(err?.response?.data?.Message));
      } else if (err?.response?.data?.message) {
        store.dispatch(postSeverity("error"));
        store.dispatch(postError(err?.response?.data?.message));
      }
  
      return Promise.reject(err);
    } else if (err.response.status === 500) {
      store.dispatch(postSeverity("error"));
      store.dispatch(
        postError(
          "Error no controlado, intente volver a cargar. Disculpe las molestias."
        )
      );
      return null;
    } else {
    
      if (err?.response?.data !== "El usuario o la contraseña son incorrectos") {
        store.dispatch(postSeverity("error"));
        store.dispatch(postError(err?.response?.data));
        return null;
      } else {
        return Promise.reject(err);
      }
    }
  }
);

//this interceptor is used to avoid issues when a change in token during the session would cause api calls to be made with the old token.
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const getToken = (dataForm) => {
  return axios({
    method: "GET",
    url: `/login`,
    auth: {
      username: dataForm.user,
      password: dataForm.password,
    },
  });
};

export const register = (dataForm) => {
  return axios({
    method: "POST",
    url: `/user/register`,
    data: dataForm,
  });
};

export const contact = (dataForm) => {
  return axios({
    method: "POST",
    url: `/contact`,
    data: dataForm,
  });
};

export const activate = (dataForm) => {
  return axios({
    method: "GET",
    url: `/user/activate_account?email=${dataForm.email}&temporary_token=${dataForm.temporaryToken}`,
  });
};

export const recoveryPass = (dataForm) => {
  const pass = {
    password: dataForm.password,
  };
  return axios({
    method: "POST",
    url: `user/recover_password?email=${dataForm.email}&temporary_token=${dataForm.temporaryToken}`,
    data: pass,
  });
};

export const recoveryPassRequest = (dataForm) => {
  return axios({
    method: "POST",
    url: `/user/recover_password_request`,
    data: dataForm,
  });
};

export const createUser = (dataForm) => {
  return axios({
    method: "POST",
    url: `/create_user`,
    data: dataForm,
  });
};

export const updateUser = (dataForm) => {
  return axios({
    method: "POST",
    url: `/user/update_user`,
    data: dataForm,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllCourses = () => {
  return axios({
    method: "GET",
    url: `/get_all_courses`,
    headers: {
      Authorization:  `Bearer ${token}` ,
    },
  });
};

export const getAllCoursesWithOutAuth = () => {
  return axios({
    method: "GET",
    url: `/get_all_courses`,
  });
};

export const getUpcomingCourses = () => {
  return axios({
    method: "GET",
    url: `/course/get_upcoming_courses`,
  });
};

export const getRecommendedCoursesUser = () => {
  return axios({
    method: "GET",
    url: `/course/get_recommended_courses_for_user`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getRecommendedCourseByLabel = (dataForm) => {
  return axios({
    method: "POST",
    url: `/course/get_recommended_courses_by_label`,
    data: dataForm,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getMyCourses = () => {
  return axios({
    method: "GET",
    url: `/course/get_my_courses`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllLabels = () => {
  return axios({
    method: "GET",
    url: `/label/get_all_labels`,
  });
};

export const getAllSpeakers = () => {
  return axios({
    method: "GET",
    url: `/user/get_all_speakers`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCourse = (dataForm) => {
 
  return axios({
    method: "POST",
    url: `/course/get_course`,
    data: dataForm,
  });
};
export const getCourseAuth = (dataForm) => {
 
  return axios({
    method: "POST",
    url: `/course/get_course`,
    data: dataForm,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPreferenceMp = (dataForm) => {
  return axios({
    method: "POST",
    url: `/create_preference_mp`,
    data: dataForm,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPreferenceMpNoAuth = (dataForm) => {
  return axios({
    method: "POST",
    url: `/create_preference_mp`,
    data: dataForm
  });
};

export const postCheckMPNoAuth = (dataForm) => {
  return axios({
    method: "POST",
    url: `/check_payment_mp`,
    data: dataForm,
  });
};

export const postCheckMPAuth = (dataForm) => {
  return axios({
    method: "POST",
    url: `/check_payment_mp`,
    data: dataForm,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postCheckPaypalAuth = (dataForm) => {
  return axios({
    method: "POST",
    url: `/check_payment_paypal`,
    data: dataForm,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postCheckPaypalNoAuth = (dataForm) => {
  return axios({
    method: "POST",
    url: `/check_payment_paypal`,
    data: dataForm,
  });
};

export const enrolFreeAuth = (dataForm) => {
  return axios({
    method: "POST",
    url: `/enrol_to_free_course`,
    data: dataForm,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const enrolFreeNoAuth = (dataForm) => {
  return axios({
    method: "POST",
    url: `/enrol_to_free_course`,
    data: dataForm,
  });
};

export const getInfoProfile = () => {
  return axios({
    method: "GET",
    url: `/user/get_user_info`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllReviews = () => {
  return axios({
    method: "GET",
    url: `/get_all_reviews`,
  });
};

export const getRecommendedCourses = () => {
  return axios({
    method: "GET",
    url: `/course/get_recommended_courses`,
  });
};

export const getMyCourse = (dataForm) => {
  return axios({
    method: "POST",
    url: `/course/get_details_sold_course`,
    data: dataForm,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPeerboard = (dataForm) => {
  return axios({
    method: "GET",
    url: `/peerboard_login`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCertificate = (dataForm) => {
  return axios({
    method: "POST",
    url: `/get_certificate`,
    data: dataForm,
  });
};

export const getSpecializations = () => {
  return axios({
    method: "GET",
    url: `/user/hours_per_label`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCertificates = () => {
  return axios({
    method: "GET",
    url: `/get_certificates`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postClaimCertificate = (dataForm) => {
  return axios({
    method: "POST",
    url: `/claim_certificate`,
    data: dataForm,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
