import { Link as LinkRouter } from "react-router-dom";
import { postClaimCertificate } from "../../services/services";
import store from "../../app/store";
import { postError, postSeverity } from "../../features/error/slices";

const CertificateInfo = ({certificate_id, id}:{certificate_id : String, id : String}) => {

    async function claimCertificate(id?: String) {
        const dataForm = {
            course: id,
        };

        const data: any = await postClaimCertificate(dataForm);
        const dataResponse = data.data.message;

        if (dataResponse === "Muchas gracias. Estamos procesando tu solicitud.") {
            store.dispatch(postError(dataResponse));
            store.dispatch(postSeverity("success"));
        } else {
            store.dispatch(postError(dataResponse));
            store.dispatch(postSeverity("error"));
        }
    }

    return(
        <div className="card course-data-card" id="course-certificate">
            <div className="course-data-container">
                <div className="course-data-title-icon-container">
                    <svg
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9 4.25C7.20508 4.25 5.75 5.70507 5.75 7.5C5.75 9.29493 7.20508 10.75 9 10.75C10.7949 10.75 12.25 9.29493 12.25 7.5C12.25 5.70507 10.7949 4.25 9 4.25ZM7.25 7.5C7.25 6.5335 8.03351 5.75 9 5.75C9.9665 5.75 10.75 6.5335 10.75 7.5C10.75 8.4665 9.9665 9.25 9 9.25C8.03351 9.25 7.25 8.4665 7.25 7.5Z"
                        fill="#F88D00"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9 0.25C4.99594 0.25 1.75 3.49594 1.75 7.5C1.75 8.96813 2.18639 10.3343 2.93652 11.476L0.350482 15.9551C0.203417 16.2099 0.218821 16.527 0.389877 16.7663C0.560933 17.0056 0.856064 17.1228 1.14467 17.066L3.75854 16.5522L4.62047 19.0728C4.71564 19.3511 4.96471 19.5481 5.25745 19.5766C5.5502 19.6051 5.83258 19.4599 5.97965 19.2051L8.55942 14.7368C8.70516 14.7456 8.85207 14.75 9 14.75C9.14794 14.75 9.29485 14.7456 9.44059 14.7368L12.0204 19.2051C12.1676 19.4601 12.4504 19.6054 12.7434 19.5765C13.0364 19.5477 13.2854 19.3501 13.3801 19.0713L14.2376 16.5455L16.8537 17.0657C17.1425 17.1232 17.4382 17.0063 17.6097 16.767C17.7812 16.5276 17.7967 16.2101 17.6495 15.9551L15.0635 11.476C15.8136 10.3343 16.25 8.96813 16.25 7.5C16.25 3.49594 13.0041 0.25 9 0.25ZM3.25 7.5C3.25 4.32436 5.82437 1.75 9 1.75C12.1756 1.75 14.75 4.32436 14.75 7.5C14.75 10.6756 12.1756 13.25 9 13.25C5.82437 13.25 3.25 10.6756 3.25 7.5ZM6.98376 14.466C5.83078 14.1328 4.79597 13.5212 3.95691 12.7086L2.4747 15.2759L4.11167 14.9541C4.47972 14.8817 4.84463 15.0924 4.96599 15.4473L5.50579 17.0259L6.98376 14.466ZM12.4918 17.0217L11.0163 14.466C12.1692 14.1328 13.204 13.5212 14.0431 12.7086L15.5229 15.2717L13.8851 14.9461C13.516 14.8727 13.1497 15.0842 13.0287 15.4406L12.4918 17.0217Z"
                        fill="#F88D00"
                    />
                    </svg>
                    <p className="course-data-title">
                    Certificado
                    </p>
            </div>
            {certificate_id ? (
                <LinkRouter
                id="see-certificate"
                className="button-inscripcion"
                to={`/certificado/${certificate_id}`}
                >
                Ver certificado
                </LinkRouter>
            ) : (
                <button
                id="request-certificate"
                className="button-inscripcion"
                color="primary"
                onClick={() => claimCertificate(id)}
                >
                Solicitar Certificado
                </button>
            )}
            </div>
        </div>
    )
}
export default CertificateInfo