let pageLocation = '/comunidad'
const CommunityIcon = (props) => (
    pageLocation === props.currentLocation ?
        (<svg
            width={props.width+2+'px'}
            height={props.height+2+'px'}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            style={{
              fillRule: "evenodd",
              clipRule: "evenodd",
              strokeLinejoin: "round",
              strokeMiterlimit: 2,
            }}
          >
            <path 
            fill={props.lightColor}
            d="M6.621 1.673a70.512 70.512 0 0 1 11.285.056l2.263.193a3.715 3.715 0 0 1 3.356 3.138l.143.931a28.806 28.806 0 0 1-.134 9.524 3.266 3.266 0 0 1-3.212 2.68H7.814c-.288 0-.571.071-.825.208L1.54 21.331a1.047 1.047 0 0 1-1.54-.92V8.543a6.868 6.868 0 0 1 6.352-6.849l.269-.021Zm-.002 6.894a1.742 1.742 0 1 0 0 3.484 1.742 1.742 0 0 0 0-3.484Zm5.573 0a1.741 1.741 0 1 0 0 3.482 1.741 1.741 0 0 0 0-3.482Zm3.832 1.742a1.742 1.742 0 1 1 3.484 0 1.742 1.742 0 0 1-3.484 0Z" />
            <path
            fill={props.darkColor}
            d="M12 1.474c1.97.002 3.941.087 5.906.255l2.263.193a3.715 3.715 0 0 1 3.356 3.138l.143.931a28.806 28.806 0 0 1-.134 9.524 3.266 3.266 0 0 1-3.212 2.68H12V12.04a1.742 1.742 0 1 0 0-3.463V1.474Zm4.024 8.835a1.742 1.742 0 1 1 3.484 0 1.742 1.742 0 0 1-3.484 0Z"
            />
          </svg>)
        :
        (<svg
            width={props.width}
            height={props.height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
            className='icon-svg-path-color'
            d="M6.619 8.567a1.742 1.742 0 1 0 0 3.484 1.742 1.742 0 0 0 0-3.484Zm5.573 0a1.741 1.741 0 1 0 0 3.482 1.741 1.741 0 0 0 0-3.482Zm3.832 1.742a1.742 1.742 0 1 1 3.484 0 1.742 1.742 0 0 1-3.484 0Z"
            style={{
                fillRule: "nonzero",
            }}
            />
            <path
            className='icon-svg-path-color'
            d="M17.906 1.729a70.512 70.512 0 0 0-11.285-.056l-.269.021A6.868 6.868 0 0 0 0 8.543v11.868a1.044 1.044 0 0 0 1.54.92l5.449-2.928c.254-.137.537-.208.825-.208h12.508a3.266 3.266 0 0 0 3.212-2.68c.573-3.145.618-6.363.134-9.524l-.143-.931a3.715 3.715 0 0 0-3.356-3.138l-2.263-.193ZM6.779 3.758a68.315 68.315 0 0 1 10.95.054l2.262.192a1.625 1.625 0 0 1 1.468 1.373l.143.931a26.698 26.698 0 0 1-.125 8.833 1.174 1.174 0 0 1-1.155.964H7.814c-.633 0-1.257.157-1.814.457l-3.91 2.101V8.543c0-2.5 1.927-4.577 4.419-4.765l.27-.02Z" />
        </svg>)
            
);
export default CommunityIcon;