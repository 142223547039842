import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { useNavigate} from "react-router-dom";

const CourseUnavailable = (props: { objection: string; courseId : string }) => {
    let navigate = useNavigate();
    return props.objection === "purchased" ?
            (<Grid item xs={8}>
                <Box className="card" style={{ height: "100%", padding: "20px", marginTop: "20px" }}>
                    <h3>Ya tenés acceso a este curso</h3>
                    <p>No podés inscribirte a este curso porque ya te inscribiste antes (y no quisiéramos que pagues dos veces por el mismo curso). Si querés ver el contenido del curso, hacé clic en el siguiente botón:</p>
                    <button
                        className="button-inscripcion"
                        color="primary"
                        onClick={() => navigate('/myCourse/' + props.courseId, { replace: true })}
                    >
                        Ir al curso
                    </button>
                    <p>Si querés regalarle este curso a alguien, cerrá tu sesión y completá la inscripción con los datos de esa persona. También podés escribirnos a info@juanmacarlupu.com y te ayudamos con el regalo.</p>
                </Box>
            </Grid>)
            :
           ( <Grid item xs={8}>
                <Box className="card" style={{ height: "100%", padding: "20px", marginTop: "20px" }}>
                    <h3>Este curso no está disponible</h3>
                    <p>Esto puede deberse a que el curso ya no esté en venta, a que hayas ingresado mal la dirección o a algún otro tipo de problema. Si creés que se trata de un error, escribinos a info@juanmacarlupu.com y nosotros te ayudamos.</p>
                </Box>
            </Grid>)
}
export default CourseUnavailable;