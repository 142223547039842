let pageLocation = '/myCourses'
const MyCoursesIcon = (props) => (
            pageLocation === props.currentLocation ?
            (<svg
              width={props.width+2+'px'}
            height={props.height+2+'px'}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              {...props}
            >
              <path
              fill={props.lightColor}
              d="M14.378 1.008a3.309 3.309 0 0 0-4.756 0L3.268 7.57a2.66 2.66 0 0 0-.705 1.369 38.097 38.097 0 0 0-.168 12.743l.233 1.497a.972.972 0 0 0 .96.821h4.44a.662.662 0 0 0 .662-.662v-9.269h6.62v9.269c0 .366.297.662.663.662h4.439a.972.972 0 0 0 .96-.821l.233-1.497c.66-4.227.603-8.535-.168-12.743a2.66 2.66 0 0 0-.705-1.369l-6.354-6.562Z"
              style={{
                fillRule: "nonzero",
              }}
              />
              <path
                fill={props.darkColor}
                d="M12 0v14.069h3.31v9.269c0 .366.297.662.663.662h4.439a.972.972 0 0 0 .96-.821l.233-1.497c.66-4.227.603-8.535-.168-12.743a2.66 2.66 0 0 0-.705-1.369l-6.354-6.562A3.297 3.297 0 0 0 12 0Z"
                style={{
                  fillRule: "nonzero",
                }}
              />
            </svg>)
            :
            (<svg
              width={props.width}
              height={props.height}
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
              <path
                className='icon-svg-path-color'
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5579 2.53423C9.68732 1.69887 8.31281 1.69887 7.44224 2.53423L2.8158 6.97357C2.70245 7.08234 2.6262 7.22402 2.59787 7.37854C2.04373 10.4004 2.00283 13.494 2.47687 16.5295L2.58939 17.25H5.56585V11.0387C5.56585 10.6244 5.90164 10.2887 6.31585 10.2887H11.6843C12.0985 10.2887 12.4343 10.6244 12.4343 11.0387V17.25H15.4107L15.5232 16.5295C15.9973 13.494 15.9564 10.4004 15.4023 7.37854C15.3739 7.22402 15.2977 7.08234 15.1843 6.97357L10.5579 2.53423ZM6.40369 1.45191C7.85464 0.0596485 10.1455 0.0596485 11.5964 1.45191L16.2229 5.89125C16.5634 6.21804 16.7925 6.64373 16.8777 7.10799C17.4622 10.2956 17.5053 13.559 17.0053 16.7609L16.8245 17.9184C16.7498 18.3971 16.3375 18.75 15.853 18.75H11.6843C11.2701 18.75 10.9343 18.4142 10.9343 18V11.7887H7.06585V18C7.06585 18.4142 6.73007 18.75 6.31585 18.75H2.14712C1.66264 18.75 1.25035 18.3971 1.1756 17.9184L0.994837 16.7609C0.494789 13.559 0.537936 10.2956 1.12247 7.10799C1.2076 6.64373 1.43668 6.21804 1.77725 5.89125L6.40369 1.45191Z"
              />
            </svg>)
            
);
export default MyCoursesIcon;