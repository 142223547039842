let pageLocation = '/infoPersonal/especializaciones'
const MySpecialtiesIcon = (props) => (
    pageLocation === props.currentLocation ?
            (<svg
                width={props.width+2+'px'}
                height={props.height+2+'px'}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M12.355 3.302a1.36 1.36 0 0 0-.743 0c-.519.148-1.042.284-1.568.421-2.343.612-4.924 1.286-7.131 3.087l-1.041.85c-.83.677-.829 2.003.001 2.68l1.024.834a12.029 12.029 0 0 0 2.353 1.491v4.629a2.75 2.75 0 0 0 1.751 2.562l4 1.56c.642.25 1.355.25 1.998 0l4-1.56a2.75 2.75 0 0 0 1.751-2.562v-4.621c.803-.391 1.586-.87 2.337-1.483l.163-.133V16a.75.75 0 0 0 1.5 0V9c0-.501-.208-1.002-.623-1.34l-1.024-.834c-2.224-1.811-4.913-2.512-7.184-3.104-.524-.137-1.046-.273-1.564-.42Zm-4.056 9.51a.75.75 0 0 0-.598 1.376 26.453 26.453 0 0 0 3.956 1.358c.236.061.484.061.72 0a26.175 26.175 0 0 0 3.945-1.359.75.75 0 1 0-.6-1.374 24.785 24.785 0 0 1-3.705 1.277 24.793 24.793 0 0 1-3.718-1.278Z"
                    fill={props.lightColor}
                />
                <path
                    d="M12 3.25v10.836l.017.004a24.746 24.746 0 0 0 3.705-1.277.748.748 0 1 1 .6 1.374 26.175 26.175 0 0 1-3.945 1.359 1.41 1.41 0 0 1-.377.045v6.012c.339 0 .678-.062.999-.187l4-1.56a2.75 2.75 0 0 0 1.751-2.562v-4.621c.803-.391 1.586-.87 2.337-1.483l.163-.133V16a.75.75 0 0 0 1.5 0V9c0-.501-.208-1.002-.623-1.34l-1.024-.834c-2.224-1.811-4.913-2.512-7.184-3.104-.524-.137-1.046-.273-1.564-.42A1.379 1.379 0 0 0 12 3.25Z"
                    fill={props.darkColor}
                />
            </svg>)
            :
            (<svg
                width={props.width}
                height={props.height}
                viewBox="0 0 22 19"
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 2,
                }}
                {...props}
            >
                <path
                className='icon-svg-path-color'
                d="M10.612.302c.243-.069.5-.069.743 0 .518.147 1.04.283 1.564.42 2.461.641 4.96 1.293 7.184 3.104l1.024.834c.415.338.623.839.623 1.34v7a.75.75 0 0 1-1.5 0V8.057l-.163.133a11.946 11.946 0 0 1-2.398 1.513.75.75 0 0 1 .061.297v4.294a2.75 2.75 0 0 1-1.751 2.562l-4 1.56c-.643.25-1.356.25-1.998 0l-4-1.56a2.75 2.75 0 0 1-1.751-2.562V10c0-.108.023-.211.064-.304-.83-.399-1.64-.89-2.417-1.522L.873 7.34C.043 6.663.042 5.337.872 4.66l1.041-.85C4.12 2.009 6.602 1.361 9.045.723 9.57.586 10.093.45 10.612.302Zm9.638 5.699c0-.09-.036-.15-.071-.178l-1.024-.834C17 3.5 15.079 2.843 12.64 2.202a95.31 95.31 0 0 1-1.656-.446c-.571.161-1.124.307-1.662.449-2.42.636-4.529 1.191-6.461 2.768l-1.041.849c-.034.028-.07.087-.07.177s.036.15.071.178l1.024.834C4.793 8.598 6.921 9.157 9.36 9.798a95.31 95.31 0 0 1 1.656.446c.57-.161 1.124-.307 1.662-.449 2.42-.636 4.529-1.191 6.461-2.768l1.04-.849c.035-.028.071-.087.071-.177Zm-7.295 5.276c1.1-.287 2.208-.577 3.295-.972v3.989c0 .515-.316.977-.796 1.165l-4 1.559a1.248 1.248 0 0 1-.908 0l-4-1.559a1.251 1.251 0 0 1-.796-1.165v-3.998c1.099.4 2.219.692 3.331.983.524.136 1.046.272 1.564.419.243.069.5.069.743 0 .519-.148 1.042-.284 1.567-.421Z"
                />
              </svg>)
);
export default MySpecialtiesIcon;