import { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import {
  getAllLabels,
  getAllSpeakers,
  getMyCourses,
} from "../../services/services";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import SideBar from "../sideBar/sideBar";
import { useSelector } from "react-redux";
import CourseCard from '../reusable/courseCard/courseCard'
import SeeDetailsButton from "./seeDetailsButton";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MyListCourses() {
  const theme = useTheme();
  const [listCourse, setlistCourse] = useState([]);
  const [listCoursePersistent, setlistCoursePersistent] = useState([]);
  const [listUpcomingCourse, setUpcomingCourse] = useState([]);
  const [listUpcomingCoursePersistent, setUpcomingCoursePersistent] = useState([]);
  const [listRecommendedCourse, setRecommendedCourse] = useState([]);
  const [listRecommendedCoursePersistent, setRecommendedCoursePersistent] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [filterLabels, setFilterLabels] = useState([]);
  const [labels, setLabels] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [loading, setloading] = useState(true);
  const user = useSelector((state) => state.user.value);

  useEffect(() => {
    getSpeakers();
    getLabels();
    getCourses();
  }, []);

  async function getCourses() {
    setloading(true);
    const data = await getMyCourses();
    const dataCourses = data.data;
    setlistCourse(dataCourses.my_courses.past_courses);
    setlistCoursePersistent(dataCourses.my_courses.past_courses);
    setUpcomingCourse(dataCourses.my_courses.upcoming_courses);
    setUpcomingCoursePersistent(dataCourses.my_courses.upcoming_courses);
    setloading(false);
  }


  async function getLabels() {
    const data = await getAllLabels();
    setLabels(data.data.labels);
  }
  async function getSpeakers() {
    const data = await getAllSpeakers();

    setSpeakers(data.data.speakers);
  }

  function filterByLabel(event) {
    const selectionsLabels = event.target.value;
    const {
      target: { value },
    } = event;
    setFilterLabels(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    let listFilter = listCoursePersistent;
    let listUpcomingFilter = listUpcomingCoursePersistent;
    let listRecommendedFilter = listRecommendedCoursePersistent;
    if (event.target.value.length) {
      listFilter = listCoursePersistent.filter((course) => {
        let filterCourse;
        selectionsLabels.forEach((label) => {
          if (typeof course.labels != "string") {
            course.labels.forEach((labelCourse) => {
              if (labelCourse.name === label) {
                filterCourse = course;
              }
            });
          } else if (course.labels === label) {
            filterCourse = course;
          }
        });
        if (filterCourse) {
          return course;
        }
      });

      listUpcomingFilter = listUpcomingCoursePersistent.filter((course) => {
        let filterCourse;
        selectionsLabels.forEach((label) => {
          if (typeof course.labels != "string") {
            course.labels.forEach((labelCourse) => {
              if (labelCourse.name === label) {
                filterCourse = course;
              }
            });
          } else if (course.labels === label) {
            filterCourse = course;
          }
        });
        if (filterCourse) {
          return course;
        }
      });

      listRecommendedFilter = listRecommendedCoursePersistent.filter(
        (course) => {
          let filterCourse;
          selectionsLabels.forEach((label) => {
            if (typeof course.labels != "string") {
              course.labels.forEach((labelCourse) => {
                if (labelCourse.name === label) {
                  filterCourse = course;
                }
              });
            } else if (course.labels === label) {
              filterCourse = course;
            }
          });
          if (filterCourse) {
            return course;
          }
        }
      );
    }

    setlistCourse(listFilter);
    setUpcomingCourse(listUpcomingFilter);
    setRecommendedCourse(listRecommendedFilter);
  }

  function filterByName(event) {
    const listFilter = listCoursePersistent.filter((course) =>
      course.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    const listUpcomingFilter = listUpcomingCoursePersistent.filter((course) =>
      course.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    const listRecommendedFilter = listRecommendedCoursePersistent.filter(
      (course) =>
        course.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setlistCourse(listFilter);
    setUpcomingCourse(listUpcomingFilter);
    setRecommendedCourse(listRecommendedFilter);
    setFilterName(event.target.value);
  }


  return (
    <>
    <SideBar></SideBar>
    <div className="view">
      <h3 className="primary" style={{ marginBottom: "8px" }}>
        ¡Hola, {user.nick_name}!
      </h3>
      <Stack direction="row" spacing={2} alignItems="center" id="filters">
        <FormControl sx={{ m: 1, width: 300 }} className="filters course-search-filter">
          <InputLabel id="demo-multiple-chip-label" className="filters course-search-filter-label">
            Todas las especialidades
          </InputLabel>
          <Select
            fullWidth
            className="filters course-search-filter-box"
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={filterLabels}
            onChange={filterByLabel}
            input={<FilledInput id="select-multiple-chip" label="Chip"/>}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                  color: "var(--text)",
                }}
              >
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {labels.map((label) => (
              <MenuItem
                key={label.name}
                value={label.name}
                style={getStyles(label, label.name, theme)}
              >
                <div style={{ color: "var(--text)"}}>{label.name}</div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{ width: "70%", maxWidth: "552px"}}
          style={{ margin: "8px" }}
          className="course-search-filter"
        >
          <InputLabel htmlFor="component-outlined" className="course-search-filter-label">Cursos</InputLabel>
          <FilledInput
            className="course-search-filter-box"
            fullWidth
            id="component-outlined"
            value={filterName}
            onChange={filterByName}
            label="Name"
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
      </Stack>
      <div className="cards-container">
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
              width: "90vw",
            }}
          >
            <CircularProgress />
          </Box>
        ) : listUpcomingCourse.length || listCourse.length ? (
          <div>
            {listUpcomingCourse.length ? (
              <div>
                <div className="">
                  <p>Próximos cursos:</p>
                  <div className="cards-container">
                    {listUpcomingCourse.map((course) => (
                      <CourseCard course={course} isRecommended={false}>
                      <SeeDetailsButton id={course.id}/>
                      </CourseCard>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}

            {listCourse.length ? (
              <div>
                <div className="">
                <p>Cursos ya emitidos:</p>
                  <div className="cards-container">
                    {listCourse.map((course) => (
                      <CourseCard course={course} isRecommended={false}>
                      <SeeDetailsButton id={course.id}/>
                      </CourseCard>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
              width: "90vw",
            }}
          >
            <Box
              sx={{
                textCenter: "center",
                width: "552px",
              }}
            >
              <h6 style={{ marginBottom: "19px" }}>
                Todavía no te inscribiste ninguno de nuestros cursos
              </h6>
              <p>
                Empezá a capacitarte haciendo clic en Más cursos, en el menú de
                la izquierda, para ver los cursos que tenemos para vos en nuestro catálogo.
                <br></br>
                <br></br>
                ¡Comencemos!
              </p>
            </Box>
          </Box>
        )}
      </div>
    </div>
    </>
  );
}

export const style = {
  listCoursesContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  card: {
    margin: "24px",
    marginLeft: "0px",
    borderRadius: "24px",
  },
};